import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
import { getItem } from '../../utility/localStorageControl';
import Assistants from '../../container/admin/Assistants';
import AttendanceSummary from '../../container/admin/AttendanceSummary';
import BatchwiseAttendance from '../../container/admin/BatchwiseAttendance';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import ConsolidatedAttendance from '../../container/admin/ConsolidatedAttendance';
import FinishingSchoolInterviews from '../../container/admin/FinishingSchoolInterviews';
import Interviewers from '../../container/admin/Interviewers';
import AcademicStudents from '../../container/admin/AcademicStudents';
import AcademicInterviews from '../../container/admin/AcademicInterviews';

const BatchesComponent = lazy(() => import('../../container/admin/Batches'));
const FS = lazy(() => import('../../container/admin/FS'));
const DashboardComponent = lazy(() => import('../../container/admin/Dashboard'));
const SubjectComponent = lazy(() => import('../../container/admin/Subjects'));
const StudentComponent = lazy(() => import('../../container/admin/Students'));
const TeachersComponent = lazy(() => import('../../container/admin/Teachers'));
const TopicsComponent = lazy(() => import('../../container/admin/Topics'));
const BadgesComponent = lazy(() => import('../../container/admin/Badges'));
const ChangePasswordComponent = lazy(() => import('../../container/admin/ChangePassword'));
const ChangeLogComponent = lazy(() => import('../../container/admin/ChangeLog'));
const NoticeComponent = lazy(() => import('../../container/admin/Notice'));
const CollegeComponent = lazy(() => import('../../container/admin/Colleges'));


const NotFound = lazy(() => import('../../container/pages/404'));



const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="dashboard" />} />
        <Route index path="/fs" element={<FS />} />
        <Route index path="/colleges" element={<CollegeComponent />} />
        <Route index path="/dashboard" element={<DashboardComponent />} />
        <Route index path="/batches" element={<BatchesComponent />} />
        <Route index path="/subjects" element={<SubjectComponent />} />
        <Route index path="/students" element={<StudentComponent />} />
        <Route index path="/academic_students" element={<AcademicStudents />} />
        <Route index path="/teachers" element={<TeachersComponent />} />
        <Route index path='/assistants' element={<Assistants />} />
        <Route index path='/interviewers' element={<Interviewers />} />
        <Route index path="/topics" element={<TopicsComponent />} />
        <Route index path="/badges" element={<BadgesComponent />} />
        <Route index path="/interviews/academic" element={<AcademicInterviews />} />
        <Route index path="/interviews/finishingschool" element={<FinishingSchoolInterviews />} />
        <Route index path="/attendance/summary" element={<AttendanceSummary />} />
        <Route index path="/attendance/batchwise" element={<BatchwiseAttendance />} />
        <Route index path="/attendance/consolidated" element={<ConsolidatedAttendance />} />
        <Route index path="/resetpassword" element={<ChangePasswordComponent />} />
        <Route index path="/changelog" element={<ChangeLogComponent />} />
        <Route index path="/notice" element={<NoticeComponent />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);