import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { notification } from 'antd';
import { logOut } from '../authentication/actionCreator';

const {
  attendanceReadBegin,
  attendanceReadSuccess,
  attendanceReadErr,

  batchwiseAttendanceReadBegin,
  batchwiseAttendanceReadSuccess,
  batchwiseAttendanceReadErr,

  postAttRemarksBegin,
  postAttRemarksSuccess,
  postAttRemarksErr,

  consolidatedAttendanceBegin,
  consolidatedAttendanceSuccess,
  consolidatedAttendanceErr,

} = actions;

const attendanceDataRead = () => {
  return async (dispatch) => {
    try {
      const activeFSid = getItem('activeFSid');

      await dispatch(attendanceReadBegin());
      const query = await DataService.get(`/studentmaster/overview/${activeFSid}`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(attendanceReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        throw query.data.message;
        // await dispatch(dashboardReadSuccess([]));
        // await dispatch(topicsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(attendanceReadErr(err));
    }
  };
};

const batchwiseAttendanceDataRead = (secId, date) => {
  return async (dispatch) => {
    try {
      await dispatch(batchwiseAttendanceReadBegin());
      const query = await DataService.get(`/studentmaster/att/${secId}/${date}`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(batchwiseAttendanceReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        await dispatch(batchwiseAttendanceReadSuccess([]));
        throw query.data.message;
        // await dispatch(dashboardReadSuccess([]));
        // await dispatch(topicsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(batchwiseAttendanceReadErr(err));
    }
  };
};

const postAttRemarks = (postData) => {
  return async (dispatch) => {
    try {
      await dispatch(postAttRemarksBegin());
      const query = await DataService.post(`/attendance/remarks`, postData);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(postAttRemarksSuccess(query.data));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {          
          dispatch(logOut());
        }
        await dispatch(postAttRemarksSuccess(null));
        throw query.data.message;
        // await dispatch(dashboardReadSuccess([]));
        // await dispatch(topicsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(postAttRemarksErr(err));
    }
  };
};

const ConsolidatedAttendanceDataRead = (batchId) => {
  return async (dispatch) => {
    try {
      await dispatch(consolidatedAttendanceBegin());
      const query = await DataService.get(`/studentmaster/detailOverview/${batchId}`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(consolidatedAttendanceSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        await dispatch(consolidatedAttendanceSuccess([]));
        throw query.data.message;
        // await dispatch(dashboardReadSuccess([]));
        // await dispatch(topicsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(consolidatedAttendanceErr(err));
    }
  };
};

export { attendanceDataRead, batchwiseAttendanceDataRead, postAttRemarks, ConsolidatedAttendanceDataRead }