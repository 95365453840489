import actions from './actions';

const {

  GET_SCHEDULED_DATA_BEGIN,
  GET_SCHEDULED_DATA_SUCCESS,
  GET_SCHEDULED_DATA_ERR,

  GET_INTERVIEW_BY_SCHEDULE_BEGIN,
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
  GET_INTERVIEW_BY_SCHEDULE_ERR,

  GET_INTERVIEW_ATTEMPTS_BEGIN,
  GET_INTERVIEW_ATTEMPTS_SUCCESS,
  GET_INTERVIEW_ATTEMPTS_ERR,

  OVERALL_SUMMARY_BEGIN,
  OVERALL_SUMMARY_SUCCESS,
  OVERALL_SUMMARY_ERR,

  SCHEDULE_WISE_SUMMARY_BEGIN,
  SCHEDULE_WISE_SUMMARY_SUCCESS,
  SCHEDULE_WISE_SUMMARY_ERR,

} = actions;


const getSheduledDataInitialState = {
  data: null,
  loading: false,
  error: null,
};

const InterviewsByScheduleInitialState = {
  data: [],
  loading: false,
  error: null,
}

const interviewAttemptsInitialState = {
  data: [],
  loading: false,
  error: null,
}


const overallSummaryInitialState = {
  data: null,
  loading: false,
  error: null,
};

const scheduleWiseSummaryInitialState = {
  data: [],
  loading: false,
  error: null,
};


const getScheduledDataReducer = (state = getSheduledDataInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SCHEDULED_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_SCHEDULED_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_SCHEDULED_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewsByScheduleReducer = (state = InterviewsByScheduleInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_BY_SCHEDULE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_BY_SCHEDULE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_BY_SCHEDULE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewAttemptsReducer = (state = interviewAttemptsInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEW_ATTEMPTS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEW_ATTEMPTS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEW_ATTEMPTS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const OverallSummaryReducer = (state = overallSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case OVERALL_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case OVERALL_SUMMARY_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case OVERALL_SUMMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ScheduleWiseSummaryReducer = (state = scheduleWiseSummaryInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case SCHEDULE_WISE_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SCHEDULE_WISE_SUMMARY_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SCHEDULE_WISE_SUMMARY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};



export { getScheduledDataReducer, getInterviewAttemptsReducer, getInterviewsByScheduleReducer, OverallSummaryReducer, ScheduleWiseSummaryReducer };
