// import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { AuthReducer, MessageReducer } from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { SUBJECT_UNITSReducer, SUBJECTReducer, UNITReducer } from './teacherSubjectsUnits/reducers'
import { TOPICSReducer } from './teacherTopicsMenu/reducers'

import { UploadContentReducer } from './uploadContent/reducers'
import { ReviewContentReducer, ReviewChunkUpdateReducer, DeleteChunkReducer } from './reviewContent/reducers'
import { TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer } from './topics/reducers';
import { ReadQAReducer, postResponseReducer, UpdateQAReducer, ReadAllQAByTopicReducer, DeleteQAReducer } from './generateQuestions/reducers';
import { postQuizResponseReducer, ReadAllQuizByTopicReducer, UpdateQuizReducer, DeleteQuizReducer, ReadAllQuizReducer } from './generateQuiz/reducers';
import { ReadStudentSubjectsReducer, ReadSubjectUnitsReducer } from './studentSubjectsUnits/reducers';
import { ProgressReducer } from './teacherDashboard/reducers';
import { QAFReducer } from './QAFeedback/reducers';
import { ContentFeedbackReducer } from './contentFeedback/reducers';
import { RecentActionsDataReducer } from './recentActions/reducers';



// Admin
import { FSReadReducer, FSUpdateReducer, FSAddReducer } from './fs/reducers';
import { COLLEGEReducer, COLLEGEAddReducer, COLLEGEUpdateReducer } from './Colleges/reducers';
import { BatchesAddReducer, BatchesReducer, BatchesUpdateReducer } from './batches/reducers';
import { SUBJECTSReducer, SUBJECTSUpdateReducer, SUBJECTSAddReducer } from './subjects/reducers';
import { STUDENTSReducer, STUDENTSUpdateReducer, STUDENTSAddReducer, STUDENTSBulkAddReducer, STUDENTSBulkUpdateReducer, ADDNEWSTUDENTSUpdateExistingReducer } from './students/reducers';
import { TEACHERSReducer, TEACHERSUpdateReducer, TEACHERSAddReducer, TEACHERSBulkAddReducer, TEACHERSBulkUpdateReducer, ADDNEWTEACHERSUpdateExistingReducer } from './teachers/reducers';
import { TEACHERSSUBJECTSReducer } from './teacherssubjects/reducers';
import { TOPICSADMINReducer, TOPICSADMINUpdateReducer, TOPICSADMINAddReducer } from './topicsadmin/reducers';
import { TOPICSSUBJECTSReducer } from './topicssubjects/reducers';
import { TESELLATORSUBJECTSReducer, TESELLATORSGETURLReducer } from './tesellatorsubjects/reducers';
import { BADGESReducer, BADGESADDReducer, BADGESUpdateReducer, BADGESDELETEReducer } from './badges/reducers';
import { USERSPWDCHANGEUpdateReducer } from './UsersPasswordChange/reducers';
import { YEARWISEBADGESCOUNTReducer, DASHBOARDReducer, DASHBOARDAddReducer } from './dashboard/reducers';
import { NOTICEReducer, NOTICEAddReducer, NOTICEUpdateReducer, NOTICECOLLEGEReducer, NOTICECOLLEGEAddReducer } from './notice/reducers';
import { ADDNEWAssistantsUpdateExistingReducer, AssistantsAddReducer, AssistantsBulkAddReducer, AssistantsBulkUpdateReducer, AssistantsReducer, AssistantsUpdateReducer } from './assistants/reducers';
import { ATTENDANCEreducer, BatchwiseAttendanceReducer, PostAttRemarksReducer, ConsolidatedAttendanceReducer } from './attendance/reducers';
import { getScheduledDataReducer, getInterviewAttemptsReducer, getInterviewsByScheduleReducer, OverallSummaryReducer, ScheduleWiseSummaryReducer } from './interview/reducers'
import { getAcademicScheduledDataReducer, getAcademicInterviewAttemptsReducer, getAcademicInterviewsByScheduleReducer } from './academicInterviews/reducers';
import { AcademicStudentAddReducer, AcademicStudentsReducer, AcademicStudentsBulkAddReducer } from './academicstudents/reducers'
import { createInterviewerDataReducer, getInterviewerDataReducer, updateInterviewerDataReducer } from './interviewers/reducers';

const rootReducers = combineReducers({
  // fb: firebaseReducer,
  fs: firestoreReducer,
  auth: AuthReducer,
  ChangeLayoutMode,
  msg: MessageReducer,

  // teacher reducers
  ProgressData: ProgressReducer,
  QAFeedbackData: QAFReducer,
  CFData: ContentFeedbackReducer,
  RecentActions: RecentActionsDataReducer,

  SubjectsUnits: SUBJECT_UNITSReducer,
  Subject: SUBJECTReducer,
  Unit: UNITReducer,
  Topics: TOPICSReducer,
  UploadContentRes: UploadContentReducer,
  ChunksForReview: ReviewContentReducer,
  updatedChunk: ReviewChunkUpdateReducer,
  DeleteChunkRes: DeleteChunkReducer,
  singleTopic: TOPICSingleReducer,
  TopicStatus: TOPICSTATUSReducer,
  GenerateQARes: postResponseReducer,
  QA: ReadQAReducer,
  QAUpdateRes: UpdateQAReducer,
  AllQAByTopic: ReadAllQAByTopicReducer,
  DeleteQARes: DeleteQAReducer,
  GenerateQuizRes: postQuizResponseReducer,
  AllQuiz: ReadAllQuizReducer,
  quizUpdateRes: UpdateQuizReducer,
  DeleteQuizRes: DeleteQuizReducer,
  AllQuizByTopic: ReadAllQuizByTopicReducer,

  // admin reducers

  Dashboard: DASHBOARDReducer,
  YearWiseBadgesCount: YEARWISEBADGESCOUNTReducer,
  DateWiseCount: DASHBOARDAddReducer,
  AttendanceOverview: ATTENDANCEreducer,
  batchwiseAttendance: BatchwiseAttendanceReducer,
  postRemraksRes: PostAttRemarksReducer,
  consolidatedAttendance: ConsolidatedAttendanceReducer,

  FS: FSReadReducer,
  FSUpdateRes: FSUpdateReducer,
  FSAddRes: FSAddReducer,

  Notice: NOTICEReducer,
  NoticeUpdateRes: NOTICEUpdateReducer,
  NoticeAddRes: NOTICEAddReducer,
  NoticeCollegeAddRes: NOTICECOLLEGEAddReducer,
  NoticeCollege: NOTICECOLLEGEReducer,

  College: COLLEGEReducer,
  CollegeAddRes: COLLEGEAddReducer,
  CollegeUpdateRes: COLLEGEUpdateReducer,

  Batches: BatchesReducer,
  BatchAddRes: BatchesAddReducer,
  BatchUpdateRes: BatchesUpdateReducer,

  Subjects: SUBJECTSReducer,
  SubjectsUpdateRes: SUBJECTSUpdateReducer,
  SubjectsAddRes: SUBJECTSAddReducer,
  Tesellatorurl: TESELLATORSGETURLReducer,

  Students: STUDENTSReducer,
  StudentsUpdateRes: STUDENTSUpdateReducer,
  StudentsAddRes: STUDENTSAddReducer,
  StudentsBulkAddRes: STUDENTSBulkAddReducer,
  StudentsBulkUpdateRes: STUDENTSBulkUpdateReducer,
  StudentsBulkAddUpdateRes: ADDNEWSTUDENTSUpdateExistingReducer,

  AcademicStudentsAddRes: AcademicStudentAddReducer,
  AcademicStudents: AcademicStudentsReducer,
  AcademicStudentsBulkAddRes: AcademicStudentsBulkAddReducer,

  overallSummary: OverallSummaryReducer,
  scheduleWiseSummary: ScheduleWiseSummaryReducer,

  Teachers: TEACHERSReducer,
  TeachersUpdateRes: TEACHERSUpdateReducer,
  TeachersAddRes: TEACHERSAddReducer,
  TeachersBulkAddRes: TEACHERSBulkAddReducer,
  TeachersSubjects: TEACHERSSUBJECTSReducer,
  TeachersBulkUpdateRes: TEACHERSBulkUpdateReducer,
  TeachersBulkAddUpdateRes: ADDNEWTEACHERSUpdateExistingReducer,

  Assistants: AssistantsReducer,
  AssistantsUpdateRes: AssistantsUpdateReducer,
  AssistantsAddRes: AssistantsAddReducer,
  AssistantsBulkAddRes: AssistantsBulkAddReducer,
  AssistantsBulkUpdateRes: AssistantsBulkUpdateReducer,
  AssistantsBulkAddUpdateRes: ADDNEWAssistantsUpdateExistingReducer,


  Topicsadmin: TOPICSADMINReducer,
  TopicsUpdateResadmin: TOPICSADMINUpdateReducer,
  TopicsAddadminRes: TOPICSADMINAddReducer,
  TopicsSubjects: TOPICSSUBJECTSReducer,
  Tesellator: TESELLATORSUBJECTSReducer,

  Badges: BADGESReducer,
  BadgesUpdateRes: BADGESUpdateReducer,
  BadgesAddRes: BADGESADDReducer,
  BadgesDeleteRes: BADGESDELETEReducer,


  UsersPwdChange: USERSPWDCHANGEUpdateReducer,


  TopicScheduledData: getScheduledDataReducer,
  interviewAttempts: getInterviewAttemptsReducer,
  interviewsBySchedule: getInterviewsByScheduleReducer,

  academicTopicScheduledData: getAcademicScheduledDataReducer,
  academicInterviewAttempts: getAcademicInterviewAttemptsReducer,
  academicInterviewsBySchedule: getAcademicInterviewsByScheduleReducer,

  //interviewer reducers
  postInterviewer: createInterviewerDataReducer,
  putInterviewer: updateInterviewerDataReducer,
  Interviewers: getInterviewerDataReducer,




  // student reducers
  StudentSubjects: ReadStudentSubjectsReducer,
  Units: ReadSubjectUnitsReducer,
  UnitTopics: TOPICSByUnitReducer

});

export default rootReducers;
