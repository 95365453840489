import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import { UilDocumentInfo } from '@iconscout/react-unicons';
import { UilQuestionCircle } from '@iconscout/react-unicons';
import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';
import { logOut } from '../../../redux/authentication/actionCreator';
import styles from './style.module.css';
import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import TopMenu from '../../../layout/TopMenu';
import HelpDoc from '../../../static/help/Admin_Documentation.pdf'
import { getItem } from '../../../utility/localStorageControl';
import 'animate.css';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const openPDF = () => {

    const pdfUrl = 'https://example.com/your-pdf-file.pdf';
    window.open(pdfUrl, '_blank');
  };

  const [state, setState] = useState({
    flag: 'en',
  });
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();

    dispatch(logOut(() => navigate('/')));
  };
  const username = getItem('username');
  const role = getItem('role')
  const collegeName = getItem('collegeName');
  const loggedname = getItem('name');
  var arr = username === 'superadmin' && loggedname.split(' ');
  const realname = username === 'superadmin' ? (arr[0].charAt(0).toUpperCase() + arr[0].slice(1)) + " " + (arr[1] && (arr[1].charAt(0).toUpperCase() + arr[1].slice(1))) : (username[0].toUpperCase() + username.slice(1).toLowerCase());

  const userContent = (

    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h4">{realname}</Heading>
            <h5>{collegeName}</h5>
            <h5>{role}</h5>
          </figcaption>
        </figure>
        {/* <ul className="user-dropdwon__links">
          <li>
            <Link target = '_blank' style={{color: 'grey',fontWeight: 'bold'}}>
              <UilDocumentInfo /> Profile
            </Link>
          </li>                     */}
        {/* <li>
            <Link to="#">
              <UilUsersAlt /> Activity
            </Link>
          </li> */}
        {/* <li>
          <Link to={HelpDoc} target='_blank' className='align-center-v'>
            <UilQuestionCircle style={{ cursor: 'pointer', color: 'grey', marginRight: '5px' }} /><span style={{ color: 'black' }}>Help</span>
          </Link>
        </li> */}
        {/* </ul> */}
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('esp', e)} to="#">
        <img src={require('../../../static/img/flag/esp.png')} alt="" />
        <span>Spanish</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('ar', e)} to="#">
        <img src={require('../../../static/img/flag/ar.png')} alt="" />
        <span>Arabic</span>
      </Link>
    </NavAuth>
  );

  useEffect(() => {
    setInterval(function () {
      let FS_NAME = document.getElementById('FS_NAME')
      if (FS_NAME) {
        FS_NAME.classList.remove("animate__rubberBand");
        setTimeout(function () {
          FS_NAME.classList.add("animate__rubberBand");
        }, 1500);
      }
    }, 10000);
  }, [])

  return (
    <>


      <InfoWraper className={styles['center-content']}>
        {/* <div>
      {collegeName}
    </div> */}
        {/* <div className="ninjadash-header-content__right d-flex">
                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 991 ? <TopMenu /> : ''}
                  </div>
                  <div className="ninjadash-nav-actions">
                    <h1 style={{fontWeight: 'bold',fontSize: '18px', color: '#006666'}}>{collegeName}</h1>
                  </div>
                </div> */}
        {/* <UilQuestionCircle /> */}

        <span style={{ color: 'black', fontWeight: '500' }} id="FS_NAME" className="animate__animated animate__rubberBand">{getItem('activeFSName')}</span>

        <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
          <Popover placement="bottomRight" content={userContent} action="click">
            <Link to="#" className="ninjadash-nav-action-link">
              <Avatar icon={<UilUser />} style={{ marginLeft: '20px' }} />
              <span className="ninjadash-nav-actions__author--name">{realname}</span>

              <UilAngleDown />
            </Link>
          </Popover>
        </div>
      </InfoWraper>
    </>
  );
});

export default AuthInfo;
