import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import withInterviewerLayout from '../../layout/withInterviewerLayout';
import CustomSpinner from '../../container/ui-elements/spinner/CustomSpinner';
import Interviews from '../../container/admin/Interviews';
import FinishingSchoolInterviews from '../../container/admin/FinishingSchoolInterviews';
import Dashboard from '../../container/admin/Dashboard';
const NotFound = lazy(() => import('../../container/pages/404'));



const Interviewer = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Suspense
      fallback={
        <div className="spin">
          <CustomSpinner />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="" />} />
        <Route index path="/" element={<Dashboard />} />
        <Route path="/interviews/academic" element={<Interviews />} />
        <Route path="/interviews/finishingschool" element={<FinishingSchoolInterviews />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withInterviewerLayout(Interviewer);