import { message, notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = (response) => {
  if(response.duplicates.length !== 0) {
    notification.error({
      message : 'Student already exists in FS / Academic'
    })
  }
  else {
    notification.success({
      message: response.message,
    });
  }
};

const addbulkNotificationSuccess = (count) => {
  notification.success({
    message: `${count} new student${count === 1 ? '' : 's'} ${count === 1 ? 'has' : 'have'} been added`,
  });
};

const addnewupdateexistingNotificationSuccess = (newstudents, updatedstudents) => {
  notification.success({
    message: `${newstudents} new student${newstudents === 1 ? '' : 's'} ${newstudents === 1 ? 'is' : 'are'} added and ${updatedstudents} student${updatedstudents === 1 ? '' : 's'} ${updatedstudents === 1 ? 'is' : 'are'} updated:`,
    duration: 0,
  });
};

const addbulkDuplicateNotificationSuccess = (duplicates, count, dupcount) => {
  notification.success({
    message: `${count} new student${count === 1 ? '' : 's'} ${count === 1 ? 'is' : 'are'} added and ${dupcount} duplicate${dupcount === 1 ? '' : 's'} ${dupcount === 1 ? 'is' : 'are'} found:`,
    description: duplicates.join(', '),
    duration: 0,
    style: {
      width: '1000px',
    },
  });
};

const updatebulkNotExistNotificationSuccess = (notexisting, notexist, count) => {
  notification.success({
    message: `${count} student${count === 1 ? '' : 's'} ${count === 1 ? 'is' : 'are'} updated and ${notexist} not existing student${notexist === 1 ? '' : 's'} ${notexist === 1 ? 'is' : 'are'} found:`,
    description: notexisting.join(', '),
    duration: 0,
    style: {
      width: '1000px',
    },
  });
};


const updateNotificationSuccess = () => {
  notification.success({
    message: 'Student Record has been updated',
  });
};

const bulkupdateNotificationSuccess = (count) => {
  notification.success({
    message: `${count} student${count === 1 ? '' : 's'} ${count === 1 ? 'has' : 'have'} been updated`,
  });
};

const bulkupdateNotificationError = (err) => {
  const errorMessage = `Updating bulk students has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const addnewupdateexistingNotificationError = (err) => {
  const errorMessage = `Adding new students & updating existing students been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating student has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  studentsAddBegin,
  studentsAddSuccess,
  studentsAddErr,

  studentsBulkAddBegin,
  studentsBulkAddSuccess,
  studentsBulkAddErr,

  addnewstudentsupdateexistingBegin,
  addnewstudentsupdateexistingSuccess,
  addnewstudentsupdateexistingErr,

  studentsReadBegin,
  studentsReadSuccess,
  studentsReadErr,

  studentsUpdateBegin,
  studentsUpdateSuccess,
  studentsUpdateErr,

  studentsUpdateBulkBegin,
  studentsUpdateBulkSuccess,
  studentsUpdateBulkErr,
} = actions;

const studentsDataSubmit = (uploadedData) => {
  return async (dispatch) => {
    try {
      let data = []
      let obj = { ...uploadedData[0], fsId: getItem('activeFSid') }
      data.push(obj)
      await dispatch(studentsAddBegin());
      const response = await DataService.post('/studentmaster/bulkadd', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(studentsAddSuccess(response.data));
        addNotificationSuccess(response.data);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        await dispatch(studentsAddSuccess(null));
        notification.error({ message: response.data.message })
        // await dispatch(studentsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(studentsAddErr(err));
    }
  };
};


const addnewstudentsupdateexisting = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(addnewstudentsupdateexistingBegin());
      const response = await DataService.put('/studentmaster/bulkaddupdate', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(addnewstudentsupdateexistingSuccess(response.data.message));
        const newstudents = response.data.payload.newcount;
        const updatedstudents = response.data.payload.updatecount;
        addnewupdateexistingNotificationSuccess(newstudents, updatedstudents);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(studentsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(addnewstudentsupdateexistingErr(err));
      addnewupdateexistingNotificationError(err);
    }
  };
};


const studentsBulkDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      // let data = []
      // uploadedData?.filter(obj => {
      //   let MyObj = { ...obj, fsId: getItem('activeFSid') }
      //   data.push(MyObj)
      // })
      await dispatch(studentsBulkAddBegin());
      const response = await DataService.post('/studentmaster/bulkadd', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        if (response.data.duplicates.length === 0) {
          await dispatch(studentsBulkAddSuccess(response.data));
          const count = data.length;
          addbulkNotificationSuccess(count);
        }
        if (response.data.duplicates.length > 0) {
          const count = data.length - response.data.duplicates.length;
          const dupcount = response.data.duplicates.length;
          await dispatch(studentsBulkAddSuccess(response.data));
          addbulkDuplicateNotificationSuccess(response.data.duplicates, count, dupcount);
        }
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        notification.error({ message: response.data.message })
        await dispatch(studentsBulkAddSuccess(null));
        throw response.data.Error;
        // await dispatch(studentsBulkAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(studentsBulkAddErr(err));
    }
  };
};

const studentsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(studentsReadBegin());
      const activeFSid = getItem('activeFSid');
      const query = await DataService.get(`/studentmaster/${activeFSid}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(studentsReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401) {
          notification.error({ message: query.data.message })
          dispatch(logOut())
        }
        throw query.data.message;
        // await dispatch(studentsReadSuccess([]));
        // await dispatch(studentsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(studentsReadErr(err));
    }
  };
};

const studentsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(studentsUpdateBegin());
      const response = await DataService.put(`/studentmaster/bulkupdate`, data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(studentsUpdateSuccess(response.data));
        updateNotificationSuccess();
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(studentsDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(studentsUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const studentsBulkDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(studentsUpdateBulkBegin());
      const response = await DataService.put(`/studentmaster/bulkupdate`, data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        if (response.data.notexitsusers.length === 0) {
          await dispatch(studentsUpdateBulkSuccess(response.data));
          const count = data.length;
          bulkupdateNotificationSuccess(count);
        }
        if (response.data.notexitsusers.length > 0) {
          await dispatch(studentsUpdateSuccess(response.data));
          const notexistingstudents = response.data.notexitsusers.length;
          const updatedstudentscount = data.length - notexistingstudents;
          updatebulkNotExistNotificationSuccess(response.data.notexitsusers, notexistingstudents, updatedstudentscount);
        }
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(studentsDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(studentsUpdateBulkErr(err));
      bulkupdateNotificationError(err);
    }
  };
};

export {
  studentsDataRead,
  studentsDataSubmit,
  studentsBulkDataSubmit,
  studentsDataUpdate,
  studentsBulkDataUpdate,
  addnewstudentsupdateexisting,
};
