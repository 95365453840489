import { notification } from 'antd';
import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const addNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Submited',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const deleteNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been Deleted',
  });
};

const deleteNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Your Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: err,
  });
};

const {

  topicSingleReadBegin,
  topicSingleReadSuccess,
  topicSingleReadErr,

  topicStatusUpdateBegin,
  topicStatusUpdateSuccess,
  topicStatusUpdateErr,

  topicsReadBegin,
  topicsReadSuccess,
  topicsReadErr,

  clearTopicBegin,
  clearTopicSuccess,
  clearTopicErr

} = actions;


const getTopicById = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicSingleReadBegin());
      const query = await DataService.get(`/topicmaster/${topicId}`);      
      const token = Cookies.get('access_token');
      await dispatch(topicSingleReadSuccess(query.data));
      if (token) {
      } else {
        await dispatch(topicSingleReadSuccess([]));
        // await dispatch(regulationAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(topicSingleReadErr(err));
    }
  };
};


const updateTopicStatusById = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(topicStatusUpdateBegin());
      const query = await DataService.put(`/topicmaster/status`,data);      
      const token = Cookies.get('access_token');      
      await dispatch(topicStatusUpdateSuccess(query.data));
      if (token) {
      } else {
        await dispatch(topicStatusUpdateSuccess([]));
        // await dispatch(regulationAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(topicStatusUpdateErr(err));
    }
  };
};

const getTopicsByUnitId = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(topicsReadBegin());
      const query = await DataService.get(`/studentmaster/get-topics-unit/${unitId}`);            
      const token = Cookies.get('access_token');
      await dispatch(topicsReadSuccess(query.data));
      if (token) {
      } else {
        await dispatch(topicsReadSuccess([]));
        // await dispatch(regulationAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(topicsReadErr(err));
    }
  };
};

const clearTopicByTopicId = (topicId) => {
  return async (dispatch) => {
    try {
      await dispatch(clearTopicBegin());
      const query = await DataService.delete(`/topicmaster/topic/${topicId}`);            
      const token = Cookies.get('access_token');
      await dispatch(clearTopicSuccess(query.data));
      if (token) {
      } else {
        await dispatch(clearTopicSuccess([]));
        // await dispatch(regulationAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(clearTopicErr(err));
    }
  };
};


export {
  getTopicById,
  updateTopicStatusById,
  getTopicsByUnitId,
  clearTopicByTopicId
};
