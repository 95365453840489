const actions = {
  STUDENTS_ADD_BEGIN: 'STUDENTS_ADD_BEGIN',
  STUDENTS_ADD_SUCCESS: 'STUDENTS_ADD_SUCCESS',
  STUDENTS_ADD_ERR: 'STUDENTS_ADD_ERR',
  
  STUDENTS_BULK_ADD_BEGIN: 'STUDENTS_BULK_ADD_BEGIN',
  STUDENTS_BULK_ADD_SUCCESS: 'STUDENTS_BULK_ADD_SUCCESS',
  STUDENTS_BULK_ADD_ERR: 'STUDENTS_BULK_ADD_ERR',

  STUDENTS_READ_BEGIN: 'STUDENTS_READ_BEGIN',
  STUDENTS_READ_SUCCESS: 'STUDENTS_READ_SUCCESS',
  STUDENTS_READ_ERR: 'STUDENTS_READ_ERR',

  STUDENTS_UPDATE_BEGIN: 'STUDENTS_UPDATE_BEGIN',
  STUDENTS_UPDATE_SUCCESS: 'STUDENTS_UPDATE_SUCCESS',
  STUDENTS_UPDATE_ERR: 'STUDENTS_UPDATE_ERR',
  
  STUDENTS_UPDATE_BULK_BEGIN: 'STUDENTS_UPDATE_BULK_BEGIN',
  STUDENTS_UPDATE_BULK_SUCCESS: 'STUDENTS_UPDATE_BULK_SUCCESS',
  STUDENTS_UPDATE_BULK_ERR: 'STUDENTS_UPDATE_BULK_ERR',

  ADD_NEW_UPDATE_EXISTING_BEGIN: 'ADD_NEW_UPDATE_EXISTING_BEGIN',
  ADD_NEW_UPDATE_EXISTING_SUCCESS: 'ADD_NEW_UPDATE_EXISTING_SUCCESS',
  ADD_NEW_UPDATE_EXISTING_ERR: 'ADD_NEW_UPDATE_EXISTING_SUCCESS',

  studentsAddBegin: () => {
    return {
      type: actions.STUDENTS_ADD_BEGIN,
    };
  },

  studentsAddSuccess: (data) => {
    return {
      type: actions.STUDENTS_ADD_SUCCESS,
      data,
    };
  },

  studentsAddErr: (err) => {
    return {
      type: actions.STUDENTS_ADD_ERR,
      err,
    };
  },

  studentsBulkAddBegin: () => {
    return {
      type: actions.STUDENTS_BULK_ADD_BEGIN,
    };
  },

  studentsBulkAddSuccess: (data) => {
    return {
      type: actions.STUDENTS_BULK_ADD_SUCCESS,
      data,
    };
  },

  studentsBulkAddErr: (err) => {
    return {
      type: actions.STUDENTS_BULK_ADD_ERR,
      err,
    };
  },

  addnewstudentsupdateexistingBegin: () =>{
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_BEGIN,
    };
  },

  addnewstudentsupdateexistingSuccess: (data) => {
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_SUCCESS,
      data,
    };
  },

  addnewstudentsupdateexistingErr: (err) => {
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_ERR,
      err,
    };
  },

  studentsReadBegin: () => {
    return {
      type: actions.STUDENTS_READ_BEGIN,
    };
  },

  studentsReadSuccess: (data) => {
    return {
      type: actions.STUDENTS_READ_SUCCESS,
      data,
    };
  },

  studentsReadErr: (err) => {
    return {
      type: actions.STUDENTS_READ_ERR,
      err,
    };
  },

  studentsUpdateBegin: () => {
    return {
      type: actions.STUDENTS_UPDATE_BEGIN,
    };
  },

  studentsUpdateSuccess: (data) => {
    return {
      type: actions.STUDENTS_UPDATE_SUCCESS,
      data,
    };
  },

  studentsUpdateErr: (err) => {
    return {
      type: actions.STUDENTS_UPDATE_ERR,
      err,
    };
  },

  studentsUpdateBulkBegin: () => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_BEGIN,
    };
  },

  studentsUpdateBulkSuccess: (data) => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_SUCCESS,
      data,
    };
  },

  studentsUpdateBulkErr: (err) => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_ERR,
      err,
    };
  },
};

export default actions;
