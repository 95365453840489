import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import React, { useEffect, useState, lazy } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
// import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import SimpleReactLightbox from 'simple-react-lightbox';
import { ThemeProvider } from 'styled-components';
import ProtectedRoute from './components/utilities/protectedRoute';
import config from './config/config';
import store, { rrfProps } from './redux/store';
import CustomSpinner from './container/ui-elements/spinner/CustomSpinner';

import Admin from './routes/admin';
import Interview from './routes/interviewer';
import Student from './routes/student';
import Auth from './routes/auth';
import './static/css/style.css';
import { getItem } from './utility/localStorageControl';
import { getMessage } from './redux/authentication/actionCreator';

const NotFound = lazy(() => import('./container/pages/404'));

const { themeColor } = config

function ProviderConfig() {
  const { rtl, isLoggedIn, topMenu, mainContent, auth, isLoading } = useSelector((state) => {
    return {
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      mainContent: state.ChangeLayoutMode.mode,
      isLoggedIn: state.auth.login,
      isLoading: state.auth.loading,
      auth: state.auth,
    };
  });

  const dispatch = useDispatch()
  const [role, setRole] = useState("");

  useEffect(() => {
    const role = getItem('role')
    setRole(role)
  })

  // const [path, setPath] = useState(window.location.pathname);

  // useEffect(() => {
  //   let unmounted = false;
  //   if (!unmounted) {
  //     setPath(window.location.pathname);
  //   }
  //   // eslint-disable-next-line no-return-assign
  //   return () => (unmounted = true);
  // }, [setPath]);


  const { msg, messageLoading } = useSelector((state) => {
    return {
      msg: state.msg.data,
      messageLoading: state.msg.loading
    };
  })

  useEffect(() => {
    dispatch(getMessage())
  }, [])

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
        {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
        {isLoading ? (
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p style={{ fontWeight: 'bold', color: '#006666' }}>
              Logging in please wait...
            </p>
          </div>
        ) : (
          <SimpleReactLightbox>
            <Router basename={''}>
              {!isLoggedIn ? (
                <div>
                  <div className='text-center' dangerouslySetInnerHTML={{ __html: msg }} />
                  <Routes>
                    <Route path="/*" element={<Auth />} />
                  </Routes>
                </div>
              ) : (
                isLoggedIn && role === 'ADMIN' ?
                  <Routes>
                    <Route path="/admin/*" element={<ProtectedRoute path="/*" Component={Admin} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  :
                  isLoggedIn && role === 'INTERVIEWER' &&
                    <Routes>
                      <Route path="/interviewer/*" element={<ProtectedRoute path="/*" Component={Interview} />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>                    
                )
              }
              {isLoggedIn && role === 'ADMIN' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/admin" />} />
                </Routes>
              )}
              {isLoggedIn && role === 'INTERVIEWER' && (
                <Routes>
                  <Route path="/" element={<Navigate to="/interviewer" />} />
                </Routes>
              )}
            </Router>
          </SimpleReactLightbox>
        )}
        {/* </ReactReduxFirebaseProvider> */}
      </ThemeProvider>
    </ConfigProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;
