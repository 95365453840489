import { Spin } from 'antd';
import React, { Suspense } from 'react';
import { AuthenticationWrap } from './overview/style';
import CustomSpinner from '../../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../../utility/localStorageControl';

const collegeName = getItem('collegeName');
const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="custom-spin">
            <CustomSpinner />
          </div>
        }
      >
        <AuthenticationWrap style={{ backgroundImage: `url("${require('../../../static/img/admin-bg-light.png')}")` }}>
          <div className="ninjadash-authentication-wrap">
            <div className="ninjadash-authentication-brand">
              <img src={require(`../../../static/img/tesseract/tesseract-fs-logo-text.png`)} alt="" width={270} style={{mixBlendMode:'darken'}}/>
            </div>
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
