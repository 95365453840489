import React, { useEffect, useState } from 'react'
import { TableWrapper } from '../../styled'
import { ProjectList } from '../../project/style'
import { Table } from 'antd'

export default function InterviewOverallReport({ summaryData }) {
    const [filteredData, setFilteredData] = useState([])
    useEffect(() => {
        if (summaryData.length !== 0) {
            const data = summaryData.map((obj, index) => {
                return (
                    {
                        sno: index + 1,
                        ScheduleName: obj.ScheduleName,
                        ScheduleDate : obj.ScheduleDate,
                        ZeroScoreCount : parseInt(obj.ZeroScoreCount),
                        Between0And4Count: parseInt(obj.Between0And4Count),
                        Between5And7Count: parseInt(obj.Between5And7Count),
                        Above7Count: parseInt(obj.Above7Count),
                        AbsenteesCount: parseInt(obj.AbsenteesCount),                         
                    }
                )
            })
            setFilteredData(summaryData)
        }
        else {
            setFilteredData([])
        }
    }, [summaryData])

    const columns = [
        {
            title: '#',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Schedule',
            dataIndex: 'ScheduleName',
            key: 'ScheduleName',
            sorter: (a, b) => a.ScheduleName.toLowerCase() > b.ScheduleName.toLowerCase() ? 1 : -1,
        },
        {
            title: 'Date',
            dataIndex: 'ScheduleDate',
            key: 'ScheduleDate',
            sorter: (a, b) => a.ScheduleDate > b.ScheduleDate ? 1 : -1,
        },
        {
            title: 'Absent',
            dataIndex: 'AbsenteesCount',
            key: 'AbsenteesCount',
            sorter: (a, b) => a.AbsenteesCount > b.AbsenteesCount ? 1 : -1,
        },
        {
            title: 'Grade 0',
            dataIndex: 'ZeroScoreCount',
            key: 'ZeroScoreCount',
            sorter: (a, b) => a.ZeroScoreCount > b.ZeroScoreCount ? 1 : -1,
        },
        {
            title: 'Grade 1 to 4',
            dataIndex: 'Between0And4Count',
            key: 'Between0And4Count',
            sorter: (a, b) => a.Between0And4Count > b.Between0And4Count ? 1 : -1,
        },
        {
            title: 'Grade 5 to 7',
            dataIndex: 'Between5And7Count',
            key: 'Between5And7Count',
            sorter: (a, b) => a.Between5And7Count > b.Between5And7Count ? 1 : -1,
        },
        {
            title: 'Grade 8 to 10',
            dataIndex: 'Above7Count',
            key: 'Above7Count',
            sorter: (a, b) => a.Above7Count > b.Above7Count ? 1 : -1,
        },
    ]

    return (
        <div>
            <TableWrapper>
                <ProjectList>
                    <div className="table-responsive">
                        <Table
                            dataSource={filteredData}
                            columns={columns}
                            showSorterTooltip={false}
                            pagination={{
                                className: 'd-flex align-center-v',
                                size: 'small',
                                showTotal: (total) => `Total ${total} Interviews`
                            }} />
                    </div>
                </ProjectList>
            </TableWrapper>
        </div>
    )
}
