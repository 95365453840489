import React from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useParams } from 'react-router-dom';

function Dashboard() {    

    return (
        <div>
            <PageHeader className="ninjadash-page-header-main" title="Dashboard" />
            <Main>
                
            </Main>
        </div>
    );
}

export default Dashboard;