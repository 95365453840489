const actions = {
  ASSISTANTS_ADD_BEGIN: 'ASSISTANTS_ADD_BEGIN',
  ASSISTANTS_ADD_SUCCESS: 'ASSISTANTS_ADD_SUCCESS',
  ASSISTANTS_ADD_ERR: 'ASSISTANTS_ADD_ERR',
  
  ASSISTANTS_BULK_ADD_BEGIN: 'ASSISTANTS_BULK_ADD_BEGIN',
  ASSISTANTS_BULK_ADD_SUCCESS: 'ASSISTANTS_BULK_ADD_SUCCESS',
  ASSISTANTS_BULK_ADD_ERR: 'ASSISTANTS_BULK_ADD_ERR',

  ASSISTANTS_READ_BEGIN: 'ASSISTANTS_READ_BEGIN',
  ASSISTANTS_READ_SUCCESS: 'ASSISTANTS_READ_SUCCESS',
  ASSISTANTS_READ_ERR: 'ASSISTANTS_READ_ERR',

  ASSISTANTS_UPDATE_BEGIN: 'ASSISTANTS_UPDATE_BEGIN',
  ASSISTANTS_UPDATE_SUCCESS: 'ASSISTANTS_UPDATE_SUCCESS',
  ASSISTANTS_UPDATE_ERR: 'ASSISTANTS_UPDATE_ERR',

  ASSISTANTS_BULK_UPDATE_BEGIN: 'ASSISTANTS_BULK_UPDATE_BEGIN',
  ASSISTANTS_BULK_UPDATE_SUCCESS: 'ASSISTANTS_BULK_UPDATE_SUCCESS',
  ASSISTANTS_BULK_UPDATE_ERR: 'ASSISTANTS_BULK_UPDATE_ERR',

  ASSISTANTS_UPDATE_EXISTING_BEGIN: 'ASSISTANTS_UPDATE_EXISTING_BEGIN',
  ASSISTANTS_UPDATE_EXISTING_SUCCESS: 'ASSISTANTS_UPDATE_EXISTING_SUCCESS',
  ASSISTANTS_UPDATE_EXISTING_ERR: 'ASSISTANTS_UPDATE_EXISTING_SUCCESS',

  assistantsAddBegin: () => {
    return {
      type: actions.ASSISTANTS_ADD_BEGIN,
    };
  },

  assistantsAddSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_ADD_SUCCESS,
      data,
    };
  },

  assistantsAddErr: (err) => {
    return {
      type: actions.ASSISTANTS_ADD_ERR,
      err,
    };
  },

  assistantsBulkAddBegin: () => {
    return {
      type: actions.ASSISTANTS_BULK_ADD_BEGIN,
    };
  },

  assistantsBulkAddSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_BULK_ADD_SUCCESS,
      data,
    };
  },

  assistantsBulkAddErr: (err) => {
    return {
      type: actions.ASSISTANTS_BULK_ADD_ERR,
      err,
    };
  },

  addnewassistantsupdateexistingBegin: () =>{
    return {
      type: actions.ASSISTANTS_UPDATE_EXISTING_BEGIN,
    };
  },

  addnewassistantsupdateexistingSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_UPDATE_EXISTING_SUCCESS,
      data,
      
    };
  },

  addnewassistantsupdateexistingErr: (err) => {
    return {
      type: actions.ASSISTANTS_UPDATE_EXISTING_ERR,
      err,
    };
  },

  assistantsReadBegin: () => {
    return {
      type: actions.ASSISTANTS_READ_BEGIN,
    };
  },

  assistantsReadSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_READ_SUCCESS,
      data,
    };
  },

  assistantsReadErr: (err) => {
    return {
      type: actions.ASSISTANTS_READ_ERR,
      err,
    };
  },

  assistantsUpdateBegin: () => {
    return {
      type: actions.ASSISTANTS_UPDATE_BEGIN,
    };
  },

  assistantsUpdateSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_UPDATE_SUCCESS,
      data,
    };
  },

  assistantsUpdateErr: (err) => {
    return {
      type: actions.ASSISTANTS_UPDATE_ERR,
      err,
    };
  },

  assistantsBulkUpdateBegin: () => {
    return {
      type: actions.ASSISTANTS_BULK_UPDATE_BEGIN,
    };
  },

  assistantsBulkUpdateSuccess: (data) => {
    return {
      type: actions.ASSISTANTS_BULK_UPDATE_SUCCESS,
      data,
    };
  },

  assistantsBulkUpdateErr: (err) => {
    return {
      type: actions.ASSISTANTS_BULK_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
