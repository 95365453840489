import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new monitor has been Added',
  });
};

const addbulkNotificationSuccess = (count) => {
  notification.success({
    message: `${count} new monitor${count === 1 ? '' : 's'} ${count === 1 ? 'has' : 'have'} been added`,
  });
};

const addbulkDuplicateNotificationSuccess = (duplicates,count,dupcount) => {
  notification.success({
    message: `${count} new monitor${count === 1 ? '' : 's'} ${count === 1 ? 'is' : 'are'} added and ${dupcount} duplicate${dupcount === 1 ? '' : 's'} ${dupcount === 1 ? 'is' : 'are'} found:`,
    description: duplicates.join(', '), 
    duration: 0,
    style: {
      width: '600px',
    },
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Adding new monitor has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updatebulkNotExistNotificationSuccess = (notexisting,notexist,count) => {
  notification.success({
    message: `${count} monitor${count === 1 ? '' : 's'} ${count === 1 ? 'is' : 'are'} updated and ${notexist} not existing monitor${notexist === 1 ? '' : 's'} ${notexist === 1 ? 'is' : 'are'} found:`,
    description: notexisting.join(', '), 
    duration: 0,
  });
};

const bulkupdateNotificationSuccess = (count) => {
  notification.success({
    message: `${count} monitor${count === 1 ? '' : 's'} ${count === 1 ? 'has' : 'have'} been updated`,
  });
};

const addbulkNotificationError = (err) => {
  notification.error({
    message: 'Adding bulk assistants has been failed',
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Monitor Record has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating monitor has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const bulkassistantsupdateNotificationError = (err) => {
  notification.error({
    message: 'Bulk assistants update has been failed',
  });
};

const addnewmonitorupdateexistingNotificationError = (err) => {
  notification.error({
    message: 'Adding bulk assistants & updating existing assistants has been failed',
    duration: 0,
  });
};

const addnewassistantsupdateexistingNotificationSuccess = (newassistants,updatedassistants) => {
  notification.success({
    message: `${newassistants} new monitor${newassistants === 1 ? '' : 's'} ${newassistants === 1 ? 'is' : 'are'} added and ${updatedassistants} monitor${updatedassistants === 1 ? '' : 's'} ${updatedassistants === 1 ? 'is' : 'are'} updated`,
    duration:0,
  });
};

const {
  assistantsAddBegin,
  assistantsAddSuccess,
  assistantsAddErr,
  
  assistantsBulkAddBegin,
  assistantsBulkAddSuccess,
  assistantsBulkAddErr,

  assistantsReadBegin,
  assistantsReadSuccess,
  assistantsReadErr,

  addnewassistantsupdateexistingBegin,
  addnewassistantsupdateexistingSuccess,
  addnewassistantsupdateexistingErr,

  assistantsUpdateBegin,
  assistantsUpdateSuccess,
  assistantsUpdateErr,

  assistantsBulkUpdateBegin,
  assistantsBulkUpdateSuccess,
  assistantsBulkUpdateErr,
} = actions;

const assistantsDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(assistantsAddBegin());
      const response = await DataService.post('/assistantmaster/bulkadd', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(assistantsAddSuccess(response.data.message));
        addNotificationSuccess();
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(assistantsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(assistantsAddErr(err));
      addNotificationError(err);
    }
  };
};


const assistantsbulkDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(assistantsBulkAddBegin());
      const response = await DataService.post('/assistantmaster/bulkadd', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        if(response.data.duplicates.length === 0){
          await dispatch(assistantsBulkAddSuccess(response.data));
          const count = data.length;
          addbulkNotificationSuccess(count);
        }
        if(response.data.duplicates.length > 0){
          const count = data.length - response.data.duplicates.length;
          const dupcount = response.data.duplicates.length;
          await dispatch(assistantsBulkAddSuccess(response.data));
          addbulkDuplicateNotificationSuccess(response.data.duplicates,count,dupcount);
        }
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.Error;
        // await dispatch(assistantsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(assistantsBulkAddErr(err));
      addbulkNotificationError(err);
    }
  };
};

const addnewassistantsupdateexisting = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(addnewassistantsupdateexistingBegin());
      const response = await DataService.post('/assistantmaster/bulkaddupdate', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false ) {
        await dispatch(addnewassistantsupdateexistingSuccess(response.data.message));
        const newassistants = response.data.payload.newcount;
        const updatedassistants = response.data.payload.updatecount;
        addnewassistantsupdateexistingNotificationSuccess(newassistants,updatedassistants);
      } else {
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(studentsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(addnewassistantsupdateexistingErr(err));
      addnewmonitorupdateexistingNotificationError(err);
    }
  };
};

const assistantsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(assistantsReadBegin());
      const activeFSid = getItem('activeFSid');
      const query = await DataService.get(`/assistantmaster/${activeFSid}`);
      const token = getItem('access_token');
      if (token && query.data!== null && query.data.Error === false) {
        await dispatch(assistantsReadSuccess(query.data.payload));
      } else {
        if (query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut())
        }
        throw query.data.message;
        // await dispatch(assistantsReadSuccess([]));
        // await dispatch(assistantsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(assistantsReadErr(err));
    }
  };
};

const assistantsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(assistantsUpdateBegin());
      const response = await DataService.put(`/assistantmaster/bulkupdate`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(assistantsUpdateSuccess(response.data));
        updateNotificationSuccess();
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(assistantsDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(assistantsUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

const assistantsBulkDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(assistantsBulkUpdateBegin());
      const response = await DataService.put(`/assistantmaster/bulkupdate`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        if(response.data.notexitsusers.length === 0){
          await dispatch(assistantsBulkUpdateSuccess(response.data));
          const count = data.length;
          bulkupdateNotificationSuccess(count);
        }
        if(response.data.notexitsusers.length > 0){
          await dispatch(assistantsBulkUpdateSuccess(response.data));
          const notexistingassistants = response.data.notexitsusers.length;
          const updatedassistantscount = data.length - notexistingassistants;
          updatebulkNotExistNotificationSuccess(response.data.notexitsusers,notexistingassistants,updatedassistantscount);
        }
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(studentsDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(assistantsBulkUpdateErr(err));
      bulkassistantsupdateNotificationError(err);
    }
  };
};

export {
  assistantsDataRead,
  assistantsDataSubmit,
  assistantsbulkDataSubmit,
  assistantsDataUpdate,
  assistantsBulkDataUpdate,
  addnewassistantsupdateexisting
};
