import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new college has been Added',
  });
};

const addNotificationError = (err) => {
  notification.error({
    message: 'Adding new college has been failed',
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'College Record has been updated',
  });
};

const updateNotificationError = (err) => {
  notification.error({
    message: 'Updating college Record has been failed',
  });
};

const {
  collegeAddBegin,
  collegeAddSuccess,
  collegeAddErr,

  collegeReadBegin,
  collegeReadSuccess,
  collegeReadErr,

  collegeUpdateBegin,
  collegeUpdateSuccess,
  collegeUpdateErr,
} = actions;

const collegeDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(collegeAddBegin());
      const response = await DataService.post('/college/create', data);
      const token = getItem('access_token');
      await dispatch(collegeAddSuccess(response.data));
      addNotificationSuccess();
      if (token) {
      } else {
        await dispatch(collegeAddErr('No Unauthorize access'));
        addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(collegeAddErr(err));
      addNotificationError(err);
    }
  };
};

const collegeDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(collegeReadBegin());
      const query = await DataService.get('/college');
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(collegeReadSuccess(query.data.payload));
      } else {
        await dispatch(collegeReadSuccess([]));
        // await dispatch(collegeAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(collegeReadErr(err));
    }
  };
};

const collegeDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(collegeUpdateBegin());
      const response = await DataService.put(`/college/update`, data);
      const token = getItem('access_token');
      await dispatch(collegeUpdateSuccess(response.data));
      updateNotificationSuccess();
      if(token){
      }else{
        await dispatch(collegeDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(collegeUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

export {
  collegeDataRead,
  collegeDataSubmit,
  collegeDataUpdate,
};
