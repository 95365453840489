const actions = {
  GENERATE_QUIZ_ADD_BEGIN: 'GENERATE_QUIZ_ADD_BEGIN',
  GENERATE_QUIZ_ADD_SUCCESS: 'GENERATE_QUIZ_ADD_SUCCESS',
  GENERATE_QUIZ_ADD_ERR: 'GENERATE_QUIZ_ADD_ERR',
  
  READ_QUIZ_BEGIN: 'READ_QUIZ_BEGIN',
  READ_QUIZ_SUCCESS: 'READ_QUIZ_SUCCESS',
  READ_QUIZ_ERR: 'READ_QUIZ_ERR',

  QUIZ_UPDATE_BEGIN: 'QUIZ_UPDATE_BEGIN',
  QUIZ_UPDATE_SUCCESS: 'QUIZ_UPDATE_SUCCESS',
  QUIZ_UPDATE_ERR: 'QUIZ_UPDATE_ERR',

  DELETE_QUIZ_BEGIN : 'DELETE_QUIZ_BEGIN',
  DELETE_QUIZ_SUCCESS : 'DELETE_QUIZ_SUCCESS',
  DELETE_QUIZ_ERR : 'DELETE_QUIZ_ERR',
  
  GET_QUIZ_BEGIN : 'GET_QUIZ_BEGIN',
  GET_QUIZ_SUCCESS : 'GET_QUIZ_SUCCESS',
  GET_QUIZ_ERR : 'GET_QUIZ_ERR',

  generateQuizPostBegin: () => {
    return {
      type: actions.GENERATE_QUIZ_ADD_BEGIN,
    };
  },

  generateQuizPostSuccess: (data) => {
    return {
      type: actions.GENERATE_QUIZ_ADD_SUCCESS,
      data,
    };
  },

  generateQuizPostErr: (err) => {
    return {
      type: actions.GENERATE_QUIZ_ADD_ERR,
      err,
    };
  },

  quizQuestionsReadBegin: () => {
    return {
      type: actions.READ_QUIZ_BEGIN,
    };
  },

  quizQuestionsReadSuccess: (data) => {
    return {
      type: actions.READ_QUIZ_SUCCESS,
      data,
    };
  },

  quizQuestionsReadErr: (err) => {
    return {
      type: actions.READ_QUIZ_ERR,
      err,
    };
  },

  QuizUpdateBegin: () => {
    return {
      type: actions.QUIZ_UPDATE_BEGIN,
    };
  },

  QuizUpdateSuccess: (data) => {
    return {
      type: actions.QUIZ_UPDATE_SUCCESS,
      data,
    };
  },

  QuizUpdateErr: (err) => {
    return {
      type: actions.QUIZ_UPDATE_ERR,
      err,
    };
  },

  deleteQuizBegin: () => {
    return {
      type: actions.DELETE_QUIZ_BEGIN,
    };
  },

  deleteQuizSuccess: (data) => {
    return {
      type: actions.DELETE_QUIZ_SUCCESS,
      data,
    };
  },

  deleteQuizErr: (err) => {
    return {
      type: actions.DELETE_QUIZ_ERR,
      err,
    };
  },
 
  getQuizBegin: () => {
    return {
      type: actions.GET_QUIZ_BEGIN,
    };
  },

  getQuizSuccess: (data) => {
    return {
      type: actions.GET_QUIZ_SUCCESS,
      data,
    };
  },

  getQuizErr: (err) => {
    return {
      type: actions.GET_QUIZ_ERR,
      err,
    };
  },

};

export default actions;
