const actions = {
  SUBJECTS_ADD_BEGIN: 'SUBJECTS_ADD_BEGIN',
  SUBJECTS_ADD_SUCCESS: 'SUBJECTS_ADD_SUCCESS',
  SUBJECTS_ADD_ERR: 'SUBJECTS_ADD_ERR',

  SUBJECTS_READ_BEGIN: 'SUBJECTS_READ_BEGIN',
  SUBJECTS_READ_SUCCESS: 'SUBJECTS_READ_SUCCESS',
  SUBJECTS_READ_ERR: 'SUBJECTS_READ_ERR',

  SUBJECTS_UPDATE_BEGIN: 'SUBJECTS_UPDATE_BEGIN',
  SUBJECTS_UPDATE_SUCCESS: 'SUBJECTS_UPDATE_SUCCESS',
  SUBJECTS_UPDATE_ERR: 'SUBJECTS_UPDATE_ERR',

  subjectsAddBegin: () => {
    return {
      type: actions.SUBJECTS_ADD_BEGIN,
    };
  },

  subjectsAddSuccess: (data) => {
    return {
      type: actions.SUBJECTS_ADD_SUCCESS,
      data,
    };
  },

  subjectsAddErr: (err) => {
    return {
      type: actions.SUBJECTS_ADD_ERR,
      err,
    };
  },

  subjectsReadBegin: () => {
    return {
      type: actions.SUBJECTS_READ_BEGIN,
    };
  },

  subjectsReadSuccess: (data) => {
    return {
      type: actions.SUBJECTS_READ_SUCCESS,
      data,
    };
  },

  subjectsReadErr: (err) => {
    return {
      type: actions.SUBJECTS_READ_ERR,
      err,
    };
  },

  subjectsUpdateBegin: () => {
    return {
      type: actions.SUBJECTS_UPDATE_BEGIN,
    };
  },

  subjectsUpdateSuccess: (data) => {
    return {
      type: actions.SUBJECTS_UPDATE_SUCCESS,
      data,
    };
  },

  subjectsUpdateErr: (err) => {
    return {
      type: actions.SUBJECTS_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
