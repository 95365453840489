import actions from './actions';

const {

  CREATE_INTERVIEWER_BEGIN,
  CREATE_INTERVIEWER_SUCCESS,
  CREATE_INTERVIEWER_ERR,

  UPDATE_INTERVIEWER_BEGIN,
  UPDATE_INTERVIEWER_SUCCESS,
  UPDATE_INTERVIEWER_ERR,

  GET_INTERVIEWER_BEGIN,
  GET_INTERVIEWER_SUCCESS,
  GET_INTERVIEWER_ERR,

} = actions;


const createInterviewerInitialState = {
  data: null,
  loading: false,
  error: null,
};

const updateInterviewerInitialState = {
  data: [],
  loading: false,
  error: null,
}

const getInterviewerInitialState = {
  data: [],
  loading: false,
  error: null,
}


const createInterviewerDataReducer = (state = createInterviewerInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case CREATE_INTERVIEWER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CREATE_INTERVIEWER_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case CREATE_INTERVIEWER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const getInterviewerDataReducer = (state = getInterviewerInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_INTERVIEWER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case GET_INTERVIEWER_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case GET_INTERVIEWER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const updateInterviewerDataReducer = (state = updateInterviewerInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case UPDATE_INTERVIEWER_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_INTERVIEWER_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case UPDATE_INTERVIEWER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { createInterviewerDataReducer, getInterviewerDataReducer, updateInterviewerDataReducer, };
