import actions from './actions';

const {
  FS_ADD_BEGIN,
  FS_ADD_SUCCESS,
  FS_ADD_ERR,

  FS_READ_BEGIN,
  FS_READ_SUCCESS,
  FS_READ_ERR,

  FS_UPDATE_BEGIN,
  FS_UPDATE_SUCCESS,
  FS_UPDATE_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const FSReadReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};


const FSAddReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FS_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const FSUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case FS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case FS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { FSReadReducer, FSUpdateReducer, FSAddReducer };
