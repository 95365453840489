import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {

  getScheduledDataBegin,
  getScheduledDataSuccess,
  getScheduledDataErr,

  getInterviewByScheduleBegin,
  getInterviewByScheduleSuccess,
  getInterviewByScheduleErr,

  getInterviewAttemptsBegin,
  getInterviewAttemptsSuccess,
  getInterviewAttemptsErr,

  overallSummaryBegin,
  overallSummarySuccess,
  overallSummaryErr,

  scheduleWiseSummaryBegin,
  scheduleWiseSummarySuccess,
  scheduleWiseSummaryErr,

} = actions;


const getScheduledData = () => {
  return async (dispatch) => {
    try {
      await dispatch(getScheduledDataBegin());
      const query = await DataService.get(`/topic-schedule/fsschedules`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getScheduledDataSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getScheduledDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getScheduledDataSuccess(null));
        }
      }
      else {
        await dispatch(getScheduledDataSuccess(null));
      }
    } catch (err) {
      await dispatch(getScheduledDataErr(err));
    }
  };
};


const getInterviewsBySchedule = (scheduleName) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewByScheduleBegin());
      const response = await DataService.get(`/interview-master/scheduledfsinterviews/${scheduleName}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewByScheduleSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewByScheduleSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewByScheduleSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewByScheduleSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewByScheduleErr(err));
    }
  };
};

const getInterviewAttemptsById = (interviewId) => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewAttemptsBegin());
      const response = await DataService.get(`/interview-attempts/findbyfsinterviewMr/${interviewId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewAttemptsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getInterviewAttemptsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewAttemptsSuccess([]));
        }
      }
      else {
        await dispatch(getInterviewAttemptsSuccess([]));
      }
    } catch (err) {
      await dispatch(getInterviewAttemptsErr(err));
    }
  };
};

const GetOverallSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(overallSummaryBegin());
      const query = await DataService.get(`/overallsummary`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(overallSummarySuccess(query.data.payload));
      } else {
        await dispatch(overallSummarySuccess(null));
      }
    } catch (err) {
      await dispatch(overallSummaryErr(err));
    }
  };
};

const ScheduleWiseSummary = () => {
  return async (dispatch) => {
    try {
      await dispatch(scheduleWiseSummaryBegin());
      const query = await DataService.get(`/schedulewisesummary`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(scheduleWiseSummarySuccess(query.data.payload));
      } else {
        await dispatch(scheduleWiseSummarySuccess([]));
      }
    } catch (err) {
      await dispatch(scheduleWiseSummaryErr(err));
    }
  };
};


export {
  getScheduledData,
  getInterviewAttemptsById,
  getInterviewsBySchedule,
  GetOverallSummary,
  ScheduleWiseSummary
};
