const actions = {

  TOPICSSUBJECTS_READ_BEGIN: 'TOPICSSUBJECTS_READ_BEGIN',
  TOPICSSUBJECTS_READ_SUCCESS: 'TOPICSSUBJECTS_READ_SUCCESS',
  TOPICSSUBJECTS_READ_ERR: 'TOPICSSUBJECTS_READ_ERR',

  topicssubjectsReadBegin: () => {
    return {
      type: actions.TOPICSSUBJECTS_READ_BEGIN,
    };
  },

  topicssubjectsReadSuccess: (data) => {
    return {
      type: actions.TOPICSSUBJECTS_READ_SUCCESS,
      data,
    };
  },

  topicssubjectsReadErr: (err) => {
    return {
      type: actions.TOPICSSUBJECTS_READ_ERR,
      err,
    };
  },

};

export default actions;
