import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {

  getAcademicScheduledDataBegin,
  getAcademicScheduledDataSuccess,
  getAcademicScheduledDataErr,

  getAcademicInterviewByScheduleBegin,
  getAcademicInterviewByScheduleSuccess,
  getAcademicInterviewByScheduleErr,

  getAcademicInterviewAttemptsBegin,
  getAcademicInterviewAttemptsSuccess,
  getAcademicInterviewAttemptsErr,

} = actions;


const getAcademicScheduledData = () => {
  return async (dispatch) => {
    try {
      await dispatch(getAcademicScheduledDataBegin());
      const query = await DataService.get(`/topic-schedule/academicschedules`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getAcademicScheduledDataSuccess(data));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getAcademicScheduledDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getAcademicScheduledDataSuccess(null));
        }
      }
      else {
        await dispatch(getAcademicScheduledDataSuccess(null));
      }
    } catch (err) {
      await dispatch(getAcademicScheduledDataErr(err));
    }
  };
};


const getAcademicInterviewsBySchedule = (scheduleName) => {
  return async (dispatch) => {
    try {
      await dispatch(getAcademicInterviewByScheduleBegin());
      const response = await DataService.get(`/interview-master/scheduledacademicinterviews/${scheduleName}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getAcademicInterviewByScheduleSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getAcademicInterviewByScheduleSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getAcademicInterviewByScheduleSuccess([]));
        }
      }
      else {
        await dispatch(getAcademicInterviewByScheduleSuccess([]));
      }
    } catch (err) {
      await dispatch(getAcademicInterviewByScheduleErr(err));
    }
  };
};

const getAcademicInterviewAttemptsById = (interviewId) => {
  return async (dispatch) => {
    try {
      await dispatch(getAcademicInterviewAttemptsBegin());
      const response = await DataService.get(`/interview-attempts/findbyacademicinterviewMr/${interviewId}`);
      const token = getItem('access_token');
      const data = response.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getAcademicInterviewAttemptsSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(getAcademicInterviewAttemptsSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(getAcademicInterviewAttemptsSuccess([]));
        }
      }
      else {
        await dispatch(getAcademicInterviewAttemptsSuccess([]));
      }
    } catch (err) {
      await dispatch(getAcademicInterviewAttemptsErr(err));
    }
  };
};


export {
  getAcademicScheduledData,
  getAcademicInterviewsBySchedule,
  getAcademicInterviewAttemptsById,
};
