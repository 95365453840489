/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message, Checkbox, Space } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { DownloadOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'; // Import icons
import { ProjectList, ProjectSorting } from '../project/style';
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { addnewstudentsupdateexisting } from '../../redux/students/actionCreator';
import { batchesDataRead } from '../../redux/batches/actionCreator';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import { ImportStyleWrap } from '../styled';
import Papa from 'papaparse';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { collegeDataRead } from '../../redux/Colleges/actionCreator';
import { AcademicStudentsDataRead, academicStudentsDataSubmit, academicStudentsBulkDataSubmit, academicstudentsBulkDataUpdate } from '../../redux/academicstudents/actionCreator';

const { Option } = Select;

const sampleCSVData = [
    {
        name: "student1",
        username: "studentuser1",
        password: "studentuser1",
        email: "student1@gmail.com",

    },
    {
        name: "student2",
        username: "studentuser2",
        password: "studentuser2",
        email: "student2@gmail.com",
    },
    {
        name: "student3",
        username: "studentuser3",
        password: "studentuser3",
        email: "student3@gmail.com",
    }];

function AcademicStudents() {
    const clgName = getItem('collegeName');
    const loggedadmin = getItem('username');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const [isNewModalVisible, setIsNewModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();
    const [bulkForm] = Form.useForm();

    const [csvFile, setCSVFile] = useState(null);
    const [csvFileList, setCSVFileList] = useState([]);
    const [parsedCSVData, setParsedCSVData] = useState([]);
    const [updateRecord, setUpdateRecord] = useState(null);

    const dispatch = useDispatch();
    const {
        students,
        isLoading,
        StudentsUpdateRes,
        isStudentsUpdating,
        StudentsAddRes,
        isStudentsAdding,
        StudentsBulkAddRes,
        isStudentsBulkAdding,
        StudentsBulkUpdateRes,
        isStudentsBulkUpdating,
        StudentsBulkAddUpdateRes,
        isStudentsBulkAddingUpdating,
        colleges,
        isCollegesLoading
    } = useSelector((state) => {
        return {
            students: state.AcademicStudents?.data,
            isLoading: state.AcademicStudents.loading,
            StudentsUpdateRes: state.StudentsUpdateRes?.data,
            isStudentsUpdating: state.StudentsUpdateRes.loading,
            StudentsAddRes: state.AcademicStudentsAddRes?.data,
            isStudentsAdding: state.AcademicStudentsAddRes.loading,
            StudentsBulkAddRes: state.AcademicStudentsBulkAddRes?.data,
            isStudentsBulkAdding: state.AcademicStudentsBulkAddRes.loading,
            StudentsBulkUpdateRes: state.StudentsBulkUpdateRes?.data,
            isStudentsBulkUpdating: state.StudentsBulkUpdateRes.loading,
            StudentsBulkAddUpdateRes: state.StudentsBulkAddUpdateRes?.data,
            isStudentsBulkAddingUpdating: state.StudentsBulkAddUpdateRes.loading,
            colleges: state.College.data,
            isCollegesLoading: state.College.loading,
        };
    });


    useEffect(() => {
        dispatch(AcademicStudentsDataRead());
        dispatch(batchesDataRead());
        if (colleges.length === 0) {
            dispatch(collegeDataRead());
        }
    }, [dispatch]);

    useEffect(() => {
        if (StudentsUpdateRes !== null) {
            dispatch(AcademicStudentsDataRead());
        }
    }, [StudentsUpdateRes])

    useEffect(() => {
        if (StudentsBulkAddRes !== null) {
            dispatch(AcademicStudentsDataRead());
        }
    }, [StudentsBulkAddRes])

    useEffect(() => {
        if (StudentsBulkUpdateRes !== null) {
            dispatch(AcademicStudentsDataRead());
        }
    }, [StudentsBulkUpdateRes])

    useEffect(() => {
        if (StudentsBulkAddUpdateRes !== null) {
            dispatch(AcademicStudentsDataRead());
        }
    }, [StudentsBulkAddUpdateRes])

    useEffect(() => {
        if (StudentsAddRes !== null) {
            dispatch(AcademicStudentsDataRead());
        }
    }, [StudentsAddRes])

    const [dataSource, setDataSource] = useState([]);
    const [GoodData, setGoodData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedBatchId, setSelectedBatchId] = useState(null);
    const [searchText, setsearchText] = useState(null);

    const [isUsernameChecked, setUsernameChecked] = useState(true);
    const [isNameChecked, setNameChecked] = useState(false);
    const [isEmailChecked, setEmailChecked] = useState(false);
    const [isPasswordChecked, setPasswordChecked] = useState(false);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isStatusChecked, setStatusChecked] = useState(false);


    const handleAllCheckboxToggle = () => {
        const allCheckedState = !isAllChecked;
        setIsAllChecked(allCheckedState);
        if (selectedMode === 'Addnewstudents') {
            setIsAllChecked(true);
        }
        if (selectedMode === '' && allCheckedState === true) {
            setEmailChecked(true);
            setNameChecked(true);
            setPasswordChecked(true);
            setStatusChecked(true)
        }
        if (selectedMode === '' && allCheckedState === false) {
            setEmailChecked(false);
            setNameChecked(false);
            setPasswordChecked(false);
            setStatusChecked(false)
        }
        if ((selectedMode === 'Addnewstudents' || selectedMode === 'AddNewUpdateExistingStudents') && allCheckedState === true) {
            setEmailChecked(true);
            setNameChecked(true);
            setPasswordChecked(true);
            setStatusChecked(true)
        }
        if ((selectedMode === 'Addnewstudents' || selectedMode === 'AddNewUpdateExistingStudents') && allCheckedState === false) {
            setEmailChecked(false);
            setNameChecked(true);
            setPasswordChecked(true);
            setStatusChecked(false)
        }
        if (selectedMode === 'Updateexistingstudents' && allCheckedState === false) {
            setEmailChecked(false);
            setNameChecked(false);
            setPasswordChecked(false);
            setStatusChecked(false)
        }
        if (selectedMode === 'Updateexistingstudents' && allCheckedState === true) {
            setEmailChecked(true);
            setNameChecked(true);
            setPasswordChecked(true);
            setStatusChecked(true)
        }
    };

    const handleModeChange = (value) => {
        const shouldCheckNameAndPassword = value === 'Addnewstudents' || value === 'AddNewUpdateExistingStudents';
        setSelectedMode(value);
        setNameChecked(shouldCheckNameAndPassword);
        setPasswordChecked(shouldCheckNameAndPassword);
        setStatusChecked(shouldCheckNameAndPassword)
        setEmailChecked(shouldCheckNameAndPassword)
    };

    const handleNameCheckboxChange = (e) => {
        if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
            setNameChecked(e.target.checked);
        }
    };

    const handlePasswordCheckboxChange = (e) => {
        if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
            setPasswordChecked(e.target.checked);
        }
    };

    const handleStatusCheckboxChange = (e) => {
        if (selectedMode !== 'Addnewstudents' && selectedMode !== 'AddNewUpdateExistingStudents') {
            setStatusChecked(e.target.checked);
        }
    };

    useEffect(() => {
        const allCheckboxes = [
            isNameChecked,
            isEmailChecked,
            isPasswordChecked,
            isStatusChecked
        ];
        const allChecked = allCheckboxes.every((checkbox) => checkbox === true);
        setIsAllChecked(allChecked);
    }, [isNameChecked, isPasswordChecked, isEmailChecked, isStatusChecked]);

    function formatDateTime(dateTime) {
        const dateObject = dateTime;
        const formattedDate = dateObject.split('T')[0].replace(/-/g, '/');
        return formattedDate;
    }

    useEffect(() => {
        if (Array.isArray(students)) {
            const filteredData = students?.map((item, index) => ({
                key: item.id,
                sno: index + 1,
                id: item.id,
                name: item.name,
                username: item.usermaster.username,
                collegeId: item.usermaster?.college?.id,
                collegecode: item.usermaster?.college?.code,
                email: item.usermaster.email ? item.usermaster.email : 'NA',
                updatedBy: item.updatedBy !== '' ? item.updatedby : '--------',
                updatedon: formatDateTime(item.updatedon),
                status: <span className={`status ${item.usermaster.isActive.toLowerCase()}`}>{item.usermaster.isActive}</span>,
                isActive: item.usermaster.isActive

            }));
            setDataSource(filteredData);
            setGoodData(filteredData);
        }
        setLoading(false);
    }, [students])

    const checkSearch = (i) => {
        for (let j in i) {
            if (i[j] !== null && i[j]?.toString().toLowerCase().includes(searchText?.toString().toLowerCase())) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        let tempValues = [...GoodData];
        if (searchText != null) {
            tempValues = tempValues && tempValues?.filter(checkSearch);
        }
        setDataSource(tempValues);
    }, [searchText])

    const handleSearch = (e) => {
        setsearchText(e)
    }

    const handleBatchChange = (value) => {
        setSelectedBatchId(value);
    };

    const handleRemoveCSVFile = () => {
        setCSVFile(null);
        setCSVFileList([])
    }

    const handleCSVFileChange = (uploadedFile) => {
        if (uploadedFile !== null && uploadedFile.fileList.length !== 0) {
            // message.success("File Uploaded");
            setTimeout(() => {
                const csvFile = uploadedFile.fileList[0];
                Papa.parse(csvFile.originFileObj, {
                    complete: (result) => {
                        const modifiedCSVData = result?.data?.map((row) => {
                            if (row) {
                                const newRow = { ...row, role: "ACADEMICSTUDENT" };
                                return newRow;
                            }
                        });
                        if (modifiedCSVData.length > 500) {
                            setCSVFile(null);
                            setCSVFileList([])
                            return message.error("Max 500 Records are allowed!")
                        }
                        else {
                            setCSVFileList(uploadedFile.fileList);
                            setParsedCSVData(modifiedCSVData);
                        }
                    },
                    header: true,
                });
            }, 1000);
        } else {
            message.warning("File Removed");
            handleRemoveCSVFile();
        }
    };

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'College',
            dataIndex: 'collegecode',
            key: 'collegecode',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Updated On',
            dataIndex: 'updatedon',
            key: 'updatedon',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },
    ];

    if (clgName === 'ALL') {
        columns.splice(8, 0,
            {
                title: 'College',
                dataIndex: 'collegename',
                key: 'collegename',
            }
        );
    }

    if (loggedadmin !== 'superadmin') {
        columns.push({
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='table-actions'>
                    <Button
                        key="updateStudent"
                        onClick={() => handleUpdate(record)}
                        className="btn-icon "
                        type="primary"
                        to="#"
                        shape="circle"
                    >
                        <UilEdit />
                    </Button>
                </div>
            ),
        });
    }

    const handleButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleButtonBulkClick = () => {
        setSelectedFieldsData([])
        setIsBulkModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleNewModalCancel = () => {
        setIsNewModalVisible(false);
        setDispatchData([]);
        setSelectedFieldsData([]);
        setSelectedMode('');
    }

    const handleModalCancell = () => {
        setIsBulkModalVisible(false);
        setCSVFile(null);
        setCSVFileList([]);
        bulkForm.resetFields();
        setNameChecked(false);
        setEmailChecked(false);
        setPasswordChecked(false);
        setStatusChecked(false)
    };

    const [selectedFieldsData, setSelectedFieldsData] = useState([]);
    const [dispatchData, setDispatchData] = useState([]);
    const [selectedMode, setSelectedMode] = useState('');

    const handleBulkModalPreview = async () => {
        await bulkForm.validateFields().then(async (values) => {
            const selectedFields = [];
            if (isUsernameChecked) selectedFields.push("username");
            if (isNameChecked) selectedFields.push("name");
            if (isPasswordChecked) selectedFields.push("password");
            if (isEmailChecked) selectedFields.push("email");
            selectedFields.push('role');
            const modifiedCSVData = await Promise.all(parsedCSVData?.map(async (row) => {
                try {
                    if ((row['username'] !== '' && row['username'] !== undefined) || (row['name'] !== '' && row['name'] !== undefined) || (row['password'] !== '' && row['password'] !== undefined)) {
                        const filteredRow = {};
                        selectedFields.forEach((field) => {
                            if (field !== "" && field !== undefined) {
                                if ((field === 'username' && isUsernameChecked) || (field === 'name' && isNameChecked) || (field === 'password' && isPasswordChecked) || (field === 'email' && isEmailChecked)) {
                                    filteredRow[field] = row[field];
                                    if ((field === 'username' || field === 'name' || field === 'password') && row[field] !== '') {
                                        filteredRow['valid'] = true
                                    }
                                    else if ((field === 'username' || field === 'name' || field === 'password') && row[field] === '') {
                                        filteredRow['valid'] = false
                                    }
                                    else if ((row['username'] !== "" || row['username'] !== undefined) && (field === 'email') && (row[field] == '' || row[field] != '')) {
                                        filteredRow['valid'] = true
                                    }
                                }
                            }
                        });
                        filteredRow['collegeId'] = values.college;
                        selectedFieldsData.push(filteredRow);
                        return filteredRow
                    }

                } catch (error) {
                    // console.error('Error processing row:', error);
                    // Handle error, possibly return a default or placeholder value
                    return {}; // Return an empty object as a placeholder
                }
            }));
            setSelectedMode(values.mode);
            setDispatchData(modifiedCSVData);
            setCSVFile(null);
            setCSVFileList([]);
            setIsBulkModalVisible(false);
            bulkForm.resetFields();
            setNameChecked(false);
            setEmailChecked(false);
            setPasswordChecked(false);
            setStatusChecked(false)
            setIsNewModalVisible(true);
        }).catch((error) => {
            message.error("Selected file is incorrect")
            console.error('Error while validating form:', error);
        });
    };

    let str = '';
    if (selectedMode === 'Addnewstudents') {
        str = 'Add';
    } else if (selectedMode === 'Updateexistingstudents') {
        str = 'Update';
    } else if (selectedMode === 'AddNewUpdateExistingStudents') {
        str = 'Add & Update';
    }

    const columnns = Object.keys(selectedFieldsData[0] || {})?.map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
    }));

    columnns.length !== 0 && columnns.push({
        title: 'Valid',
        dataIndex: 'valid',
        key: 'valid',
        render: (valid) => (
            <Space size="middle">
                {valid ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
            </Space>
        ),
    });

    const tableData = selectedFieldsData?.map((item, index) => {
        const rowData = {
            ...item,
            key: index,
        };

        if (!rowData.valid) {
            str = '';
        }
        return rowData;
    });

    const handleBulkStudentsAdd = () => {
        if (str === 'Add') {
            if (dispatchData.length > 0 && dispatchData.length <= 500) {
                dispatch(academicStudentsBulkDataSubmit(dispatchData));
            }
            else {
                message.error("Max 500 Records are allowed!")
            }
        } else if (str === 'Update') {
            dispatch(academicstudentsBulkDataUpdate(dispatchData));
        } else if (str === 'Add & Update') {
            dispatch(addnewstudentsupdateexisting(dispatchData));
        }
        setSelectedFieldsData([]);
        setIsNewModalVisible(false);
        setSelectedMode('');
        str = '';
    }

    const handleModalAdd = () => {
        form.validateFields().then((values) => {
            const newRow = {
                name: values.name,
                username: values.username,
                password: values.password,
                email: (values.email === undefined) || (values.email?.trim() === "") ? null : values.email,
                collegeId: values.college
            };
            form.resetFields();
            setIsModalVisible(false);
            dispatch(academicStudentsDataSubmit([newRow]));
        })
            .catch((error) => {
                console.error('Error while validating form:', error);
            });
    };

    const handleUpdate = (record) => {
        setUpdateRecord(record);
        setIsUpdateModalVisible(true);
        const email = record.email !== 'NA' ? record.email : null;
        updateForm.setFieldsValue({
            name: record.name,
            email: email,
            username: record.username,
            isActive: record.isActive,
            college: record.collegeId
        });
    };

    const handleModalUpdate = () => {
        updateForm.validateFields().then((values) => {
            const updatedRecord = {
                id: parseInt(updateRecord.id),
                name: values.name,
                email: (values.email === undefined) || (values.email?.trim() === "") ? null : values.email,
                username: updateRecord.username,
                isActive: values.isActive,
                collegeId: values.college
            };
            setIsUpdateModalVisible(false);
            dispatch(academicstudentsBulkDataUpdate([updatedRecord]));
        }).catch((error) => {
            console.error('Error while validating form:', error);
        });
    };

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title={`Academic Students`}
                buttons={
                    loggedadmin !== 'superadmin' ?
                        [
                            <Button id="students-page-add_student-button" onClick={handleButtonClick} key="AddStudent" type="primary" size="default">
                                Add Student
                            </Button>,
                            <Button id="students-page-add/update-bulk-student-button" onClick={handleButtonBulkClick} key="AddBulkStudents" type="primary" size="default">
                                Bulk Upload
                            </Button>,
                        ] : []}
            />
            <Main>
                {isLoading || loading || isStudentsUpdating || isStudentsAdding || isStudentsBulkAdding || isStudentsBulkUpdating || isStudentsBulkAddingUpdating ?
                    (
                        <div className="custom-loading-spin-with-text">
                            <CustomSpinner />
                            <p style={{ fontWeight: 'bold', color: '#006666' }}>
                                Loading please wait...
                            </p>
                        </div>
                    ) : (
                        <Row gutter={25}>
                            <Col sm={24} xs={24}>
                                <ProjectSorting>
                                    <div className='project-sort-bar'>
                                        <div className="project-sort-search">
                                            <AutoComplete id="students-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                                        </div>
                                    </div>
                                </ProjectSorting>
                                <Cards headless>
                                    <TableWrapper>
                                        <ProjectList>
                                            <div className='table-responsive'>
                                                <Table dataSource={dataSource} columns={columns} />
                                            </div>
                                        </ProjectList>
                                    </TableWrapper>
                                </Cards>
                            </Col>
                        </Row>
                    )
                }
            </Main>
            <Modal
                title="Add Student"
                open={isModalVisible}
                onCancel={handleModalCancel}
                maskClosable={false}
                footer={[
                    <Button id="add-student-button" key="AddStudentRecord" type="primary" onClick={handleModalAdd}>
                        Add
                    </Button>,
                ]}
                width={850}
            >
                <Form id="add-student-form" form={form} layout="vertical">
                    <Row gutter={25}>
                        <Col md={12}>
                            <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter student name" }]}>
                                <Input id="add-student-enter-student-name" allowClear placeholder="Enter name" />
                            </Form.Item>
                            <Form.Item name="username" label="User Name" rules={[{ required: true, message: "Please enter student username" }]}>
                                <Input id="add-student-enter-student-username" allowClear placeholder="Enter username" />
                            </Form.Item>
                            <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please enter password" }]}>
                                <Input.Password id="add-student-enter-password" placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="email" label="Email (optional)" rules={[{ required: false, message: "Please enter email", type: 'email' }]} >
                                <Input id="add-student-enter-email" inputMode='email' allowClear placeholder="Ex: xyz@gmail.com" />
                            </Form.Item>
                            <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
                                <Select id="add-student-select-college" placeholder="select"
                                    loading={isCollegesLoading}
                                >
                                    {colleges?.map((college) => (
                                        <Option key={college.id} value={college.id}>
                                            {college.code}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <Modal
                title="Bulk Upload"
                open={isBulkModalVisible}
                onCancel={handleModalCancell}
                maskClosable={false}
                footer={[
                    <Button id="add/update-bulk-academic-students-preview-button" key="AddBulkStudentsRecords" type="primary" onClick={handleBulkModalPreview} disabled={csvFileList.length === 0}>
                        Preview
                    </Button>,
                ]}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample File:</p>
                    <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>academic_students_bulk_upload.csv</span>
                    <DownloadOutlined id="academic-students-bulk-add/update-file-upload"
                        style={{ verticalAlign: 'middle' }}
                        className="download-icon"
                        onClick={() => {
                            const csvContent = Papa.unparse(sampleCSVData);
                            const blob = new Blob([csvContent], { type: "text/csv" });
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = "academic_students_bulk_upload.csv";
                            link.click();
                        }}
                    />
                </div>
                <Form id="students-bulk-add/update-form" form={bulkForm} layout="vertical" style={{ marginTop: '15px' }}>
                    <Form.Item name="mode" label="Mode" rules={[{ required: true, message: "Please select mode" }]}>
                        <Select id="students-page-select-mode" placeholder="Select mode" onChange={handleModeChange}>
                            <Option value="Addnewstudents">Add new students</Option>
                            {/* <Option value="Updateexistingstudents">Update existing students</Option>
                            <Option value="AddNewUpdateExistingStudents">Add new & update existing students</Option> */}
                        </Select>
                    </Form.Item>
                    {
                        (selectedMode !== "Addnewstudents" && selectedMode !== "") &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className='mb-10'>Select the columns to add/update</span>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <Checkbox id="students-page-username-checkbox" checked={isUsernameChecked} disabled>
                                    Username
                                </Checkbox>
                                <Checkbox id="students-page-all-checkbox" checked={isAllChecked} onChange={handleAllCheckboxToggle}>
                                    All Columns
                                </Checkbox>
                                <Checkbox id="students-page-name-checkbox" checked={isNameChecked} onChange={handleNameCheckboxChange}>
                                    Name
                                </Checkbox>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Checkbox id="students-page-password-checkbox" checked={isPasswordChecked} onChange={handlePasswordCheckboxChange}>
                                    Password
                                </Checkbox>
                                <Checkbox id="students-page-email-checkbox" checked={isEmailChecked} onChange={(e) => setEmailChecked(e.target.checked)}>
                                    Email
                                </Checkbox>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Checkbox id="teacher-page-status-checkbox" checked={isStatusChecked} onChange={handleStatusCheckboxChange}>
                                    Status
                                </Checkbox>
                            </div>
                        </div>
                    }
                    <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
                        <Select id="add-student-select-college" placeholder="select"
                            loading={isCollegesLoading}
                        >
                            {colleges?.map((college) => (
                                <Option key={college.id} value={college.id}>
                                    {college.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item name="batch" label="batch" rules={[{ required: true, message: "Please select batch" }]}>
                        <Select id="add-student-select-batch" placeholder='Select batch'
                            showSearch
                            filterOption={(inputValue, option) =>
                                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                            }
                        >
                            {batchesData?.map((batch) => (
                                <Option key={batch.name} value={batch.id}>
                                    {batch.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item> */}
                    <Form.Item name="file" rules={[{ required: true, message: 'Please upload csv file' }]} label="File (Max: 500 Records)">
                        <ImportStyleWrap>
                            <Dragger id="students-add/update-file-upload"
                                maxCount={1}
                                beforeUpload={uploadedFile => {
                                    if (uploadedFile !== null) {
                                        setCSVFile(uploadedFile);
                                        return false;
                                    }
                                }}
                                onChange={handleCSVFileChange}
                                onRemove={handleRemoveCSVFile}
                                fileList={csvFileList}
                                accept=".csv"
                            >
                                <p className="ant-upload-drag-icon">
                                    <UilExport />
                                </p>
                                <Heading as="h4" className="ant-upload-text">
                                    Click here to select .csv File
                                </Heading>
                            </Dragger>
                        </ImportStyleWrap>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Preview Students"
                open={isNewModalVisible}
                onCancel={handleNewModalCancel}
                maskClosable={false}
                width={1400}
                footer={[
                    <Button id="academic-students-preview-cancel-button" key="cancelpreview" type="light" onClick={handleNewModalCancel}>
                        Cancel
                    </Button>,
                    <Button id="academic-students-preview-add/update/add_new_update_existing-button" key="addpreviewstudents" type="primary" onClick={handleBulkStudentsAdd}>
                        {str}
                    </Button>
                ]}
            >
                <Table columns={columnns} dataSource={tableData} />
            </Modal>
            <Modal
                title={`Update info of ${updateRecord?.username}`}
                open={isUpdateModalVisible}
                onCancel={() => setIsUpdateModalVisible(false)}
                maskClosable={false}
                footer={[
                    <Button id="update-student-button" key="UpdateStudents" type="primary" onClick={handleModalUpdate}>
                        Update
                    </Button>
                ]}
            >
                <Form id="update-student-form" form={updateForm} layout="vertical">
                    {/* <Form.Item name="username" label="Username" rules={[{ required: true, message: "Please enter Username" }]}>
                        <Input id="update-student-enter-user-name" placeholder="Enter Username" disabled readOnly />
                    </Form.Item> */}
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter student name" }]}>
                        <Input id="update-student-enter-student-name" placeholder="Enter student name" />
                    </Form.Item>
                    <Form.Item name="email" label="Email (optional)" rules={[{ required: false, message: "Please enter email" }]}>
                        <Input id="update-student-enter-student-email" placeholder="Enter email(Ex: xyz@gmail.com)" />
                    </Form.Item>
                    <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
                        <Select id="update-student-select-college" placeholder="select"
                            loading={isCollegesLoading}
                        >
                            {colleges?.map((college) => (
                                <Option key={college.id} value={college.id}>
                                    {college.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="isActive" label="Status" rules={[{ required: true }]}>
                        <Select id="edit-teacher_select-status">
                            <Option id="edit-teacher_select-status-active" value="ACTIVE">
                                ACTIVE
                            </Option>
                            <Option id="edit-teacher_select-status-inactive" value="INACTIVE">
                                INACTIVE
                            </Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AcademicStudents;