const actions = {

  GET_SCHEDULED_DATA_BEGIN: 'GET_SCHEDULED_DATA_BEGIN',
  GET_SCHEDULED_DATA_SUCCESS: 'GET_SCHEDULED_DATA_SUCCESS',
  GET_SCHEDULED_DATA_ERR: 'GET_SCHEDULED_DATA_ERR',

  GET_INTERVIEW_BY_SCHEDULE_BEGIN: 'GET_INTERVIEW_BY_SCHEDULE_BEGIN',
  GET_INTERVIEW_BY_SCHEDULE_SUCCESS: 'GET_INTERVIEW_BY_SCHEDULE_SUCCESS',
  GET_INTERVIEW_BY_SCHEDULE_ERR: 'GET_INTERVIEW_BY_SCHEDULE_ERR',

  GET_INTERVIEW_ATTEMPTS_BEGIN: 'GET_INTERVIEW_ATTEMPTS_BEGIN',
  GET_INTERVIEW_ATTEMPTS_SUCCESS: 'GET_INTERVIEW_ATTEMPTS_SUCCESS',
  GET_INTERVIEW_ATTEMPTS_ERR: 'GET_INTERVIEW_ATTEMPTS_ERR',

  OVERALL_SUMMARY_BEGIN: 'OVERALL_SUMMARY_BEGIN',
  OVERALL_SUMMARY_SUCCESS: 'OVERALL_SUMMARY_SUCCESS',
  OVERALL_SUMMARY_ERR: 'OVERALL_SUMMARY_ERR',

  SCHEDULE_WISE_SUMMARY_BEGIN: 'SCHEDULE_WISE_SUMMARY_BEGIN',
  SCHEDULE_WISE_SUMMARY_SUCCESS: 'SCHEDULE_WISE_SUMMARY_SUCCESS',
  SCHEDULE_WISE_SUMMARY_ERR: 'SCHEDULE_WISE_SUMMARY_ERR',


  getScheduledDataBegin: () => {
    return {
      type: actions.GET_SCHEDULED_DATA_BEGIN,
    };
  },

  getScheduledDataSuccess: (data) => {
    return {
      type: actions.GET_SCHEDULED_DATA_SUCCESS,
      data,
    };
  },

  getScheduledDataErr: (err) => {
    return {
      type: actions.GET_SCHEDULED_DATA_ERR,
      err,
    };
  },

  getInterviewByScheduleBegin: () => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_BEGIN,
    };
  },

  getInterviewByScheduleSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_SUCCESS,
      data,
    };
  },

  getInterviewByScheduleErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_BY_SCHEDULE_ERR,
      err,
    };
  },


  getInterviewAttemptsBegin: () => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_BEGIN,
    };
  },

  getInterviewAttemptsSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_SUCCESS,
      data,
    };
  },

  getInterviewAttemptsErr: (err) => {
    return {
      type: actions.GET_INTERVIEW_ATTEMPTS_ERR,
      err,
    };
  },


  overallSummaryBegin: () => {
    return {
      type: actions.OVERALL_SUMMARY_BEGIN,
    };
  },

  overallSummarySuccess: (data) => {
    return {
      type: actions.OVERALL_SUMMARY_SUCCESS,
      data,
    };
  },

  overallSummaryErr: (err) => {
    return {
      type: actions.OVERALL_SUMMARY_ERR,
      err,
    };
  },

  scheduleWiseSummaryBegin: () => {
    return {
      type: actions.SCHEDULE_WISE_SUMMARY_BEGIN,
    };
  },

  scheduleWiseSummarySuccess: (data) => {
    return {
      type: actions.SCHEDULE_WISE_SUMMARY_SUCCESS,
      data,
    };
  },

  scheduleWiseSummaryErr: (err) => {
    return {
      type: actions.SCHEDULE_WISE_SUMMARY_ERR,
      err,
    };
  },


};

export default actions;
