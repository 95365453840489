/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
// import { getItem } from '../../utility/localStorageControl';
import { useDispatch, useSelector } from 'react-redux';
import { Main, SalesOverviewStyleWrap } from '../styled';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Cards } from '../../components/cards/frame/cards-frame';
// import { ProjectList } from '../project/style';
import { Row, Col, Progress, Result } from 'antd';
import { attendanceDataRead } from '../../redux/attendance/actionCreator';
import { Button } from '../../components/buttons/buttons';
import UilRefresh from '@iconscout/react-unicons/icons/uil-refresh';
import {H4} from '../../components/heading/style';

function AttendanceSummary() {
  const dispatch = useDispatch();
  const { attendance, isLoading } = useSelector((state) => {
    return {
      attendance: state.AttendanceOverview.data,
      isLoading: state.AttendanceOverview.loading,
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(attendanceDataRead());
    }
  }, [dispatch]);

  const [attendanceData, setAttendanceData] = useState({});
  useEffect(() => {
    if (attendance) {
      const data = Object.keys(attendance).map((item, index) => {
        const value = attendance[item];
        return {
          key: index,
          class: item,
          morning: value.morning,
          afternoon: value.afternoon,
          // present: value.present,
          // absent: value.absent,
          // percentage: value.percentage,
          total: value.total,
          createdon: value.createdon !== "" ? new Date(value.createdon).toLocaleString() : 'Date not found'
        };
      });
      setAttendanceData(data);
    }
  }, [attendance]);

  // const [progressValue, setProgressValue] = useState(0);

  // useEffect(() => {
  //   // Animate the progress from 0 to the specified percentage
  //   const interval = setInterval(() => {
  //     {
  //       Object.keys(attendanceData).map((key) =>
  //         setProgressValue((prevValue) => {
  //           const newValue = prevValue + 1;
  //           return newValue <= attendanceData[key]?.percentage ? newValue : attendanceData[key]?.percentage;
  //         }),
  //       );
  //     }
  //   }, 20);

  //   return () => clearInterval(interval);
  // }, [attendanceData]);
  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Attendance Summary`} buttons={[
        <Button type={'primary'} onClick={() => { dispatch(attendanceDataRead()); }}>Refresh</Button>
      ]} />
      <Main>
        {isLoading ? (
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p style={{ fontWeight: 'bold', color: '#006666' }}>Loading please wait...</p>
          </div>
        ) : (
          <div>
            {
              Object.keys(attendanceData).length === 0 &&
              <div>
                <Result
                  status={'warning'}
                  title="No data found"
                />
              </div>
            }
            <Row gutter={1}>
              <Col xxl={24} xs={24}>
                <Row gutter={10} >
                  {Object.keys(attendanceData)?.map((key, i) => (
                    <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} key={i}>
                      <SalesOverviewStyleWrap>
                        <Cards title={`${attendanceData[key].class}`}>
                          <div className="ninjadash-sales-inner">
                            <H4>Morning Attendance</H4>
                            <Progress
                              type="circle"
                              percent={attendanceData[key].morning.morningPercentage == null ? 0 : attendanceData[key].morning.morningPercentage}
                              status={attendanceData[key].morningPercentage === 100 ? 'success' : ''}
                            />
                            <div className="ninjadash-sales-content d-flex">
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Present</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].morning.morningPresent == null ? 0 : attendanceData[key].morning.morningPresent}
                                </h4>
                              </div>
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Absent</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].morning.morningAbsent == null ? 0 : attendanceData[key].morning.morningAbsent}
                                </h4>
                              </div>
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Total</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].total}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <br />
                          < hr />
                          <br />
                          <div className="ninjadash-sales-inner">
                            <H4>Afternoon Attendance</H4>
                            <Progress
                              type="circle"
                              percent={attendanceData[key]?.afternoon?.afternoonPercentage == null ? 0 : attendanceData[key].afternoon.afternoonPercentage}
                              status={attendanceData[key].afternoonPercentage === 100 ? 'success' : ''}
                            />
                            <div className="ninjadash-sales-content d-flex">
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Present</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].afternoon.afternoonPresent == null ? 0 : attendanceData[key].afternoon.afternoonPresent}
                                </h4>
                              </div>
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Absent</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].afternoon.afternoonAbsent == null ? 0 : attendanceData[key].afternoon.afternoonAbsent}
                                </h4>
                              </div>
                              <div className="ninjadash-sales-content__item">
                                <span className="ninjadash-sales-content__item--label">Total</span>
                                <h4 className="ninjadash-sales-content__item--total">
                                  {attendanceData[key].total}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div className='mt-25 text-center' style={{ fontSize: '11px' }}>
                            <span>
                              As on {attendanceData[key].createdon}
                            </span>
                          </div>
                        </Cards>

                      </SalesOverviewStyleWrap>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </Main>
    </div>
  );
}

export default AttendanceSummary;
