/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
// import { getItem } from '../../utility/localStorageControl';
import { useDispatch, useSelector } from 'react-redux';
import { Main, TableWrapper } from '../styled';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Cards } from '../../components/cards/frame/cards-frame';
// import { ProjectList } from '../project/style';
import { Row, Col, Table, Select, DatePicker, Button, message, Form, Modal, Input } from 'antd';
import { ConsolidatedAttendanceDataRead } from '../../redux/attendance/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { batchesDataRead } from '../../redux/batches/actionCreator';
import actions from '../../redux/attendance/actions';
import { CSVLink } from 'react-csv';
import UilCommentAdd from '@iconscout/react-unicons/icons/uil-comment-add';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';

const { Option } = Select;
const { consolidatedAttendanceSuccess } = actions

function ConsolidatedAttendance() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { attendance, isLoading, batches, isBatchesLoading, consolidatedAttendance, consolidatedAttendanceLoading} = useSelector((state) => {
    return {
      batches: state.Batches.data,
      isBatchesLoading: state.Batches.loading,
      consolidatedAttendance: state.consolidatedAttendance.data,
      consolidatedAttendanceLoading: state.consolidatedAttendance.loading,            
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(batchesDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(consolidatedAttendanceSuccess([]))
    setAttendanceData([])
  }, [])

  const [batchesArray, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");  
  const [GoodData, setGoodData] = useState([]);

  useEffect(() => {
    if (batches) {
      const filteredData = batches.length !== 0 ? batches.map((item, index) => ({
        key: item.id,
        sno: index + 1,
        id: item.id,
        name: item.name
      })) : []
      setBatches(filteredData);
    }
  }, [batches]);

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Rollno',
      dataIndex: 'userName',
      key: 'userName',
      sorter: (a, b) => a.userName > b.userName ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Forenoon',
      dataIndex: 'morningPercentage',
      key: 'morningPercentage',
      align: 'center',
      sorter: (a, b) => a.morningPercentage > b.morningPercentage ? 1 : -1,
    },
    {
      title: 'Afternoon',
      dataIndex: 'afternoonPercentage',
      key: 'afternoonPercentage',
      align: 'center',
      sorter: (a, b) => a.afternoonPercentage > b.afternoonPercentage ? 1 : -1,
    },
  ];

  const fetchAttendance = () => {
    if (selectedBatch !== "") {      
      dispatch(ConsolidatedAttendanceDataRead(parseInt(selectedBatch)))
    }
    else {
      message.error("Select batch and date")
    }
  }

  const [attendanceData, setAttendanceData] = useState([]);
  useEffect(() => {
    const data = consolidatedAttendance.length !== 0 ? consolidatedAttendance.map((item, index) => {
      const { attId, id, studentName, userName, mobileNumber, morningPercentage, afternoonPercentage } = item
      return {
        key: index,
        sno: index + 1,
        attId,
        studentId : id,
        studentName,
        userName,
        mobileNumber,
        morningPercentage : morningPercentage ? morningPercentage : 'NA' ,
        afternoonPercentage : afternoonPercentage ? afternoonPercentage : 'NA' ,                                        
      };
    }) : []
    setAttendanceData(data);
    setGoodData(data);
  }, [consolidatedAttendance]);

  const [searchText, setsearchText] = useState(null);

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (GoodData.length !== 0) {
      let tempValues = []
      if (searchText != null) {
        tempValues = GoodData.filter(checkSearch)
      }
      setAttendanceData(tempValues);
    }
  }, [searchText]);

  const handleSearch = (e) => {
    setsearchText(e);
  };


  //export
  const headers = [
    { label: "S.No", key: "sno" },
    { label: "RollNo", key: "userName" },
    { label: "StudentName", key: "studentName" },
    { label: "Mobile", key: "mobileNumber" },
    { label: "Morning_Percentage", key: "morningPercentage" },
    { label: "Afternoon_Percentage", key: "afternoonPercentage" },    
  ];

  const csvReport = {
    filename: "ConsolidatedAttendance.csv",
    headers: headers,
    data: attendanceData
  }


  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Consolidated Attendance`} />
      <Main>
        {isLoading ? (
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p style={{ fontWeight: 'bold', color: '#006666' }}>Loading please wait...</p>
          </div>
        ) : (
          <div>

            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <ProjectSorting>
                  <div className="project-sort-bar" style={{ marginLeft: '10px' }}>
                    <div className="">
                      <div className="">
                        <span style={{ marginRight: '10px' }}>Batch :</span>
                        <Select id="topic-page-batch-filter" placeholder="Select" loading={isBatchesLoading} onChange={(e) => { setSelectedBatch(e) }} style={{ minWidth: '200px', fontSize: '14px' }}>
                          {Array.from(batches?.map((Obj) => (
                            <Option key={Obj.id} value={Obj.id}>
                              {Obj.name}
                            </Option>
                          )))}
                        </Select>
                      </div>
                    </div>                    
                    <div className="" style={{ marginLeft: '20px' }}>
                      <Button type='primary' disabled={selectedBatch === ""} onClick={() => { fetchAttendance() }}>Get</Button>
                    </div>
                  </div>
                </ProjectSorting>
                <Cards headless>
                  {
                    consolidatedAttendanceLoading ?
                      <div className='d-flex text-center' style={{ minHeight: '500px' }}>
                        <CustomSpinner />
                      </div>
                      :
                      <TableWrapper>
                        <div className="project-sort-search d-flex justify-content-end mb-10 align-center-v">
                          <CSVLink {...csvReport}>
                            <Button id="att_export-button" type="primary" size="middle" style={{ marginRight: '10px' }} disabled={attendanceData.length !== 0 ? false : true}>
                              Export
                            </Button>
                          </CSVLink>
                          <AutoComplete id="topic-page-search-filter" onSearch={handleSearch} dataSource={attendance} placeholder="Search here" patterns />
                        </div>
                        <ProjectList>
                          <div className="table-responsive">
                            <Table dataSource={attendanceData} columns={columns} />
                          </div>
                        </ProjectList>
                      </TableWrapper>
                  }
                </Cards>
              </Col>
            </Row>
          </div>
        )}

      </Main>
    </div>
  );
}

export default ConsolidatedAttendance;
