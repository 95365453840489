import React from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { NoteCardWrap } from '../note/style';
import { Col, Row } from 'antd';
import './customstyles.css'

function VideoPage() {
    return (
        <div>
            <Cards headless className='pb-20'>
                <Heading as={'h3'} className='text-center'>Topic Name</Heading>
                <NoteCardWrap>
                    <div className='mt-25 text-center'>
                        <iframe
                            className='videoframe'
                            src="https://www.youtube.com/embed/55l-aZ7_F24"
                            title="Video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen>
                        </iframe>
                    </div>
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default VideoPage;