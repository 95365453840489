const actions = {
  TEACHERS_ADD_BEGIN: 'TEACHERS_ADD_BEGIN',
  TEACHERS_ADD_SUCCESS: 'TEACHERS_ADD_SUCCESS',
  TEACHERS_ADD_ERR: 'TEACHERS_ADD_ERR',
  
  TEACHERS_BULK_UPLOAD_BEGIN: 'TEACHERS_BULK_UPLOAD_BEGIN',
  TEACHERS_BULK_UPLOAD_SUCCESS: 'TEACHERS_BULK_UPLOAD_SUCCESS',
  TEACHERS_BULK_UPLOAD_ERR: 'TEACHERS_BULK_UPLOAD_ERR',

  TEACHERS_READ_BEGIN: 'TEACHERS_READ_BEGIN',
  TEACHERS_READ_SUCCESS: 'TEACHERS_READ_SUCCESS',
  TEACHERS_READ_ERR: 'TEACHERS_READ_ERR',

  TEACHERS_UPDATE_BEGIN: 'TEACHERS_UPDATE_BEGIN',
  TEACHERS_UPDATE_SUCCESS: 'TEACHERS_UPDATE_SUCCESS',
  TEACHERS_UPDATE_ERR: 'TEACHERS_UPDATE_ERR',

  TEACHERS_BULK_UPDATE_BEGIN: 'TEACHERS_BULK_UPDATE_BEGIN',
  TEACHERS_BULK_UPDATE_SUCCESS: 'TEACHERS_BULK_UPDATE_SUCCESS',
  TEACHERS_BULK_UPDATE_ERR: 'TEACHERS_BULK_UPDATE_ERR',

  ADD_NEW_TEACHERS_UPDATE_EXISTING_BEGIN: 'ADD_NEW_TEACHERS_UPDATE_EXISTING_BEGIN',
  ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS: 'ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS',
  ADD_NEW_TEACHERS_UPDATE_EXISTING_ERR: 'ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS',

  teachersAddBegin: () => {
    return {
      type: actions.TEACHERS_ADD_BEGIN,
    };
  },

  teachersAddSuccess: (data) => {
    return {
      type: actions.TEACHERS_ADD_SUCCESS,
      data,
    };
  },

  teachersAddErr: (err) => {
    return {
      type: actions.TEACHERS_ADD_ERR,
      err,
    };
  },

  teachersBulkUploadBegin: () => {
    return {
      type: actions.TEACHERS_BULK_UPLOAD_BEGIN,
    };
  },

  teachersBulkUploadSuccess: (data) => {
    return {
      type: actions.TEACHERS_BULK_UPLOAD_SUCCESS,
      data,
    };
  },

  teachersBulkUploadErr: (err) => {
    return {
      type: actions.TEACHERS_BULK_UPLOAD_ERR,
      err,
    };
  },

  addnewteachersupdateexistingBegin: () =>{
    return {
      type: actions.ADD_NEW_TEACHERS_UPDATE_EXISTING_BEGIN,
    };
  },

  addnewteachersupdateexistingSuccess: (data) => {
    return {
      type: actions.ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS,
      data,
      
    };
  },

  addnewteachersupdateexistingErr: (err) => {
    return {
      type: actions.ADD_NEW_TEACHERS_UPDATE_EXISTING_ERR,
      err,
    };
  },

  teachersReadBegin: () => {
    return {
      type: actions.TEACHERS_READ_BEGIN,
    };
  },

  teachersReadSuccess: (data) => {
    return {
      type: actions.TEACHERS_READ_SUCCESS,
      data,
    };
  },

  teachersReadErr: (err) => {
    return {
      type: actions.TEACHERS_READ_ERR,
      err,
    };
  },

  teachersUpdateBegin: () => {
    return {
      type: actions.TEACHERS_UPDATE_BEGIN,
    };
  },

  teachersUpdateSuccess: (data) => {
    return {
      type: actions.TEACHERS_UPDATE_SUCCESS,
      data,
    };
  },

  teachersUpdateErr: (err) => {
    return {
      type: actions.TEACHERS_UPDATE_ERR,
      err,
    };
  },

  teachersBulkUpdateBegin: () => {
    return {
      type: actions.TEACHERS_BULK_UPDATE_BEGIN,
    };
  },

  teachersBulkUpdateSuccess: (data) => {
    return {
      type: actions.TEACHERS_BULK_UPDATE_SUCCESS,
      data,
    };
  },

  teachersBulkUpdateErr: (err) => {
    return {
      type: actions.TEACHERS_BULK_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
