const actions = {

  TOPICS_READ_BEGIN: 'TOPICS_READ_BEGIN',
  TOPICS_READ_SUCCESS: 'TOPICS_READ_SUCCESS',
  TOPICS_READ_ERR: 'TOPICS_READ_ERR',



  topicsReadBegin: () => {
    return {
      type: actions.TOPICS_READ_BEGIN,
    };
  },

  topicsReadSuccess: (data) => {    
    return {
      type: actions.TOPICS_READ_SUCCESS,      
      data,
    };
  },

  topicsReadErr: (err) => {
    return {
      type: actions.TOPICS_READ_ERR,
      err,
    };
  },
};

export default actions;
