import actions from './actions';

const {

  ATTENDANCE_READ_BEGIN,
  ATTENDANCE_READ_SUCCESS,
  ATTENDANCE_READ_ERR,

  BATCH_WISE_ATTENDANCE_READ_BEGIN,
  BATCH_WISE_ATTENDANCE_READ_SUCCESS,
  BATCH_WISE_ATTENDANCE_READ_ERR,

  POST_ATT_REMARKS_BEGIN,
  POST_ATT_REMARKS_SUCCESS,
  POST_ATT_REMARKS_ERR,

  CONSOLIDATED_ATTENDANCE_BEGIN,
  CONSOLIDATED_ATTENDANCE_SUCCESS,
  CONSOLIDATED_ATTENDANCE_ERR,

} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const initialbatchwiseState = {
  data: [],
  loading: false,
  error: null,
};

const postRemarksInitialState = {
  data: null,
  loading: false,
  error: null,
};

const consolidatedAttInitialState = {
  data:[],
  loading: false,
  error: null,
};

const ATTENDANCEreducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case ATTENDANCE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ATTENDANCE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ATTENDANCE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const BatchwiseAttendanceReducer = (state = initialbatchwiseState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case BATCH_WISE_ATTENDANCE_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BATCH_WISE_ATTENDANCE_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case BATCH_WISE_ATTENDANCE_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const PostAttRemarksReducer = (state = postRemarksInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case POST_ATT_REMARKS_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case POST_ATT_REMARKS_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case POST_ATT_REMARKS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const ConsolidatedAttendanceReducer = (state = consolidatedAttInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case CONSOLIDATED_ATTENDANCE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CONSOLIDATED_ATTENDANCE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case CONSOLIDATED_ATTENDANCE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};


export { ATTENDANCEreducer, BatchwiseAttendanceReducer, PostAttRemarksReducer, ConsolidatedAttendanceReducer };
