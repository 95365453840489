const actions = {
  
    ATTENDANCE_READ_BEGIN:'ATTENDANCE_READ_BEGIN',
    ATTENDANCE_READ_SUCCESS:'ATTENDANCE_READ_SUCCESS',
    ATTENDANCE_READ_ERR:'ATTENDANCE_READ_ERR',
  
    BATCH_WISE_ATTENDANCE_READ_BEGIN:'BATCH_WISE_ATTENDANCE_READ_BEGIN',
    BATCH_WISE_ATTENDANCE_READ_SUCCESS:'BATCH_WISE_ATTENDANCE_READ_SUCCESS',
    BATCH_WISE_ATTENDANCE_READ_ERR:'BATCH_WISE_ATTENDANCE_READ_ERR',
  
    POST_ATT_REMARKS_BEGIN:'POST_ATT_REMARKS_BEGIN',
    POST_ATT_REMARKS_SUCCESS:'POST_ATT_REMARKS_SUCCESS',
    POST_ATT_REMARKS_ERR:'POST_ATT_REMARKS_ERR',
  
    CONSOLIDATED_ATTENDANCE_BEGIN:'CONSOLIDATED_ATTENDANCE_BEGIN',
    CONSOLIDATED_ATTENDANCE_SUCCESS:'CONSOLIDATED_ATTENDANCE_SUCCESS',
    CONSOLIDATED_ATTENDANCE_ERR:'CONSOLIDATED_ATTENDANCE_ERR',
    
    attendanceReadBegin:()=>{
      return{
        type:actions.ATTENDANCE_READ_BEGIN
      }
    },
    attendanceReadSuccess:(data)=>{
      return{
        type:actions.ATTENDANCE_READ_SUCCESS,
        data,
      }
    },
    attendanceReadErr:(err)=>{
      return{
        type:actions.ATTENDANCE_READ_ERR,
        err
      }
    },
    
    batchwiseAttendanceReadBegin:()=>{
      return{
        type:actions.BATCH_WISE_ATTENDANCE_READ_BEGIN
      }
    },
    batchwiseAttendanceReadSuccess:(data)=>{
      return{
        type:actions.BATCH_WISE_ATTENDANCE_READ_SUCCESS,
        data,
      }
    },
    batchwiseAttendanceReadErr:(err)=>{
      return{
        type:actions.BATCH_WISE_ATTENDANCE_READ_ERR,
        err
      }
    },
    
    postAttRemarksBegin:()=>{
      return{
        type:actions.POST_ATT_REMARKS_BEGIN
      }
    },
    postAttRemarksSuccess:(data)=>{
      return{
        type:actions.POST_ATT_REMARKS_SUCCESS,
        data,
      }
    },
    postAttRemarksErr:(err)=>{
      return{
        type:actions.POST_ATT_REMARKS_ERR,
        err
      }
    },
    
    consolidatedAttendanceBegin:()=>{
      return{
        type:actions.CONSOLIDATED_ATTENDANCE_BEGIN
      }
    },
    consolidatedAttendanceSuccess:(data)=>{
      return{
        type:actions.CONSOLIDATED_ATTENDANCE_SUCCESS,
        data,
      }
    },
    consolidatedAttendanceErr:(err)=>{
      return{
        type:actions.CONSOLIDATED_ATTENDANCE_ERR,
        err
      }
    },
  
  };
  
  export default actions;
  