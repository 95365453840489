const actions = {
  GENERATE_QUESTIONS_ADD_BEGIN: 'GENERATE_QUESTIONS_ADD_BEGIN',
  GENERATE_QUESTIONS_ADD_SUCCESS: 'GENERATE_QUESTIONS_ADD_SUCCESS',
  GENERATE_QUESTIONS_ADD_ERR: 'GENERATE_QUESTIONS_ADD_ERR',

  GENERATE_QUESTIONS_READ_BEGIN: 'GENERATE_QUESTIONS_READ_BEGIN',
  GENERATE_QUESTIONS_READ_SUCCESS: 'GENERATE_QUESTIONS_READ_SUCCESS',
  GENERATE_QUESTIONS_READ_ERR: 'GENERATE_QUESTIONS_READ_ERR',

  QA_UPDATE_BEGIN: 'QA_UPDATE_BEGIN',
  QA_UPDATE_SUCCESS: 'QA_UPDATE_SUCCESS',
  QA_UPDATE_ERR: 'QA_UPDATE_ERR',

  READ_QUESTIONS_BEGIN: 'READ_QUESTIONS_BEGIN',
  READ_QUESTIONS_SUCCESS: 'READ_QUESTIONS_SUCCESS',
  READ_QUESTIONS_ERR: 'READ_QUESTIONS_ERR',

  DELETE_QA_BEGIN : 'DELETE_QA_BEGIN',
  DELETE_QA_SUCCESS : 'DELETE_QA_SUCCESS',
  DELETE_QA_ERR : 'DELETE_QA_ERR',

  generateQuestionsPostBegin: () => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_BEGIN,
    };
  },

  generateQuestionsPostSuccess: (data) => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_SUCCESS,
      data,
    };
  },

  generateQuestionsPostErr: (err) => {
    return {
      type: actions.GENERATE_QUESTIONS_ADD_ERR,
      err,
    };
  },

  generateQuestionsReadBegin: () => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_BEGIN,
    };
  },

  generateQuestionsReadSuccess: (data) => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_SUCCESS,
      data,
    };
  },

  generateQuestionsReadErr: (err) => {
    return {
      type: actions.GENERATE_QUESTIONS_READ_ERR,
      err,
    };
  },

  QAUpdateBegin: () => {
    return {
      type: actions.QA_UPDATE_BEGIN,
    };
  },

  QAUpdateSuccess: (data) => {
    return {
      type: actions.QA_UPDATE_SUCCESS,
      data,
    };
  },

  QAUpdateErr: (err) => {
    return {
      type: actions.QA_UPDATE_ERR,
      err,
    };
  },

  getQuestionsBegin: () => {
    return {
      type: actions.READ_QUESTIONS_BEGIN,
    };
  },

  getQuestionsSuccess: (data) => {
    return {
      type: actions.READ_QUESTIONS_SUCCESS,
      data,
    };
  },

  getQuestionsErr: (err) => {
    return {
      type: actions.READ_QUESTIONS_ERR,
      err,
    };
  },


  
  deleteQABegin: () => {
    return {
      type: actions.DELETE_QA_BEGIN,
    };
  },

  deleteQASuccess: (data) => {
    return {
      type: actions.DELETE_QA_SUCCESS,
      data,
    };
  },

  deleteQAErr: (err) => {
    return {
      type: actions.DELETE_QA_ERR,
      err,
    };
  },

};

export default actions;
