/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message, Space, Checkbox } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { DownloadOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'; // Import icons
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { fsDataRead } from '../../redux/fs/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { CreateInterviewer, UpdateInterviewer, GetInterviewers } from '../../redux/interviewers/actionCreator';
import Papa from 'papaparse';
import { ImportStyleWrap } from '../styled';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { collegeDataRead } from '../../redux/Colleges/actionCreator';

const { Option } = Select;


function Interviewers() {

    const clgName = getItem('collegeName');
    let ClgName = '(' + clgName + ')';
    const loggedadmin = getItem('username');

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

    const [form] = Form.useForm();
    const [updateForm] = Form.useForm();

    const [searchText, setsearchText] = useState(null);
    const [updateRecord, setUpdateRecord] = useState(null);

    const dispatch = useDispatch();
    const { isInterviewersDataLoading, InterviewersData, colleges, isCollegesLoading, PostInterviewerData, isPostInterviewerDataLoading, PutInterviewerData, isPutInterviewerDataLoading } = useSelector((state) => {
        return {
            colleges: state.College.data,
            isCollegesLoading: state.College.loading,
            InterviewersData: state.Interviewers.data,
            isInterviewersDataLoading: state.Interviewers.loading,
            PostInterviewerData: state.postInterviewer.data,
            isPostInterviewerDataLoading: state.postInterviewer.loading,
            PutInterviewerData: state.putInterviewer.data,
            isPutInterviewerDataLoading: state.putInterviewer.loading,
        };
    });

    useEffect(() => {
        dispatch(GetInterviewers());
        if (colleges.length === 0) {
            dispatch(collegeDataRead());
        }
    }, [dispatch]);

    useEffect(() => {
        if (PostInterviewerData !== null && PostInterviewerData?.Error === false) {
            dispatch(GetInterviewers());
        }
    }, [PostInterviewerData])

    useEffect(() => {
        if (PutInterviewerData !== null && PutInterviewerData?.Error === false) {
            dispatch(GetInterviewers());
        }
    }, [PutInterviewerData])

    const [dataSource, setDataSource] = useState([]);
    const [GoodData, setGoodData] = useState([]);
    const [loading, setLoading] = useState(false);

    function formatDateTime(dateTime) {
        const dateObject = dateTime;
        const formattedDate = dateObject?.split('T')[0].replace(/-/g, '/');
        return formattedDate;
    }
    useEffect(() => {
        if (InterviewersData?.length > 0) {
            const filteredData = InterviewersData.map((item, index) => ({
                key: item.interviewerId,
                sno: index + 1,
                id: item.interviewerId,
                name: item.name,
                username: item.username,
                collegeId: item.collegeId,
                collegeCode: item.collegeCode,
                updatedby: item.updatedBy,
            }));
            setDataSource(filteredData);
            setGoodData(filteredData);
            setLoading(false);
        } else {
            setDataSource([]);
            setGoodData([]);
            setLoading(false);
        }
    })

    const checkSearch = (item) => {
        return Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchText.toLowerCase())
        );
    };

    useEffect(() => {
        if (searchText) {
            const filteredData = GoodData.filter(checkSearch);
            setDataSource(filteredData);
        } else {
            setDataSource(GoodData);
        }
    }, [searchText, GoodData]);

    const handleSearch = (e) => {
        setsearchText(e);
    };

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'College',
            dataIndex: 'collegeCode',
            key: 'collegeCode',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedby',
            key: 'updatedby',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <div className='table-actions'>
                    <Button
                        key="updateInterviewer"
                        onClick={() => handleUpdate(record)}
                        className="btn-icon "
                        type="primary"
                        to="#"
                        shape="circle"
                    >
                        <UilEdit />
                    </Button>
                </div>
            ),
        }
    ];


    const handleButtonClick = () => {
        setIsModalVisible(true);
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleModalAdd = () => {
        form.validateFields().then((values) => {
            const newRow = {
                name: values.name,
                username: values.username,
                collegeId: values.college,
                password: values.password,
            };
            form.resetFields();
            setIsModalVisible(false);
            dispatch(CreateInterviewer(newRow));
        })
            .catch((error) => {
                console.error('Error while validating form:', error);
            });
    };

    const handleModalUpdate = () => {
        updateForm.validateFields().then((values) => {
            const updatedRecord = {
                id: updateRecord.id,
                name: values.name,
            };
            setIsUpdateModalVisible(false);
            dispatch(UpdateInterviewer(updatedRecord));
        }).catch((error) => {
            console.error('Error while validating form:', error);
        });
    };

    const handleUpdate = (record) => {
        setUpdateRecord(record);
        setIsUpdateModalVisible(true);
        updateForm.setFieldsValue({
            name: record.name,
        });
    };

    return (
        <>
            <PageHeader className="ninjadash-page-header-main" title={`Interviewers ${loggedadmin === 'superadmin' ? ClgName : ''}`}
                buttons={
                    loggedadmin !== 'superadmin' ?
                        [
                            <Button id="assistants-page-add_assistant-button" onClick={handleButtonClick} key="AddAssistant" type="primary" size="default">
                                Add Interviewer
                            </Button>
                        ] : []}
            />
            <Main>
                {loading || isInterviewersDataLoading || isPutInterviewerDataLoading || isPostInterviewerDataLoading ?
                    (
                        <div className="custom-loading-spin-with-text">
                            <CustomSpinner />
                            <p style={{ fontWeight: 'bold', color: '#006666' }}>
                                Loading please wait...
                            </p>
                        </div>
                    ) : (
                        <Row gutter={25}>
                            <Col sm={24} xs={24}>
                                <ProjectSorting>
                                    <div className="project-sort-bar d-flex justify-content-end">
                                        <div className="project-sort-search">
                                            <AutoComplete id="interviewers-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                                        </div>
                                    </div>
                                </ProjectSorting>
                                <Cards headless>
                                    <TableWrapper>
                                        <ProjectList>
                                            <div className='table-responsive'>
                                                <Table dataSource={dataSource} columns={columns} />
                                            </div>
                                        </ProjectList>
                                    </TableWrapper>
                                </Cards>
                            </Col>
                        </Row>
                    )
                }
            </Main>
            <Modal
                title="Add Interviewer"
                open={isModalVisible}
                onCancel={handleModalCancel}
                maskClosable={false}
                footer={[
                    <Button id="add-interviewer-button" key="AddInterviewerRecord" type="primary" onClick={handleModalAdd}>
                        Add
                    </Button>,
                ]}
            >
                <Form id="add-interviewer-form" form={form} layout="vertical">
                    <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
                        <Select id="add-interviewer-select-college" placeholder="select"
                            loading={isCollegesLoading}
                        >
                            {colleges?.map((college) => (
                                <Option key={college.id} value={college.id}>
                                    {college.code}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter interviewer name" }]}>
                        <Input id="add-interviewer-enter-interviewer-name" placeholder="Enter name" />
                    </Form.Item>
                    <Form.Item name="username" label="User Name" rules={[{ required: true, message: "Please enter interviewer username" }]}>
                        <Input id="add-interviewer-enter-interviewer-username" placeholder="Enter username" />
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please enter password" }]}>
                        <Input.Password id="add-interviewer-enter-password" placeholder="Enter password" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={`Update info of ${updateRecord?.username}`}
                open={isUpdateModalVisible}
                onCancel={() => setIsUpdateModalVisible(false)}
                maskClosable={false}
                footer={[
                    <Button id="update-interviewer-button" key="UpdateinterviewerRecord" type="primary" onClick={handleModalUpdate}>
                        Update
                    </Button>
                ]}
            >
                <Form id="update-interviewer-form" form={updateForm} layout="vertical">
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter interviewer name" }]}>
                        <Input id="update-interviewer-enter-assistant-name" placeholder='Enter name' />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default Interviewers;