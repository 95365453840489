import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem, setItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new FS has been Submited',
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Adding new FS has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'FS Record has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating FS has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  fsAddBegin,
  fsAddSuccess,
  fsAddErr,

  fsReadBegin,
  fsReadSuccess,
  fsReadErr,

  fsUpdateBegin,
  fsUpdateSuccess,
  fsUpdateErr,
} = actions;

const CreateFS = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(fsAddBegin());
      const response = await DataService.post('/fs-master/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(fsAddSuccess(response.data));
        addNotificationSuccess();
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut());
        }
        throw response.data.message;
        // await dispatch(fsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(fsAddErr(err));
      addNotificationError(err);
    }
  };
};

const fsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(fsReadBegin());
      const response = await DataService.get(`/fs-master`);
      const token = getItem('access_token');

      if (token && response.status === 200 && response.data !== null && response.data.Error === false) {
        await dispatch(fsReadSuccess(response.data.payload));
        if (response.data.payload.length !== 0) {
          const activeFSObj = response.data.payload.sort((a, b) => a.fsyear < b.fsyear ? 1 : -1).filter((obj) => obj.status === "ACTIVE")
          if(activeFSObj.length !== 0) {
            setItem("activeFSid",activeFSObj[0].id)
            setItem("activeFSName",activeFSObj[0].name)
          }
        }
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(fsReadErr(err));
    }
  };
};

const fsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(fsUpdateBegin());
      const response = await DataService.put(`/fs-master/update`, data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(fsUpdateSuccess(response.data));
        updateNotificationSuccess();
      } else {
        if (response.data.Error === true && response.data.statusCode === 401) {
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        await dispatch(fsUpdateSuccess(null));
        throw response.data.message;
      }
    } catch (err) {
      await dispatch(fsUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

export {
  fsDataRead,
  CreateFS,
  fsDataUpdate,
};

