import {
  UilUsersAlt,
  UilBookOpen,
  UilBag,
  UilAward,
  UilBookAlt,
  UilUserMd,
  UilApps,
  UilTable,
  UilWrench,
  UilCheck,
  UilUniversity,
  UilMeetingBoard
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/interviewer';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'interviews'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/`}>
        {t('Dashboard')}
      </NavLink>,
      '', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/`}>
          <UilApps />
        </NavLink>
      )
    ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
    //     {t('Interviews')}
    //   </NavLink>,
    //   'interviews',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
    //       <UilMeetingBoard />
    //     </NavLink>
    //   ),
    //   null,
    // ),

    getItem(t('Prashn Manch'), 'Prashn Manch', !topMenu && <UilMeetingBoard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews/academic`}>
          {t('Academic')}
        </NavLink>,
        'academic',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews/finishingschool`}>
          {t('Finishing School')}
        </NavLink>,
        'finishingschool',
        null,
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? '' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : ''}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
