export const READ_ContentFeedback_BEGIN = 'READ_ContentFeedback_BEGIN';
export const READ_ContentFeedback_SUCCESS = 'READ_ContentFeedback_SUCCESS';
export const READ_ContentFeedback_ERROR = 'READ_ContentFeedback_ERROR';

export const readContentBegin = () => ({
    type: READ_ContentFeedback_BEGIN,
});

export const readContentSuccess = (data) => ({
    type: READ_ContentFeedback_SUCCESS,
    payload: data,
});

export const readContentError = (error) => ({
    type: READ_ContentFeedback_ERROR,
    payload: error,
});
