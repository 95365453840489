import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new batch has been Added',
  });
};

const addNotificationError = (err) => {
  // if(err.includes('Duplicate')){
  //   notification.error({
  //     message: 'Duplicate Entry',
  //   });
  // }
  // else{
  //   notification.error({
  //     message: 'Adding new batch has been failed',
  //   });
  // }
  const errorMessage = `Adding new batch has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Batch Record has been updated',
  });
};

const updateNotificationError = (err) => {
  // if(err.includes('Duplicate')){
  //   notification.error({
  //     message: 'Duplicate Entry',
  //   });
  // }
  // else{
  //   notification.error({
  //     message: 'Updating batch Record has been failed',
  //   });
  // }
  const errorMessage = `Updating batch has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  batchesAddBegin,
  batchesAddSuccess,
  batchesAddErr,

  batchesReadBegin,
  batchesReadSuccess,
  batchesReadErr,

  batchesUpdateBegin,
  batchesUpdateSuccess,
  batchesUpdateErr,
} = actions;

const batchesDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(batchesAddBegin());
      const response = await DataService.post('/batch/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(batchesAddSuccess(response.data));
        addNotificationSuccess();
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut());
        }
        throw response.data.message;
        // await dispatch(batchesAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(batchesAddErr(err));
      addNotificationError(err);
    }
  };
};

const batchesDataRead = () => {
  return async (dispatch) => {
    try {
      const activeFSid = getItem('activeFSid');
      await dispatch(batchesReadBegin());
      const query = await DataService.get(`/batch/${activeFSid}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(batchesReadSuccess(query.data.payload));
      } else {
        if ( query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        throw query.data.message;
        // await dispatch(batchesAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(batchesReadErr(err));
    }
  };
};

const batchesDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(batchesUpdateBegin());
      const response = await DataService.put(`/batch/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(batchesUpdateSuccess(response.data));
        updateNotificationSuccess();
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(batchesDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(batchesUpdateErr(err));
      updateNotificationError(err);
    }
  };
};
export {
  batchesDataRead,
  batchesDataSubmit,
  batchesDataUpdate,
};
