const actions = {

  TEACHERSSUBJECTS_READ_BEGIN: 'TEACHERSSUBJECTS_READ_BEGIN',
  TEACHERSSUBJECTS_READ_SUCCESS: 'TEACHERSSUBJECTS_READ_SUCCESS',
  TEACHERSSUBJECTS_READ_ERR: 'TEACHERSSUBJECTS_READ_ERR',

  teacherssubjectsReadBegin: () => {
    return {
      type: actions.TEACHERSSUBJECTS_READ_BEGIN,
    };
  },

  teacherssubjectsReadSuccess: (data) => {
    return {
      type: actions.TEACHERSSUBJECTS_READ_SUCCESS,
      data,
    };
  },

  teacherssubjectsReadErr: (err) => {
    return {
      type: actions.TEACHERSSUBJECTS_READ_ERR,
      err,
    };
  },

};

export default actions;
