import { READ_ContentFeedback_BEGIN, READ_ContentFeedback_SUCCESS, READ_ContentFeedback_ERROR } from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const ContentFeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case READ_ContentFeedback_BEGIN:
            return {
                ...state,
                loading: true,
            };
        case READ_ContentFeedback_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
            };
        case READ_ContentFeedback_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};


export { ContentFeedbackReducer };
