import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { getItem } from '../../utility/localStorageControl';
import { notification } from 'antd';
import { logOut } from '../authentication/actionCreator';

const {
  teacherssubjectsReadBegin,
  teacherssubjectsReadSuccess,
  teacherssubjectsReadErr,
} = actions;

const teacherssubjectsDataRead = (selectedRegulationId) => {
  return async (dispatch) => {
    try {
      await dispatch(teacherssubjectsReadBegin());
      const query = await DataService.get(`/subjectmaster/regulation/${selectedRegulationId}`);
      const token = getItem('access_token');
      if (token && query.data.Error === false) {
        await dispatch(teacherssubjectsReadSuccess(query.data.payload));
      } else {
        if ( query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        throw query.data.message;
        // await dispatch(teacherssubjectsReadSuccess([]));
        // await dispatch(topicsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(teacherssubjectsReadErr(err));
    }
  };
};

export {
  teacherssubjectsDataRead,
};
