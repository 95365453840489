/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
// import { getItem } from '../../utility/localStorageControl';
import { useDispatch, useSelector } from 'react-redux';
import { Main, TableWrapper } from '../styled';
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { Cards } from '../../components/cards/frame/cards-frame';
// import { ProjectList } from '../project/style';
import { Row, Col, Table, Select, DatePicker, Button, message, Form, Modal, Input } from 'antd';
import { batchwiseAttendanceDataRead, postAttRemarks } from '../../redux/attendance/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { batchesDataRead } from '../../redux/batches/actionCreator';
import actions from '../../redux/attendance/actions';
import { CSVLink } from 'react-csv';
import UilCommentAdd from '@iconscout/react-unicons/icons/uil-comment-add';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';

const { Option } = Select;
const { batchwiseAttendanceReadSuccess, postAttRemarksSuccess } = actions

function BatchwiseAttendance() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { attendance, isLoading, batches, isBatchesLoading, batchwiseAttendance, batchwiseAttendanceLoading, postRemraksRes, isPostingRemarks } = useSelector((state) => {
    return {
      batches: state.Batches.data,
      isBatchesLoading: state.Batches.loading,
      batchwiseAttendance: state.batchwiseAttendance.data,
      batchwiseAttendanceLoading: state.batchwiseAttendance.loading,
      postRemraksRes: state.postRemraksRes.data,
      isPostingRemarks: state.postRemraksRes.loading,
    };
  });

  useEffect(() => {
    if (dispatch) {
      dispatch(batchesDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(batchwiseAttendanceReadSuccess([]))
    setAttendanceData([])
  }, [])

  const [batchesArray, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [GoodData, setGoodData] = useState([]);

  useEffect(() => {
    if (batches) {
      const filteredData = batches.length !== 0 ? batches.map((item, index) => ({
        key: item.id,
        sno: index + 1,
        id: item.id,
        name: item.name
      })) : []
      setBatches(filteredData);
    }
  }, [batches]);

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Rollno',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username > b.username ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'studentName',
      key: 'studentName',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Forenoon',
      dataIndex: 'morning',
      key: 'morning',
      align: 'center',
    },
    {
      title: 'Afternoon',
      dataIndex: 'afternoon',
      key: 'afternoon',
      align: 'center',
    },
    {
      title: 'Att marked on',
      dataIndex: 'createdon',
      key: 'createdon',
    },
    {
      title: 'Remarks',
      dataIndex: 'addRemarks',
      key: 'addRemarks',
      align: 'center'
    },
    // {
    //   title: 'Updated By',
    //   dataIndex: 'updatedby',
    //   key: 'updatedby',
    // },
  ];

  const fetchAttendance = () => {
    if (selectedBatch !== "" || selectedDate !== "") {
      const DD = `${new Date(selectedDate).getDate()}`.padStart(2, "0")
      const MM = `${new Date(selectedDate).getMonth() + 1}`.padStart(2, "0");
      const YY = new Date(selectedDate).getFullYear()
      const date = MM + "-" + DD + "-" + YY
      dispatch(batchwiseAttendanceDataRead(parseInt(selectedBatch), date.toString()))
    }
    else {
      message.error("Select batch and date")
    }
  }

  const [remarksModal, setRemarksModalVisible] = useState(false)
  const [viewRemarksModal, setViewRemarksModalVisible] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState(null)

  const openRemarksModal = (studentObj) => {
    setSelectedStudent(studentObj)
    setRemarksModalVisible(true)
    form.setFieldsValue({ remarks: studentObj.remarks })
  }

  const handleModalCancel = () => {
    setRemarksModalVisible(false);
    form.resetFields();
  };

  const openViewRemarksModal = (studentObj) => {
    if (studentObj && studentObj.remarks !== null) {
      setViewRemarksModalVisible(true);
      setSelectedStudent(studentObj)
    }
    else {
      message.error('Remarks not found')
    }
  }

  const [attendanceData, setAttendanceData] = useState([]);
  useEffect(() => {
    const data = batchwiseAttendance.length !== 0 ? batchwiseAttendance.map((item, index) => {
      const { attId, studentId, studentName, username, mobileNumber, morning, afternoon, createdon, updatedby, remarks } = item
      return {
        key: index,
        sno: index + 1,
        attId,
        studentId,
        studentName,
        username,
        mobileNumber,
        morning: morning === 0 ? <span className='status error'>ABSENT</span> : morning === 1 ? <span className='status Success'>PRESENT</span> : <span className=''>--</span>,
        morningAtt: morning,
        afternoon: afternoon === 0 ? <span className='status error'>ABSENT</span> : afternoon === 1 ? <span className='status Success'>PRESENT</span> : <span className=''>--</span>,
        afternoonAtt: afternoon,
        createdon: createdon ? new Date(createdon).toDateString() : '--',
        // updatedby: updatedby ? updatedby : '--'
        addRemarks: <div className='table-actions'>
          <UilCommentAdd size={22} className="cursor-pointer" style={{ marginRight: '10px' }} onClick={() => { openRemarksModal(item) }} />
          {remarks && <UilEye size={22} className="cursor-pointer" onClick={() => { openViewRemarksModal(item) }} />}
        </div>,
        remarks: remarks === null ? undefined : remarks
      };
    }) : []
    setAttendanceData(data);
    setGoodData(data);
  }, [batchwiseAttendance]);

  const [searchText, setsearchText] = useState(null);

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase().includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (GoodData.length !== 0) {
      let tempValues = []
      if (searchText != null) {
        tempValues = GoodData.filter(checkSearch)
      }
      setAttendanceData(tempValues);
    }
  }, [searchText]);

  const handleSearch = (e) => {
    setsearchText(e);
  };


  //export
  const headers = [
    { label: "S.No", key: "sno" },
    { label: "RollNo", key: "username" },
    { label: "StudentName", key: "studentName" },
    { label: "Mobile", key: "mobileNumber" },
    { label: "Morning", key: "morningAtt" },
    { label: "Afternoon", key: "afternoonAtt" },
    { label: "Remarks", key: "remarks" }
  ];

  const csvReport = {
    filename: "BatchwiseAtt.csv",
    headers: headers,
    data: attendanceData
  }

  const UploadRemarks = () => {
    form.validateFields().then((values) => {
      let row = {
        id: selectedStudent?.attId,
        remarks: values.remarks
      }
      dispatch(postAttRemarks(row))
    })
      .catch((error) => {
        // console.error('Error while validating form:', error);
      });
  }

  useEffect(() => {
    if (postRemraksRes && postRemraksRes.Error === false) {
      message.success(postRemraksRes.message)
      setSelectedStudent(null)
      setRemarksModalVisible(false)
      dispatch(postAttRemarksSuccess(null))
      fetchAttendance()
      form.resetFields()
    } else if (postRemraksRes && postRemraksRes.Error === true) {
      message.error(postRemraksRes.message)
    }
  }, [postRemraksRes])

  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Batch Wise Attendance`} />
      <Main>
        {isLoading ? (
          <div className="custom-loading-spin-with-text">
            <CustomSpinner />
            <p style={{ fontWeight: 'bold', color: '#006666' }}>Loading please wait...</p>
          </div>
        ) : (
          <div>

            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <ProjectSorting>
                  <div className="project-sort-bar" style={{ marginLeft: '10px' }}>
                    <div className="">
                      <div className="">
                        <span style={{ marginRight: '10px' }}>Batch :</span>
                        <Select id="topic-page-batch-filter" placeholder="Select" loading={isBatchesLoading} onChange={(e) => { setSelectedBatch(e) }} style={{ minWidth: '200px', fontSize: '14px' }}>
                          {Array.from(batches?.map((Obj) => (
                            <Option key={Obj.id} value={Obj.id}>
                              {Obj.name}
                            </Option>
                          )))}
                        </Select>
                      </div>
                    </div>
                    <div className="" style={{ marginLeft: '20px' }}>
                      <span style={{ marginRight: '10px' }}>Date :</span>
                      <DatePicker size='middle' onChange={(e) => e && setSelectedDate(e.toString())} />
                    </div>
                    <div className="" style={{ marginLeft: '20px' }}>
                      <Button type='primary' disabled={selectedBatch === "" || selectedDate === ""} onClick={() => { fetchAttendance() }}>Get</Button>
                    </div>
                  </div>
                </ProjectSorting>
                <Cards headless>
                  {
                    batchwiseAttendanceLoading ?
                      <div className='d-flex text-center' style={{ minHeight: '500px' }}>
                        <CustomSpinner />
                      </div>
                      :
                      <TableWrapper>
                        <div className="project-sort-search d-flex justify-content-end mb-10 align-center-v">
                          <CSVLink {...csvReport}>
                            <Button id="att_export-button" type="primary" size="middle" style={{ marginRight: '10px' }} disabled={attendanceData.length !== 0 ? false : true}>
                              Export
                            </Button>
                          </CSVLink>
                          <AutoComplete id="topic-page-search-filter" onSearch={handleSearch} dataSource={attendance} placeholder="Search here" patterns />
                        </div>
                        <ProjectList>
                          <div className="table-responsive">
                            <Table dataSource={attendanceData} columns={columns} />
                          </div>
                        </ProjectList>
                      </TableWrapper>
                  }
                </Cards>
              </Col>
            </Row>
          </div>
        )}

        {/* add modal */}
        <Modal
          title={`Add/Update Remarks for ${selectedStudent && selectedStudent.username}`}
          open={remarksModal}
          onCancel={handleModalCancel}
          maskClosable={false}
          footer={[
            <Button id="add-remarks-button" key="AddRemarks" type="primary" onClick={UploadRemarks}>
              Add/Update
            </Button>
          ]}
        >
          <Form id="add-remarks-form" form={form} layout="vertical">
            <Form.Item name="remarks" label="Remarks" rules={[{ required: true, message: "Please input remarks" }]}>
              <Input.TextArea rows={5} id="add-reamrks-enter-remarks" placeholder="Type here..." />
            </Form.Item>
          </Form>
        </Modal>

        {/* view modal */}
        <Modal
          title={`Remarks of ${selectedStudent && selectedStudent.username}`}
          open={viewRemarksModal}
          onCancel={() => { setViewRemarksModalVisible(false); setSelectedStudent(null) }}
          maskClosable={false}
          footer={false}
        >
          <div>
            <p>{selectedStudent && selectedStudent.remarks}</p>
          </div>
        </Modal>
      </Main>
    </div>
  );
}

export default BatchwiseAttendance;
