import { message } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';


const {

  createInterviewerDataBegin,
  createInterviewerDataSuccess,
  createInterviewerDataErr,

  getInterviewerDataBegin,
  getInterviewerDataSuccess,
  getInterviewerDataErr,

  updateInterviewerDataBegin,
  updateInterviewerDataSuccess,
  updateInterviewerDataErr,

} = actions;


const CreateInterviewer = (dtoData) => {
  return async (dispatch) => {
    try {
      await dispatch(createInterviewerDataBegin());
      const query = await DataService.post(`/interviewer-master/create`, dtoData);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(createInterviewerDataSuccess(data));
        message.success(query.data.message);
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(createInterviewerDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(createInterviewerDataSuccess(null));
        }
      }
      else {
        await dispatch(createInterviewerDataSuccess(null));
      }
    } catch (err) {
      await dispatch(createInterviewerDataErr(err));
      message.error(err)
    }
  };
};

const GetInterviewers = () => {
  return async (dispatch) => {
    try {
      await dispatch(getInterviewerDataBegin());
      const query = await DataService.get(`/interviewer-master`);
      const token = getItem('access_token');
      const data = query.data
      if (token && data !== null && data.Error === false) {
        await dispatch(getInterviewerDataSuccess(data.payload));
      }
      else if (data !== null && data.Error === true) {
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          message.error(data.message)
          await dispatch(getInterviewerDataSuccess(null));
          dispatch(logOut())
        }
        else {
          await dispatch(getInterviewerDataSuccess(null));
        }
      }
      else {
        await dispatch(getInterviewerDataSuccess(null));
      }
    } catch (err) {
      await dispatch(getInterviewerDataErr(err));
      message.error(err)
    }
  };
};


const UpdateInterviewer = (dtoData) => {
  return async (dispatch) => {
    try {
      await dispatch(updateInterviewerDataBegin());
      const response = await DataService.put(`/interviewer-master/update`, dtoData);
      const token = getItem('access_token');
      const data = response.data
      console.log(data);
      if (token && data !== null && data.Error === false) {
        await dispatch(updateInterviewerDataSuccess(data));
        message.success(response.data.message)
      }
      else if (data !== null && data.Error === true) {
        message.error(data.message)
        if (Object.keys(data).length >= 3 && data.statusCode == 401) {
          await dispatch(updateInterviewerDataSuccess([]));
          dispatch(logOut())
        }
        else {
          await dispatch(updateInterviewerDataSuccess([]));
        }
      }
      else {
        await dispatch(updateInterviewerDataSuccess([]));
      }
    } catch (err) {
      await dispatch(updateInterviewerDataErr(err));
      message.error(err);
    }
  };
};


export {
  CreateInterviewer,
  UpdateInterviewer,
  GetInterviewers,
};
