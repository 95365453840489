const actions = {

  GET_ACADEMIC_SCHEDULED_DATA_BEGIN: 'GET_ACADEMIC_SCHEDULED_DATA_BEGIN',
  GET_ACADEMIC_SCHEDULED_DATA_SUCCESS: 'GET_ACADEMIC_SCHEDULED_DATA_SUCCESS',
  GET_ACADEMIC_SCHEDULED_DATA_ERR: 'GET_ACADEMIC_SCHEDULED_DATA_ERR',

  GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_BEGIN: 'GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_BEGIN',
  GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_SUCCESS: 'GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_SUCCESS',
  GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_ERR: 'GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_ERR',

  GET_ACADEMIC_INTERVIEW_ATTEMPTS_BEGIN: 'GET_ACADEMIC_INTERVIEW_ATTEMPTS_BEGIN',
  GET_ACADEMIC_INTERVIEW_ATTEMPTS_SUCCESS: 'GET_ACADEMIC_INTERVIEW_ATTEMPTS_SUCCESS',
  GET_ACADEMIC_INTERVIEW_ATTEMPTS_ERR: 'GET_ACADEMIC_INTERVIEW_ATTEMPTS_ERR',


  getAcademicScheduledDataBegin: () => {
    return {
      type: actions.GET_ACADEMIC_SCHEDULED_DATA_BEGIN,
    };
  },

  getAcademicScheduledDataSuccess: (data) => {
    return {
      type: actions.GET_ACADEMIC_SCHEDULED_DATA_SUCCESS,
      data,
    };
  },

  getAcademicScheduledDataErr: (err) => {
    return {
      type: actions.GET_ACADEMIC_SCHEDULED_DATA_ERR,
      err,
    };
  },

  getAcademicInterviewByScheduleBegin: () => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_BEGIN,
    };
  },

  getAcademicInterviewByScheduleSuccess: (data) => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_SUCCESS,
      data,
    };
  },

  getAcademicInterviewByScheduleErr: (err) => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_BY_SCHEDULE_ERR,
      err,
    };
  },


  getAcademicInterviewAttemptsBegin: () => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_ATTEMPTS_BEGIN,
    };
  },

  getAcademicInterviewAttemptsSuccess: (data) => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_ATTEMPTS_SUCCESS,
      data,
    };
  },

  getAcademicInterviewAttemptsErr: (err) => {
    return {
      type: actions.GET_ACADEMIC_INTERVIEW_ATTEMPTS_ERR,
      err,
    };
  },

};

export default actions;
