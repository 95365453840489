import actions from './actions';

const {

  TOPICSSUBJECTS_READ_BEGIN,
  TOPICSSUBJECTS_READ_SUCCESS,
  TOPICSSUBJECTS_READ_ERR,

} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};



const TOPICSSUBJECTSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case TOPICSSUBJECTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPICSSUBJECTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPICSSUBJECTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TOPICSSUBJECTSReducer};
