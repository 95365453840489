import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import jwtDecode from 'jwt-decode';
import { message } from 'antd';
import { removeItem, setItem } from '../../utility/localStorageControl';
import { fsDataRead } from '../fs/actionCreator';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr,
  getmsgBegin,
  getmsgSuccess,
  getmsgErr } = actions;

const login = (values, callback) => {

  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.login('/auth/adminlogin', values);
      if (response.data !== null && response.data.Error === true) {
        message.error(response.data.message, 5);
        dispatch(loginErr(response.data.message));
      }
      else {
        const userInfo = await jwtDecode(response.data.access_token)
        if (userInfo.error === false && Object.keys(response.data).length !== 0 && 'access_token' in response.data && (userInfo.role === "ADMIN" || userInfo.role === "INTERVIEWER")) {
          setItem('access_token', response.data.access_token);
          setItem('logedIn', true);
          setItem('role', userInfo.role);
          setItem('username', userInfo.username);
          setItem('userId', userInfo.sub);
          setItem('collegeId', userInfo.collegeId);
          setItem('collegeName', userInfo.collegeName);
          setItem('name', userInfo.name);
          dispatch(loginSuccess(true));
          dispatch(fsDataRead());
          // callback();      
        }
        else {
          message.error("Invalid credentials")
          dispatch(loginErr("Invalid credentials"));
        }
      }


      // else {     
      //   dispatch(loginErr(response.data));   
      // }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const getMessage = () => {
  return async (dispatch) => {
    try {
      await dispatch(getmsgBegin());
      const response = await DataService.getMsg(`/message`);
      if (response.data.Error === false && response.data !== null) {
        await dispatch(getmsgSuccess(response.data.htmlcode));
      }
      else {
        await dispatch(getmsgSuccess(null));
      }
    } catch (err) {
      await dispatch(getmsgErr(err));
    }
  };
}

// const fbLogin = (callback) => {
//   return async (dispatch) => {
//     dispatch(loginBegin());
//     try {
//       setItem('logedIn', true);
//       dispatch(loginSuccess(true));
//       callback();
//     } catch (err) {
//       dispatch(loginErr(err));
//     }
//   };
// };

// const register = (values) => {
//   return async (dispatch) => {
//     dispatch(loginBegin());
//     try {
//       const response = await DataService.post('/register', values);
//       if (response.data.errors) {
//         dispatch(loginErr('Registration failed!'));
//       } else {
//         dispatch(loginSuccess(false));
//       }
//     } catch (err) {
//       dispatch(loginErr(err));
//     }
//   };
// };

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      removeItem('logedIn');
      removeItem('loginStatus');
      removeItem('access_token');
      removeItem('role')
      removeItem('name')
      removeItem('username')
      removeItem('userId');
      removeItem('activeunit');
      removeItem('activetopic');
      removeItem('activeFSid');
      removeItem('activeFSName');
      removeItem('activestudenttopic')
      removeItem('collegeId');
      removeItem('collegeName');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export {
  login, logOut, getMessage
  //  register, fbLogin 
};