const actions = {
  ACADEMIC_STUDENTS_ADD_BEGIN: 'ACADEMIC_STUDENTS_ADD_BEGIN',
  ACADEMIC_STUDENTS_ADD_SUCCESS: 'ACADEMIC_STUDENTS_ADD_SUCCESS',
  ACADEMIC_STUDENTS_ADD_ERR: 'ACADEMIC_STUDENTS_ADD_ERR',
  
  ACADEMIC_STUDENTS_BULK_ADD_BEGIN: 'ACADEMIC_STUDENTS_BULK_ADD_BEGIN',
  ACADEMIC_STUDENTS_BULK_ADD_SUCCESS: 'ACADEMIC_STUDENTS_BULK_ADD_SUCCESS',
  ACADEMIC_STUDENTS_BULK_ADD_ERR: 'ACADEMIC_STUDENTS_BULK_ADD_ERR',

  ACADEMIC_STUDENTS_READ_BEGIN: 'ACADEMIC_STUDENTS_READ_BEGIN',
  ACADEMIC_STUDENTS_READ_SUCCESS: 'ACADEMIC_STUDENTS_READ_SUCCESS',
  ACADEMIC_STUDENTS_READ_ERR: 'ACADEMIC_STUDENTS_READ_ERR',

  STUDENTS_UPDATE_BEGIN: 'STUDENTS_UPDATE_BEGIN',
  STUDENTS_UPDATE_SUCCESS: 'STUDENTS_UPDATE_SUCCESS',
  STUDENTS_UPDATE_ERR: 'STUDENTS_UPDATE_ERR',
  
  STUDENTS_UPDATE_BULK_BEGIN: 'STUDENTS_UPDATE_BULK_BEGIN',
  STUDENTS_UPDATE_BULK_SUCCESS: 'STUDENTS_UPDATE_BULK_SUCCESS',
  STUDENTS_UPDATE_BULK_ERR: 'STUDENTS_UPDATE_BULK_ERR',

  ADD_NEW_UPDATE_EXISTING_BEGIN: 'ADD_NEW_UPDATE_EXISTING_BEGIN',
  ADD_NEW_UPDATE_EXISTING_SUCCESS: 'ADD_NEW_UPDATE_EXISTING_SUCCESS',
  ADD_NEW_UPDATE_EXISTING_ERR: 'ADD_NEW_UPDATE_EXISTING_SUCCESS',

  academicStudentsAddBegin: () => {
    return {
      type: actions.ACADEMIC_STUDENTS_ADD_BEGIN,
    };
  },

  academicStudentsAddSuccess: (data) => {
    return {
      type: actions.ACADEMIC_STUDENTS_ADD_SUCCESS,
      data,
    };
  },

  academicStudentsAddErr: (err) => {
    return {
      type: actions.ACADEMIC_STUDENTS_ADD_ERR,
      err,
    };
  },

  academicStudentsBulkAddBegin: () => {
    return {
      type: actions.ACADEMIC_STUDENTS_BULK_ADD_BEGIN,
    };
  },

  academicStudentsBulkAddSuccess: (data) => {
    return {
      type: actions.ACADEMIC_STUDENTS_BULK_ADD_SUCCESS,
      data,
    };
  },

  academicStudentsBulkAddErr: (err) => {
    return {
      type: actions.ACADEMIC_STUDENTS_BULK_ADD_ERR,
      err,
    };
  },

  addnewstudentsupdateexistingBegin: () =>{
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_BEGIN,
    };
  },

  addnewstudentsupdateexistingSuccess: (data) => {
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_SUCCESS,
      data,
    };
  },

  addnewstudentsupdateexistingErr: (err) => {
    return {
      type: actions.ADD_NEW_UPDATE_EXISTING_ERR,
      err,
    };
  },

  academicStudentsReadBegin: () => {
    return {
      type: actions.ACADEMIC_STUDENTS_READ_BEGIN,
    };
  },

  academicStudentsReadSuccess: (data) => {
    return {
      type: actions.ACADEMIC_STUDENTS_READ_SUCCESS,
      data,
    };
  },

  academicStudentsReadErr: (err) => {
    return {
      type: actions.ACADEMIC_STUDENTS_READ_ERR,
      err,
    };
  },

  studentsUpdateBegin: () => {
    return {
      type: actions.STUDENTS_UPDATE_BEGIN,
    };
  },

  studentsUpdateSuccess: (data) => {
    return {
      type: actions.STUDENTS_UPDATE_SUCCESS,
      data,
    };
  },

  studentsUpdateErr: (err) => {
    return {
      type: actions.STUDENTS_UPDATE_ERR,
      err,
    };
  },

  studentsUpdateBulkBegin: () => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_BEGIN,
    };
  },

  studentsUpdateBulkSuccess: (data) => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_SUCCESS,
      data,
    };
  },

  studentsUpdateBulkErr: (err) => {
    return {
      type: actions.STUDENTS_UPDATE_BULK_ERR,
      err,
    };
  },
};

export default actions;
