import React, { useEffect, useLayoutEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  UilBooks,
  UilBook,
} from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';
import { useDispatch, useSelector } from 'react-redux';
import { studentSubjectsDataRead } from '../redux/studentSubjectsUnits/actionCreator';
import Cookies from 'js-cookie';
import { ellipsis } from '../utility/utility'

const userId = Cookies.get('userId')

function StudentMenu() {
  const path = '/student';

  const dispatch = useDispatch();


  const addParentActive = (event) => {
    Cookies.remove('activeunit')
    Cookies.remove('activestudenttopic')
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };

// get data by userid
  useEffect(() => {
    let userId = 1
    if (dispatch) {
      dispatch(studentSubjectsDataRead());
    }
  }, [dispatch]);

  const { StudentSubjects, isLoading } = useSelector((state) => {
    return {
      StudentSubjects: state.StudentSubjects.data,
      isLoading: state.StudentSubjects.loading,
    };
  });


  return (
    <TopMenuStyle>
      <div className="ninjadash-top-menu">
        <ul>
          <li className="">
            <NavLink to={`${path}`} >
              Dashboard
            </NavLink>
          </li>

          <li className="mega-item has-subMenu">
            <Link to="#" className="parent">
              Learn
            </Link>
            <ul className="megaMenu-wrapper megaMenu-wide" style={{paddingBottom:'50px'}}>
              <li>
                <span className="mega-title">1st Year</span>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 1</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'I-I').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 2</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'I-II').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
              <li>
                <span className="mega-title">2nd Year</span>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 1</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'II-I').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 2</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'II-II').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
              <li>
                <span className="mega-title">3rd Year</span>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 1</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'III-I').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 2</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'III-II').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
              <li>
                <span className="mega-title">4th Year</span>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 1</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'IV-I').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
                <ul style={{ height: '50%' }}>
                  <span className="mega-sub-title">Semester 2</span>
                  {
                    StudentSubjects.length !== 0 && StudentSubjects.filter(sub => sub.yearsemester_code === 'IV-II').map((obj, index) =>
                      <li key={index} className='text-ellipsis'>
                        <NavLink onClick={addParentActive} to={`${path}/subject/${obj.subject_id}`}>
                          {obj.subject_name}
                        </NavLink>
                      </li>
                    )
                  }
                </ul>
              </li>
            </ul>
            {/* {
              SubjectsUnits !== null && SubjectsUnits.subjects.length !== 0 ?
                <ul className="subMenu">
                  {
                    SubjectsUnits.subjects.sort((a, b) => a.name > b.name ? 1 : -1).map((subjectObj, subjectIndex) =>
                      <li className="has-subMenu-left" key={subjectIndex}>
                        <Link to="#" className="parent" >
                          <UilBooks />
                          <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis', width: '150px' }}>
                            {subjectObj.name}
                          </div>
                        </Link>
                        {
                          subjectObj.units.length !== 0 &&
                          <ul className="subMenu">
                            {
                              subjectObj.units.sort((a, b) => a.name > b.name ? 1 : -1).map((unitObj, unitIndex) =>
                                <li key={unitIndex}>
                                  <NavLink onClick={addParentActive} to={`${path}/subjects/subject/${subjectObj.id}/unit/${unitObj.id}`}>
                                    <UilBook />
                                    {unitObj.name} ({unitObj.questionscount})
                                  </NavLink>
                                </li>
                              )
                            }
                          </ul>
                        }
                      </li>
                    )
                  }
                </ul>
                :
                <ul className="subMenu">
                  <li>
                    <Link to="#">
                      Subjects Not Found
                    </Link>
                  </li>
                </ul>
            } */}
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default StudentMenu;