import actions from './actions';

const {
  SUBJECTS_ADD_BEGIN,
  SUBJECTS_ADD_SUCCESS,
  SUBJECTS_ADD_ERR,

  SUBJECTS_READ_BEGIN,
  SUBJECTS_READ_SUCCESS,
  SUBJECTS_READ_ERR,

  SUBJECTS_UPDATE_BEGIN,
  SUBJECTS_UPDATE_SUCCESS,
  SUBJECTS_UPDATE_ERR,
} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const SUBJECTSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
        case SUBJECTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    
    default:
      return state;
  }
};
const SUBJECTSAddReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case SUBJECTS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECTS_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECTS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    
    default:
      return state;
  }
};

const SUBJECTSUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case SUBJECTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case SUBJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case SUBJECTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { SUBJECTSReducer, SUBJECTSUpdateReducer, SUBJECTSAddReducer };
