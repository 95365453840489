import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';


const {
  subjectsUnitsReadBegin,
  subjectsUnitsReadSuccess,
  subjectsUnitsReadErr,
  subjectReadBegin,
  subjectReadSuccess,
  subjectReadErr,
  unitReadBegin,
  unitReadSuccess,
  unitReadErr,
} = actions;


const subjectsUnitsDataRead = () => {    
  return async (dispatch) => {
    try {
      await dispatch(subjectsUnitsReadBegin());
      const query = await DataService.get(`/teachermaster/units`);       
      const token = Cookies.get('access_token');                  
      await dispatch(subjectsUnitsReadSuccess(query.data));           
      if (token) {
        //
      } else {
        await dispatch(subjectsUnitsReadSuccess([]));
        // await dispatch(subjectsUnitsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(subjectsUnitsReadErr(err));
    }
  };
};

const getSubjectById = (subjectId) => {    
  return async (dispatch) => {
    try {
      await dispatch(subjectReadBegin());
      const query = await DataService.get(`/subjectmaster/${subjectId}`); 
      const token = Cookies.get('access_token');
      await dispatch(subjectReadSuccess(query.data));         
      if (token) {
        //
      } else {
        await dispatch(subjectReadSuccess([]));
        // await dispatch(subjectsUnitsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(subjectReadErr(err));
    }
  };
};

const getUnitById = (unitId) => {
  return async (dispatch) => {
    try {
      await dispatch(unitReadBegin());
      const query = await DataService.get(`/unitmaster/${unitId}`); 
      const token = Cookies.get('access_token');
      await dispatch(unitReadSuccess(query.data));            
      if (token) {
        //
      } else {
        await dispatch(subjectsUnitsReadSuccess([]));
        // await dispatch(subjectsUnitsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(unitReadErr(err));
    }
  };
};


export {
  subjectsUnitsDataRead, 
  getSubjectById,
  getUnitById
};