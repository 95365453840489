const actions = {
  TESELLATORSUBJECTS_READ_BEGIN: 'TESELLATORSUBJECTS_READ_BEGIN',
  TESELLATORSUBJECTS_READ_SUCCESS: 'TESELLATORSUBJECTS_READ_SUCCESS',
  TESELLATORSUBJECTS_READ_ERR: 'TESELLATORSUBJECTS_READ_ERR',

  TESELLATORGETURL_READ_BEGIN: 'TTESELLATORGETURL_READ_BEGIN',
  TESELLATORGETURL_READ_SUCCESS: 'TESELLATORGETURL_READ_SUCCESS',
  TESELLATORGETURL_READ_ERR: 'TESELLATORGETURL_READ_ERR',

  tesellatorsubjectsReadBegin: () => {
    return {
      type: actions.TESELLATORSUBJECTS_READ_BEGIN,
    };
  },

  tesellatorsubjectsReadSuccess: (data) => {
    return {
      type: actions.TESELLATORSUBJECTS_READ_SUCCESS,
      data,
    };
  },

  tesellatorsubjectsReadErr: (err) => {
    return {
      type: actions.TESELLATORSUBJECTS_READ_ERR,
      err,
    };
  },

  tesellatorurlgetReadBegin: () => {
    return {
      type: actions.TESELLATORGETURL_READ_BEGIN,
    };
  },

  tesellatorurlgetReadSuccess: (data) => {
    return {
      type: actions.TESELLATORGETURL_READ_SUCCESS,
      data,
    };
  },

  tesellatorurlgetReadErr: (err) => {
    return {
      type: actions.TESELLATORGETURL_READ_ERR,
      err,
    };
  },

};

export default actions;
