const actions = {
  DASHBOARD_READ_BEGIN: 'DASHBOARD_READ_BEGIN',
  DASHBOARD_READ_SUCCESS: 'DASHBOARD_READ_SUCCESS',
  DASHBOARD_READ_ERR: 'DASHBOARD_READ_ERR',

  YEARWISEBADGESCOUNT_READ_BEGIN: 'YEARWISEBADGESCOUNT_READ_BEGIN',
  YEARWISEBADGESCOUNT_READ_SUCCESS: 'YEARWISEBADGESCOUNT_READ_SUCCESS',
  YEARWISEBADGESCOUNT_READ_ERR: 'YEARWISEBADGESCOUNT_READ_ERR',

  DASHBOARD_ADD_BEGIN: 'DASHBOARD_ADD_BEGIN',
  DASHBOARD_ADD_SUCCESS: 'DASHBOARD_ADD_SUCCESS',
  DASHBOARD_ADD_ERR: 'DASHBOARD_ADD_ERR',

  dashboardReadBegin: () => {
    return {
      type: actions.DASHBOARD_READ_BEGIN,
    };
  },

  dashboardReadSuccess: (data) => {
    return {
      type: actions.DASHBOARD_READ_SUCCESS,
      data,
    };
  },

  dashboardReadErr: (err) => {
    return {
      type: actions.DASHBOARD_READ_ERR,
      err,
    };
  },

  yearwisebadgescountReadBegin: () => {
    return {
      type: actions.YEARWISEBADGESCOUNT_READ_BEGIN,
    };
  },

  yearwisebadgescountReadSuccess: (data) => {
    return {
      type: actions.YEARWISEBADGESCOUNT_READ_SUCCESS,
      data,
    };
  },

  yearwisebadgescountReadErr: (err) => {
    return {
      type: actions.YEARWISEBADGESCOUNT_READ_ERR,
      err,
    };
  },

  dashboardAddBegin: () => {
    return {
      type: actions.DASHBOARD_ADD_BEGIN,
    };
  },

  dashboardAddSuccess: (data) => {
    return {
      type: actions.DASHBOARD_ADD_SUCCESS,
      data,
    };
  },

  dashboardAddErr: (err) => {
    return {
      type: actions.DASHBOARD_ADD_ERR,
      err,
    };
  },

};

export default actions;
