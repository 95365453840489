const actions = {

  CREATE_INTERVIEWER_BEGIN: 'CREATE_INTERVIEWER_BEGIN',
  CREATE_INTERVIEWER_SUCCESS: 'CREATE_INTERVIEWER_SUCCESS',
  CREATE_INTERVIEWER_ERR: 'CREATE_INTERVIEWER_ERR',

  GET_INTERVIEWER_BEGIN: 'GET_INTERVIEWER_BEGIN',
  GET_INTERVIEWER_SUCCESS: 'GET_INTERVIEWER_SUCCESS',
  GET_INTERVIEWER_ERR: 'GET_INTERVIEWER_ERR',

  UPDATE_INTERVIEWER_BEGIN: 'UPDATE_INTERVIEWER_BEGIN',
  UPDATE_INTERVIEWER_SUCCESS: 'UPDATE_INTERVIEWER_SUCCESS',
  UPDATE_INTERVIEWER_ERR: 'UPDATE_INTERVIEWER_ERR',

  createInterviewerDataBegin: () => {
    return {
      type: actions.CREATE_INTERVIEWER_BEGIN,
    };
  },

  createInterviewerDataSuccess: (data) => {
    return {
      type: actions.CREATE_INTERVIEWER_SUCCESS,
      data,
    };
  },

  createInterviewerDataErr: (err) => {
    return {
      type: actions.CREATE_INTERVIEWER_ERR,
      err,
    };
  },

  getInterviewerDataBegin: () => {
    return {
      type: actions.GET_INTERVIEWER_BEGIN,
    };
  },

  getInterviewerDataSuccess: (data) => {
    return {
      type: actions.GET_INTERVIEWER_SUCCESS,
      data,
    };
  },

  getInterviewerDataErr: (err) => {
    return {
      type: actions.GET_INTERVIEWER_ERR,
      err,
    };
  },

  updateInterviewerDataBegin: () => {
    return {
      type: actions.UPDATE_INTERVIEWER_BEGIN,
    };
  },

  updateInterviewerDataSuccess: (data) => {
    return {
      type: actions.UPDATE_INTERVIEWER_SUCCESS,
      data,
    };
  },

  updateInterviewerDataErr: (err) => {
    return {
      type: actions.UPDATE_INTERVIEWER_ERR,
      err,
    };
  },

};

export default actions;
