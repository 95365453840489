import actions from './actions';

const {
  DASHBOARD_READ_BEGIN,
  DASHBOARD_READ_SUCCESS,
  DASHBOARD_READ_ERR,

  YEARWISEBADGESCOUNT_READ_BEGIN,
  YEARWISEBADGESCOUNT_READ_SUCCESS,
  YEARWISEBADGESCOUNT_READ_ERR,

  DASHBOARD_ADD_BEGIN,
  DASHBOARD_ADD_SUCCESS,
  DASHBOARD_ADD_ERR,

} = actions;

const initialState = {
  data: [],
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const dashboardAddinitialState = {
  data: null,
  url: null,
  fileLoading: false,
  loading: false,
  error: null,
};

const DASHBOARDReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case DASHBOARD_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DASHBOARD_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

  
    default:
      return state;
  }
};

const YEARWISEBADGESCOUNTReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case YEARWISEBADGESCOUNT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case YEARWISEBADGESCOUNT_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case YEARWISEBADGESCOUNT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

  
    default:
      return state;
  }
};

const DASHBOARDAddReducer = (state = dashboardAddinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DASHBOARD_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case DASHBOARD_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

   
    default:
      return state;
  }
};

export { DASHBOARDReducer, YEARWISEBADGESCOUNTReducer, DASHBOARDAddReducer };
