import React, { useEffect, useRef, useState } from 'react'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Main, TableWrapper } from '../styled'
import { Button, Col, Collapse, Modal, Result, Row, Select, Table, Tooltip, message } from 'antd'
import { ProjectList, ProjectSorting } from '../project/style'
import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { useDispatch } from 'react-redux'
import { Cards } from '../../components/cards/frame/cards-frame'
import { getAcademicInterviewAttemptsById, getAcademicInterviewsBySchedule } from '../../redux/academicInterviews/actionCreator'

import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { ActionWrapper } from '../../components/steps/style'
import UilArrowRight from '@iconscout/react-unicons/icons/uil-arrow-right';
import UilArrowLeft from '@iconscout/react-unicons/icons/uil-arrow-left';
import WaveSurfer from 'wavesurfer.js'
import FontAwesome from 'react-fontawesome';
// import jsPDF from 'jspdf'
// import 'jspdf-autotable';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import { CSVLink } from 'react-csv';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { NoteCardWrap } from '../note/style'
import { Button as ButtonComponent } from '../../components/buttons/buttons'
import FileInfoAlt from '@iconscout/react-unicons/icons/uil-file-info-alt';
import Hourglass from '@iconscout/react-unicons/icons/uil-hourglass';
import UilCheck from '@iconscout/react-unicons/icons/uil-check';
import { Page, Text, Document, StyleSheet, PDFDownloadLink, View } from '@react-pdf/renderer';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import UilMinus from '@iconscout/react-unicons/icons/uil-minus';
import { getInterviewAttemptsById, getInterviewsBySchedule } from '../../redux/interview/actionCreator'

const { Option } = Select

export default function Interviews({ TopicScheduledData, isSchedulesLoading, interviewsBySchedule, isInterviewsByScheduleLoading, interviewAttempts, isInterviewAttemptsLoading, pageTitle }) {

    const [selectedSchedule, setSelectedSchedule] = useState(null)
    const [tableData, setTableData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [modalVisisble, setModalVisisble] = useState(false)
    const [rowData, setRowData] = useState(null)
    const [questionIndex, setQuestionIndex] = useState(0)
    const waveSurferRef = useRef(null);

    const dispatch = useDispatch();

    const loadData = () => {
        if (selectedSchedule) {
            {
                pageTitle == "Academic Interviews" ?
                    dispatch(getAcademicInterviewsBySchedule(selectedSchedule))
                    :
                    pageTitle == "Finishingschool Interviews" &&
                    dispatch(getInterviewsBySchedule(selectedSchedule))
            }

        }
    }

    useEffect(() => {
        if (dispatch) {
            setTableData([])
            setFilteredData([])
            setModalVisisble(false)
            setQuestionIndex(0)
            setRowData(null)
            setSelectedSchedule(null)
        }
    }, [dispatch]);

    useEffect(() => {
        if (interviewsBySchedule) {
            const data = interviewsBySchedule.map((obj, index) => ({
                key: index + 1,
                sno: index + 1,
                interviewMasterId: obj.interviewMasterId,
                studentId: obj.studentID,
                rollno: obj.username,
                studentName: obj.studentName,
                interviewStatus: obj.interviewStatus || 'ABSENT',
                interviewStatusIcon: obj.interviewStatus === "EVALUATING" ? <Hourglass /> : obj.interviewStatus === "EVALUATED" ? <UilCheck /> : '-' || 'ABSENT',
                questionsattempted: obj.questionsAttempted || '0',
                scheduledName: obj.scheduledName,
                scheduledDate: obj.scheduledDate,
                avgscore: (obj.averageScore === -1 || obj.averageScore === 0) ? 0 : obj.averageScore || 'NA',
                scheduledQuestionCount: obj.scheduledQuestionCount,
                attemptedon: obj.attemptedOn !== null ? new Date(obj.attemptedOn).toLocaleString() : 'NA',
            }))
            setTableData(data)
            setFilteredData(data)
        }
    }, [interviewsBySchedule])

    const filterTableData = (column, value) => {
        if (tableData) {
            let data = []
            if (value === "ALL") {
                data = tableData
            }
            if (value === "PRESENT") {
                data = tableData.filter((obj) => obj[column] !== "ABSENT")
            }
            if (value === "ABSENT") {
                data = tableData.filter((obj) => obj[column] === "ABSENT")
            }
            if (value === "0") {
                data = tableData.filter((obj) => obj[column] == 0)
            }
            if (value === "1-4") {
                data = tableData.filter((obj) => obj[column] >= 1 && obj[column] <= 4)
            }
            if (value === "5-7") {
                data = tableData.filter((obj) => obj[column] >= 5 && obj[column] <= 7)
            }
            if (value === "8-10") {
                data = tableData.filter((obj) => obj[column] >= 8 && obj[column] <= 10)
            }
            setFilteredData(data)
        }
        else {
            setFilteredData([])
        }
    }

    const searchkeys = ['rollno']
    const handleSearch = (e) => {
        const value = e;
        const searchData = tableData.length !== 0 && tableData.filter((obj) => searchkeys.some((key) => obj[key].toLowerCase().includes(value.toLowerCase())))
        setFilteredData(searchData)
    }

    const columns = [
        {
            title: 'Rollno',
            dataIndex: 'rollno',
            key: 'rollno',
            sorter: (a, b) => a.rollno > b.rollno ? 1 : -1,
        },
        {
            title: 'Name',
            dataIndex: 'studentName',
            key: 'studentName',
        },
        {
            title: 'Answered',
            dataIndex: 'questionsattempted',
            key: 'questionsattempted',
            sorter: (a, b) => a.questionsattempted > b.questionsattempted ? 1 : -1,
        },
        {
            title: 'Grade',
            dataIndex: 'avgscore',
            key: 'avgscore',
            sorter: (a, b) => a.avgscore > b.avgscore ? 1 : -1,
        },
        {
            title: 'Status',
            dataIndex: 'interviewStatusIcon',
            key: 'interviewStatus',
            sorter: (a, b) => a.interviewStatus > b.interviewStatus ? 1 : -1,
            sortDirections: ['ascend'],
            filters: interviewsBySchedule.length !== 0 && Array.from(new Set(interviewsBySchedule.map(interview => interview.interviewStatus == null ? "ABSENT" : interview.interviewStatus))).map(interviewStatus => ({ key: interviewStatus, text: interviewStatus, value: interviewStatus })),
            onFilter: (value, record) => record.interviewStatus === value,
        },
        {
            title: 'Report',
            render: (_, row) => (
                <div>
                    {
                        row.interviewStatus === "EVALUATING" ?
                            <div className='text-danger'>Evaluating</div>
                            :
                            <ButtonComponent size='small' type='primary' outlined disabled={row.interviewStatus === "STARTED" || row.interviewStatus === "INCOMPLETE" || row.interviewMasterId === null} onClick={() => { setModalVisisble(true); { pageTitle == "Academic Interviews" ? dispatch(getAcademicInterviewAttemptsById(parseInt(row.interviewMasterId))) : pageTitle == "Finishingschool Interviews" && dispatch(getInterviewAttemptsById(parseInt(row.interviewMasterId))) }; setRowData(row); setQuestionIndex(0) }} ><FileInfoAlt /></ButtonComponent>
                    }
                </div>
            )
        },
    ];

    const handlePrevQuestion = () => {
        if (questionIndex + 1 !== 0) {
            setQuestionIndex(questionIndex - 1);
        }
    }

    const handleNextQuestion = () => {
        if (questionIndex + 1 < interviewAttempts.length) {
            setQuestionIndex(questionIndex + 1);
        }
    }

    const AudioPlayer = ({ audioUrl }) => {
        const [isAudioLoaded, setIsAudioLoaded] = useState(false);
        const [isErrorFound, setIsErrorFound] = useState(false)
        useEffect(() => {
            if (audioUrl !== "--" && audioUrl !== null) {
                // Initialize WaveSurfer when the component mounts
                waveSurferRef.current = WaveSurfer.create({
                    container: '#waveform', // Use a div with the id 'waveform' as the container
                    waveColor: '#6FA2CE',
                    progressColor: '#2D5D85',
                    cursorColor: '#224563',
                    barWidth: 2,
                    barGap: 1,
                    barRadius: 2,
                    height: 20,
                });
                waveSurferRef.current.on('ready', () => {
                    setIsAudioLoaded(true)
                })
                waveSurferRef.current.on('error', () => {
                    setIsErrorFound(true)
                })

                // Add event listener for the finish event
                waveSurferRef.current.on('finish', () => {
                    setIsPlaying(false);
                });

                return () => {
                    // Clean up when the component unmounts
                    waveSurferRef.current.destroy();
                    setIsPlaying(false)
                };
            }
        }, []);

        useEffect(() => {
            // Load the new audio URL when it changes
            if (audioUrl && audioUrl !== "--") {
                waveSurferRef.current.load(process.env.REACT_APP_API_ENDPOINT + audioUrl);
            }
        }, [audioUrl]);

        const [isPlaying, setIsPlaying] = useState(false)
        const handlePlayPause = () => {
            if (waveSurferRef.current) {
                if (waveSurferRef.current.isPlaying()) {
                    waveSurferRef.current.pause();
                    setIsPlaying(false);
                } else {
                    waveSurferRef.current.play();
                    setIsPlaying(true);
                }
            } else {
                console.error('WaveSurfer instance is not initialized.');
                // Handle the error or display a message to the user
            }
        };
        return <div className='text-center'>
            <div id="waveform" style={{ display: `${isErrorFound ? 'none' : 'inline'}` }} />
            {
                !isErrorFound && isAudioLoaded ?
                    <>
                        {
                            (audioUrl !== "--" && audioUrl !== null) &&
                            <Button onClick={handlePlayPause} className='mb-10 icon-btn d-inline' type='link'>
                                {isPlaying ? <FontAwesome name="pause" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} /> : <FontAwesome name="play" style={{ fontSize: '22px', background: '#eee', padding: '6px 18px', borderRadius: '6px' }} />}
                            </Button>
                        }
                    </>
                    :
                    isAudioLoaded ?
                        <p>Loading audio...</p>
                        :
                        isErrorFound && <p className='text-danger'>Failed to load audio</p>
            }
        </div>;
    };

    // const generatePDF = () => {
    //     const now = new Date();
    //     const doc = new jsPDF('landscape');
    //     doc.setFont('Arial');
    //     doc.setFontSize(12);
    //     let startY = 34;
    //     let isFirstPage = true;
    //     let totalPagesExp = '{total_pages_count_string}';

    //     const data = interviewAttempts.map((obj, index) => [
    //         index + 1,
    //         obj.question.question?.replace(/<[^>]*>|&nbsp;/g, ' '),
    //         obj.answer?.replace(/<[^>]*>|&nbsp;/g, ' ') || 'NA',
    //         obj.evaluationfeedback1 === null ? 'NA' : (obj.evaluationfeedback1 + "\n\nScore: " + (obj.evaluationscore1 === -1 ? 'NA' : obj.evaluationscore1)),
    //         // obj.evaluationscore1,
    //         obj.evaluationfeedback2 === null ? 'NA' : (obj.evaluationfeedback2 + "\n\nScore: " + (obj.evaluationscore2 === -1 ? 'NA' : obj.evaluationscore2)),
    //         // obj.evaluationscore2,
    //         obj.avgscore === -1 ? 'NA' : obj.avgscore
    //     ]);

    //     doc.autoTable({
    //         head: [['#', 'Question', "Student Answer", 'Evaluation Feedback 1', 'Evaluation Feedback 2', 'Avg Score']],
    //         body: data,
    //         startY: startY,
    //         theme: 'grid',
    //         columnStyles: {
    //             0: { cellWidth: 8 },
    //             1: { cellWidth: 50 },
    //             2: { cellWidth: 80 },
    //             3: { cellWidth: 53 },
    //             // 4: { cellWidth: 22 },
    //             4: { cellWidth: 53 },
    //             // 6: { cellWidth: 22 },
    //             5: { cellWidth: 25 },
    //         },

    //         didDrawPage: function (data) {
    //             const pageWidth = doc.internal.pageSize.width;

    //             if (isFirstPage) {
    //                 doc.setFontSize(12);
    //                 doc.text(`Roll No.: ${rowData?.rollno}`, startY, 10);
    //                 doc.text(`Student Name: ${rowData?.studentName}`, pageWidth - startY, 10, { align: 'right' });
    //                 doc.text(`Schedule: ${rowData?.scheduledName}`, startY, 22);
    //                 doc.text(`Grade: ${rowData?.avgscore}`, pageWidth - startY, 22, { align: 'right' });
    //                 doc.text(``, pageWidth - startY, 34);
    //                 isFirstPage = false;
    //             }

    //             if (isFirstPage) {
    //                 startY = 45
    //                 data.settings.margin.top = startY;
    //             } else {
    //                 startY = 10;
    //                 data.settings.margin.top = startY;
    //             }
    //             var str = 'Page ' + doc.internal.getNumberOfPages();
    //             if (typeof doc.putTotalPages === 'function') {
    //                 str = str + ' of ' + totalPagesExp;
    //             }
    //             doc.setFontSize(10);
    //             const pageHeight = doc.internal.pageSize.height ? doc.internal.pageSize.height : doc.internal.pageSize.getHeight();
    //             const centerPos = pageWidth / 2;
    //             const textWidth = (doc.getStringUnitWidth('Interview taken on: ' + rowData.attemptedon) * doc.internal.getFontSize() / doc.internal.scaleFactor) - 50;
    //             const xPos = centerPos - textWidth / 2;
    //             doc.text(`Prashn Manch`, startY, pageHeight - 10);
    //             doc.text('Interview taken on: ' + rowData.attemptedon, xPos, pageHeight - 10, { align: 'center' });
    //             doc.text(str, pageWidth - startY + 20, pageHeight - 10, { align: 'right' });

    //         },
    //     });
    //     if (typeof doc.putTotalPages === 'function') {
    //         doc.putTotalPages(totalPagesExp)
    //     }

    //     doc.save(`${rowData.rollno}_${rowData?.scheduledName}_answers.pdf`);
    // };



    const headers = [
        { label: "S.No", key: "sno" },
        { label: "Rollno", key: "rollno" },
        { label: "Name", key: "studentName" },
        // { label: "Schedule Name", key: "scheduledName" },
        { label: "Interview Status", key: "interviewStatus" },
        { label: "Grade", key: "avgscore" },
        { label: "InterviewTakenOn", key: "attemptedon" },
    ];

    const csvReport = {
        filename: `${selectedSchedule}_Interviews_Report`,
        headers: headers,
        data: filteredData,
    }


    // Define styles
    const styles = StyleSheet.create({
        page: {
            padding: 40,
            fontFamily: 'Helvetica',
            lineHeight: 1.5,
            fontSize: 12,
        },
        reportTitle: {
            textAlign: 'center',
            color: '#0056b3',
            fontSize: 16,
            marginBottom: 10,
            textTransform: 'uppercase'
        },
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
            paddingBottom: 10,
            borderBottom: '1px solid #ddd',
            color: '#333',
        },
        section: {
            marginBottom: 10,
            padding: 10,
            backgroundColor: '#fafafa',
            borderRadius: 5,
            border: '1px solid #ddd',
            breakInside: 'avoid', // Prevent section from breaking within
        },
        sectionHeader: {
            fontWeight: 'bold',
            marginBottom: 5,
            color: '#0056b3',
        },
        questionHeader: {
            fontWeight: 'bold',
            marginBottom: 5,
        },
        answer: {
            fontFamily: 'Courier',
            fontSize: 12,
            marginTop: 15
        },
        footer: {
            position: 'absolute',
            bottom: 20,
            left: 40,
            right: 40,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: 10,
            color: '#777',
        },
        fontsize11: {
            fontSize: 11,
        },
        fontsize12: {
            fontSize: 12,
        },
        justifyspacebetween: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 5,
        },
        boldText: {
            fontWeight: 'bold',
        },
    });


    // PDF Document component
    const MyDocument = () => (
        <Document>
            {interviewAttempts?.map((item, index) => (
                <Page key={index} size={'A4'} style={styles.page}>
                    {index === 0 && (
                        <View style={styles.reportTitle}>
                            <Text>Prashna Manch Evaluation Report</Text>
                        </View>
                    )}
                    {index === 0 && (
                        <View style={styles.header}>
                            <View style={styles.headerLeft}>
                                <Text>Roll No.: {rowData?.rollno}</Text>
                                <Text>Name: {rowData?.studentName}</Text>
                            </View>
                            <View style={styles.headerRight}>
                                <Text>Schedule: {rowData?.scheduledName}</Text>
                                <Text>Grade: {rowData?.avgscore}</Text>
                            </View>
                        </View>
                    )}
                    <View style={[styles.section]} wrap={true}>
                        <View style={styles.justifyspacebetween}>
                            <View>
                                <Text style={[styles.sectionHeader, styles.fontsize11]}>
                                    Question Type: {item.question.questionType}
                                </Text>
                            </View>
                            <View>
                                <Text style={[styles.fontsize11]}>
                                    Avg Grade: {(item.evaluationscore1 + item.evaluationscore2 + item.evaluationscore3) / 3}
                                </Text>
                            </View>
                            <View>
                                <Text style={[styles.fontsize11]}>
                                    Status: {item.status}
                                </Text>
                            </View>
                        </View>

                        <Text style={styles.questionHeader}>Q{index + 1}. {item.question.question}</Text>
                        <Text>
                            {
                                (item.question.questionType === "JUMBLED CODE" || item.question.questionType === "EXPLAIN CODE" || item.question.questionType === "DEBUG CODE" || item.question.questionType === "PREDICT OUTPUT") &&
                                item.question.modifiedProgram
                            }
                        </Text>
                        <Text style={styles.answer}>
                            <Text style={styles.sectionHeader}>Answer</Text>
                            {item.answer}
                        </Text>
                        <View style={{ marginTop: 10 }} wrap={true}>
                            <Text style={styles.sectionHeader}>Evaluations</Text>
                            <View style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }}>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback~1</Text>
                                    <Text style={styles.fontsize12}>Score~1: {item.evaluationscore1}</Text>
                                </View>
                                <View>
                                    <Text>{item.evaluationfeedback1}</Text>
                                </View>
                            </View>
                            <View style={{ borderBottom: '1px solid #ddd', paddingBottom: 10 }}>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback~2</Text>
                                    <Text style={styles.fontsize12}>Score~2: {item.evaluationscore2}</Text>
                                </View>
                                <View>
                                    <Text>{item.evaluationfeedback2}</Text>
                                </View>
                            </View>
                            <View>
                                <View style={styles.justifyspacebetween}>
                                    <Text style={styles.fontsize12}>Feedback~3</Text>
                                    <Text style={styles.fontsize12}>Score~3: {item.evaluationscore3}</Text>
                                </View>
                                <View>
                                    <Text>{item.evaluationfeedback3}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Text>Prashna Manch</Text>
                        <Text>Interview Taken on: {rowData.attemptedon}</Text>
                        <Text>Page {index + 1}</Text>
                    </View>
                </Page>
            ))}
        </Document>
    );

    return (
        <div>
            <PageHeader className="ninjadash-page-header-main" title={pageTitle} />
            <Main>
                <Row gutter={25}>
                    <Col sm={24} xs={24}>
                        <ProjectSorting>
                            <div className='d-flex'>
                                <div className="sort-group mr-5">
                                    <Select id="interviews-page-schedule-filter"
                                        placeholder="Select schedule"
                                        value={selectedSchedule}
                                        onChange={(e) => { setSelectedSchedule(e) }}
                                        style={{ minWidth: '200px', fontSize: '14px', width: '350px' }}
                                        loading={isSchedulesLoading}
                                        showSearch
                                        filterOption={(inputValue, option) =>
                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                        }
                                        maxTagCount={'responsive'}
                                    >
                                        {TopicScheduledData !== null && TopicScheduledData.Error === false && TopicScheduledData.payload.length !== 0 && TopicScheduledData.payload.sort((a, b) => a.id < b.id ? 1 : -1).map((schedule) => (
                                            <Option key={schedule.name} value={schedule.name}>
                                                {schedule.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="sort-group">
                                    <Button type={'primary'} onClick={() => { loadData() }} disabled={selectedSchedule === null}>Get</Button>
                                </div>
                            </div>
                        </ProjectSorting>
                        <Cards headless>
                            {
                                isInterviewsByScheduleLoading ?
                                    <NoteCardWrap>
                                        <div className="custom-spin">
                                            <CustomSpinner />
                                        </div>
                                    </NoteCardWrap>
                                    :
                                    <div>
                                        <div className="d-flex justify-content-between mb-20">
                                            <div className='d-flex align-center-v'>
                                                {
                                                    tableData.length !== 0 &&
                                                    <div>
                                                        <Tooltip title="Dispaly all students" placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('_', "ALL")}>All</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.interviewStatus !== "ABSENT").length} students are Present`} placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('interviewStatus', "PRESENT")}>P ({tableData.filter((obj) => obj.interviewStatus !== "ABSENT").length})</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.interviewStatus === "ABSENT").length} students are Absent`} placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('interviewStatus', "ABSENT")}>AB ({tableData.filter((obj) => obj.interviewStatus === "ABSENT").length})</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.avgscore == 0).length} students scored 0`} placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('avgscore', "0")}> 0 ({tableData.filter((obj) => obj.avgscore == 0).length})</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.avgscore >= 1 && obj.avgscore <= 4).length} students scored  1 to 4`} placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('avgscore', "1-4")}> 1-4 ({tableData.filter((obj) => obj.avgscore >= 1 && obj.avgscore <= 4).length})</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.avgscore >= 5 && obj.avgscore <= 7).length} students scored 5 to 7`} placement='top'>
                                                            <span className='status cursor-pointer mr-5' onClick={() => filterTableData('avgscore', "5-7")}> 5-7 ({tableData.filter((obj) => obj.avgscore >= 5 && obj.avgscore <= 7).length})</span>
                                                        </Tooltip>
                                                        <Tooltip title={`${tableData.filter((obj) => obj.avgscore >= 8 && obj.avgscore <= 10).length} students scored 8 to 10`} placement='top'>
                                                            <span className='status cursor-pointer' onClick={() => filterTableData('avgscore', "8-10")}> 8-10 ({tableData.filter((obj) => obj.avgscore >= 8 && obj.avgscore <= 10).length})</span>
                                                        </Tooltip>

                                                    </div>
                                                }
                                            </div>
                                            <div className='d-flex align-center-v'>
                                                {
                                                    tableData.length !== 0 &&
                                                    <Tooltip placement='top' title="Export">
                                                        <CSVLink {...csvReport}>
                                                            <Button type='link' className='mr-5'><UilExport size={20} /></Button>
                                                        </CSVLink>
                                                    </Tooltip>
                                                }
                                                <AutoComplete id="" onSearch={handleSearch} dataSource={tableData} placeholder="Search here" patterns />
                                            </div>
                                        </div>
                                        <TableWrapper>
                                            <ProjectList>
                                                <div className="table-responsive">
                                                    <Table
                                                        dataSource={filteredData}
                                                        columns={columns}
                                                        showSorterTooltip={false}
                                                        pagination={{
                                                            className: 'd-flex align-center-v',
                                                            size: 'small',
                                                            // showSizeChanger: true,
                                                            showTotal: (total) => `Total ${total} Interviews`
                                                        }} />
                                                </div>
                                            </ProjectList>
                                        </TableWrapper>
                                    </div>
                            }
                        </Cards>
                    </Col>
                </Row>

                <Modal
                    open={modalVisisble}
                    title={<span>EVALUATION REPORT: <i style={{ fontWeight: 400 }}>{rowData?.rollno}</i>, <i style={{ fontWeight: 400 }}>{rowData?.studentName}</i>, <i>Grade</i> - {rowData?.avgscore}</span>}
                    width={1400}
                    centered
                    maskClosable={false}
                    footer={false}
                    onCancel={() => { setModalVisisble(false) }}
                >
                    {
                        isInterviewAttemptsLoading ?
                            <div className="spin">
                                <CustomSpinner Message={'Loading... Please wait.'} />
                            </div>
                            :
                            interviewAttempts.length === 0 ?
                                <div>
                                    <Result
                                        title="Evaluations not found!"
                                        status="warning"
                                    />
                                </div>
                                :
                                interviewAttempts.length !== 0 &&
                                <div>
                                    <div>
                                        <div>
                                            {/* <StepsStyle current={questionIndex} size="small">
                                                {interviewAttempts.map((question, index) => (
                                                    <StepsStyle.Step key={index} />
                                                ))}
                                            </StepsStyle> */}
                                            <div className='d-flex justify-content-between align-center-v'>
                                                <div>
                                                    <span className={`status mr-2 ${interviewAttempts[questionIndex].status == "SKIPPED" ? 'text-danger' : ''}`}>{interviewAttempts[questionIndex].status}</span>
                                                    <span className='status'>{interviewAttempts[questionIndex].question?.questionType}</span>
                                                </div>
                                                <div className='d-flex align-center-v'>
                                                    {/* <span className='mr-5'>Avg score: {interviewAttempts[0].interview.avgscore}</span> */}
                                                    {/* <ButtonComponent type={'primary'} outlined onClick={() => { DownloadPDF() }} className='d-flex align-center-v'>
                                                        <UilImport size={16} /> &nbsp;
                                                        <span>PDF</span>
                                                    </ButtonComponent> */}
                                                    {/*<PDFDownloadLink document={<MyDocument />} fileName={rowData?.rollno + '-evaluation-report.pdf'}>
                                                        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
                                                    </PDFDownloadLink>*/}
                                                </div>
                                            </div>
                                            {
                                                interviewAttempts[questionIndex].question?.questionType == "CODE" || interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE" ?
                                                    <div className="interviews-page-panel mt-10" >
                                                        <PanelGroup direction="vertical">
                                                            <Panel key='top' minSize={10}>
                                                                <PanelGroup direction="horizontal">
                                                                    <Panel key="left" collapsible={false} collapsedSize={10} defaultSize={50} minSize={10} style={{ overflow: 'auto' }}>
                                                                        <h3 className='p-2 d-flex' dangerouslySetInnerHTML={{ __html: (questionIndex + 1) + '. ' + interviewAttempts[questionIndex].question?.question.toString('html') }}></h3>
                                                                        {
                                                                            (interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE") &&
                                                                                interviewAttempts[questionIndex].question?.modifiedProgram ? interviewAttempts[questionIndex].question.modifiedProgram.split('\n').map((line, index) => {
                                                                                    if (line.trim() !== '') {
                                                                                        return (
                                                                                            <li
                                                                                                key={index}
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                }}
                                                                                            >
                                                                                                <span>{line}</span>
                                                                                            </li>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })
                                                                                :
                                                                                <div className='d-flex'>
                                                                                    NA
                                                                                </div>
                                                                        }
                                                                    </Panel>
                                                                    <PanelResizeHandle style={{ width: '1px', backgroundColor: '#ddd' }} />
                                                                    <Panel key="right" collapsible={false} collapsedSize={10} minSize={10} style={{ overflow: 'auto' }}>
                                                                        {
                                                                            interviewAttempts[questionIndex].question?.questionType === "CODE" || interviewAttempts[questionIndex].question?.questionType === "EXPLAIN CODE" ?
                                                                                <p className='p-2' dangerouslySetInnerHTML={{ __html: 'Ans: ' + interviewAttempts[questionIndex].answer }}></p>
                                                                                :
                                                                                (interviewAttempts[questionIndex].question?.questionType === "PREDICT OUTPUT" || interviewAttempts[questionIndex].question?.questionType === "DEBUG CODE") &&
                                                                                    interviewAttempts[questionIndex].answer ? interviewAttempts[questionIndex].answer.split('\n').map((line, index) => {
                                                                                        if (line.trim() !== '') {
                                                                                            return (
                                                                                                <li
                                                                                                    key={index}
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                    }}
                                                                                                >
                                                                                                    <span>{line}</span>
                                                                                                </li>
                                                                                            );
                                                                                        } else {
                                                                                            return null;
                                                                                        }
                                                                                    })
                                                                                    :
                                                                                    <div className='d-flex'>
                                                                                        NA
                                                                                    </div>
                                                                        }
                                                                    </Panel>
                                                                </PanelGroup>
                                                            </Panel>
                                                            <PanelResizeHandle style={{ height: '1px', backgroundColor: '#ddd' }} />
                                                            <Panel key="bottom" collapsible={false} collapsedSize={10} minSize={10} style={{ overflow: 'auto' }}>
                                                                <Evaluations interviewAttempts={interviewAttempts} questionIndex={questionIndex} />
                                                            </Panel>
                                                        </PanelGroup>
                                                    </div>
                                                    :
                                                    <div className={'mt-10'} style={{ textAlign: 'left' }}>
                                                        <div style={{ marginTop: '15px', marginBottom: '10px' }} className='d-flex justify-content-between align-center-v'>
                                                            <h3 className='d-flex' dangerouslySetInnerHTML={{ __html: (questionIndex + 1) + '. ' + interviewAttempts[questionIndex].question?.question.toString('html') }}></h3>
                                                            {/* <div>
                                                                <span className='status'>{interviewAttempts[questionIndex].status}</span>
                                                                <span className='status'>{interviewAttempts[questionIndex].question?.questionType}</span>
                                                            </div> */}
                                                        </div>
                                                        <div>
                                                            {
                                                                interviewAttempts[questionIndex].question?.questionType === "JUMBLED CODE" ?
                                                                    <div>
                                                                        {
                                                                            interviewAttempts[questionIndex].answer ? interviewAttempts[questionIndex].answer.split('\n').map((line, index) => {
                                                                                if (line.trim() !== '') {
                                                                                    return (
                                                                                        <li
                                                                                            key={index}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                            }}
                                                                                        >
                                                                                            <span>{line}</span>
                                                                                        </li>
                                                                                    );
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            })
                                                                                :
                                                                                <div className='d-flex'>
                                                                                    NA
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <p dangerouslySetInnerHTML={{ __html: 'Ans: ' + interviewAttempts[questionIndex].answer }}></p>
                                                            }

                                                            {
                                                                interviewAttempts[questionIndex].status === "ATTEMPTED" &&
                                                                <AudioPlayer audioUrl={interviewAttempts[questionIndex].audiolink} />
                                                            }
                                                        </div>
                                                        <Evaluations interviewAttempts={interviewAttempts} questionIndex={questionIndex} />
                                                    </div>
                                            }
                                        </div>
                                        <ActionWrapper>
                                            <div className="step-action-wrap pb-20">
                                                <div className="step-action-inner">
                                                    <div className="steps-action">

                                                        <Button className="btn-prev" type="white" outlined style={{ color: '#555', marginRight: '10px' }} onClick={handlePrevQuestion} disabled={questionIndex === 0}>
                                                            <UilArrowLeft />
                                                            Previous
                                                        </Button>
                                                        {questionIndex < (interviewAttempts.length !== 0 && interviewAttempts.length - 1) && (
                                                            <Button
                                                                className="btn-next"
                                                                type="primary"
                                                                onClick={handleNextQuestion}
                                                            >
                                                                Next
                                                                <UilArrowRight />
                                                            </Button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </ActionWrapper>
                                    </div>
                                </div>
                    }
                </Modal>

            </Main>
        </div>
    )
}


const Evaluations = ({ interviewAttempts, questionIndex }) => {
    return (
        <div>
            {
                (interviewAttempts[questionIndex].evaluationfeedback1 == null && interviewAttempts[questionIndex].evaluationfeedback2 == null && interviewAttempts[questionIndex].evaluationfeedback3 == null) ?
                    <div style={{ background: '#fafafa', borderRadius: '6px', padding: '10px' }}>
                        <h4 className='text-center text-danger'>Evaluations not found</h4>
                    </div>
                    :
                    <div style={{ background: '#fafafa', borderRadius: '6px', padding: '10px' }}>
                        <h4 className='mt-10 d-flex justify-content-end'>Question Best score {interviewAttempts[questionIndex].avgscore}</h4>
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={1} />
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={2} />
                        <CollapseComponent QObj={interviewAttempts[questionIndex]} index={3} />
                    </div>
            }
        </div>
    )
}

const CollapseComponent = ({ QObj, index }) => {
    const { Panel } = Collapse;
    let key = `evaluationfeedback${(index.toString())}`
    let scoreKey = `evaluationscore${(index.toString())}`
    return (
        <div>
            <Collapse defaultActiveKey={['1']} bordered={false} expandIcon={({ isActive }) => (isActive ? <UilMinus /> : <UilPlus />)} className='m-0 mb-2' >
                <Panel header={<div className='d-flex justify-content-between'>
                    <p className='m-0'>Evaluation Feedback {index}</p><p className='fontsize-13'>{QObj[key] !== null && "Score: " + QObj[scoreKey]}</p></div>}>
                    <p style={{ textAlign: 'justify', paddingBottom: '15px' }} className='m-0'>
                        {
                            QObj[key] === null ?
                                'NA'
                                :
                                QObj[key]
                        }
                    </p>
                </Panel>
            </Collapse>
        </div>
    );
}