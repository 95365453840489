import actions from './actions';

const {
  ASSISTANTS_ADD_BEGIN,
  ASSISTANTS_ADD_SUCCESS,
  ASSISTANTS_ADD_ERR,
  
  ASSISTANTS_BULK_ADD_BEGIN,
  ASSISTANTS_BULK_ADD_SUCCESS,
  ASSISTANTS_BULK_ADD_ERR,

  ASSISTANTS_READ_BEGIN,
  ASSISTANTS_READ_SUCCESS,
  ASSISTANTS_READ_ERR,

  ASSISTANTS_UPDATE_BEGIN,
  ASSISTANTS_UPDATE_SUCCESS,
  ASSISTANTS_UPDATE_ERR,

  ASSISTANTS_BULK_UPDATE_BEGIN,
  ASSISTANTS_BULK_UPDATE_SUCCESS,
  ASSISTANTS_BULK_UPDATE_ERR,

  ASSISTANTS_UPDATE_EXISTING_BEGIN,
  ASSISTANTS_UPDATE_EXISTING_SUCCESS,
  ASSISTANTS_UPDATE_EXISTING_ERR,
} = actions;

const initialState = {
  data: [],  
  loading: false,
  error: null,
};

const addUpdateinitialState = {
  data: null,  
  loading: false,
  error: null,
};

const updateBulkState = {
  data: null,  
  loading: false,
  error: null,
};

const addinitialState = {
  data: null,  
  loading: false,
  error: null,
};

const bulkAddinitialState = {
  data: null,  
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const AssistantsReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case ASSISTANTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ADDNEWAssistantsUpdateExistingReducer = (state = addUpdateinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ASSISTANTS_UPDATE_EXISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_UPDATE_EXISTING_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_UPDATE_EXISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const AssistantsBulkUpdateReducer = (state = updateBulkState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case ASSISTANTS_BULK_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_BULK_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const AssistantsAddReducer = (state = addinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
  
    case ASSISTANTS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const AssistantsBulkAddReducer = (state = bulkAddinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
  
    case ASSISTANTS_BULK_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_BULK_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_BULK_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const AssistantsUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case ASSISTANTS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ASSISTANTS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ASSISTANTS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { AssistantsReducer,AssistantsUpdateReducer , AssistantsAddReducer, AssistantsBulkAddReducer, ADDNEWAssistantsUpdateExistingReducer, AssistantsBulkUpdateReducer};
