import {
  UilUsersAlt,
  UilBookOpen,
  UilBag,
  UilAward,
  UilBookAlt,
  UilUserMd,
  UilApps,
  UilTable,
  UilWrench,
  UilCheck,
  UilUniversity,
  UilMeetingBoard
} from '@iconscout/react-unicons';
import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t('Dashboard')}
      </NavLink>,
      'dashboard', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilApps />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/colleges`}>
        {t('Colleges')}
      </NavLink>,
      'colleges', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/colleges`}>
          <UilUniversity />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/fs`}>
        {t('FS')}
      </NavLink>,
      'fs', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/fs`}>
          <UilBag />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/batches`}>
        {t('Batches')}
      </NavLink>, 'batches', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/batches`}>
          <UilTable />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/subjects`}>
        {t('Subjects')}
      </NavLink>, 'subjects', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/subjects`}>
          <UilBookAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/topics`}>
        {t('Topics')}
      </NavLink>, 'topics', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/topics`}>
          <UilBookOpen />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/teachers`}>
        {t('Teachers')}
      </NavLink>, 'teachers', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/teachers`}>
          <UilUserMd />
        </NavLink>
      )
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/assistants`}>
        {t('Assistants')}
      </NavLink>, 'assistants', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/assistants`}>
          <UilUserMd />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/interviewers`}>
        {t('Interviewers')}
      </NavLink>, 'interviewers', !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/interviewers`}>
          <UilUserMd />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/students`}>
        {t('Students')}
      </NavLink>,
      'students',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/students`}>
          <UilUsersAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/academic_students`}>
        {t('Academic Students')}
      </NavLink>,
      'academic_students',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/academic_students`}>
          <UilUsersAlt />
        </NavLink>
      )
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/badges`}>
        {t('Badges')}
      </NavLink>,
      'badges',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/badges`}>
          <UilAward />
        </NavLink>
      )
    ),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/interviews`}>
    //     {t('Interviews')}
    //   </NavLink>,
    //   'interviews',
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/interviews`}>
    //       <UilMeetingBoard />
    //     </NavLink>
    //   ),
    //   null,
    // ),


    getItem(t('Prashn Manch'), 'Prashn Manch', !topMenu && <UilMeetingBoard />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews/academic`}>
          {t('Academic')}
        </NavLink>,
        'academic',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/interviews/finishingschool`}>
          {t('Finishing School')}
        </NavLink>,
        'finishingschool',
        null,
      ),
    ]),

    getItem(t('Attendance'), 'Attendance', !topMenu && <UilCheck />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/attendance/summary`}>
          {t('Summary')}
        </NavLink>,
        'summary',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/attendance/batchwise`}>
          {t('Batch Wise')}
        </NavLink>,
        'batchwise',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/attendance/consolidated`}>
          {t('Consolidated')}
        </NavLink>,
        'consolidated',
        null,
      ),
    ]),

    getItem(t('Operations'), 'Operations', !topMenu && <UilWrench />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/resetpassword`}>
          {t('Reset Password')}
        </NavLink>,
        'resetpassword',
        null,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/notice`}>
          {t('Notice')}
        </NavLink>,
        'notice',
        null,
      ),
    ]),
  ];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'dashboard' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
