import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';


const {
  readSubjectsBegin,
  readSubjectsSuccess,
  readSubjectsErr,
  readUnitsBegin,
  readUnitsSuccess,
  readUnitsErr
} = actions;


const studentSubjectsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(readSubjectsBegin());
      const query = await DataService.get(`/studentmaster/get-subjects-by-id/1`);
      const token = Cookies.get('access_token');            
      if (token) {
        await dispatch(readSubjectsSuccess(query.data));
      } else {
        await dispatch(readSubjectsSuccess([]));        
      }
    } catch (err) {
      await dispatch(readSubjectsErr(err));
    }
  };
};


const subjectUnitsDataRead = (subjectId) => {
  return async (dispatch) => {
    try {
      await dispatch(readUnitsBegin());
      const query = await DataService.get(`/studentmaster/get-subject-units/${subjectId}`);
      const token = Cookies.get('access_token');      
      if (token) {
        await dispatch(readUnitsSuccess(query.data));
      } else {
        await dispatch(readUnitsSuccess([]));        
      }
    } catch (err) {
      await dispatch(readUnitsErr(err));
    }
  };
};



export {
  studentSubjectsDataRead,
  subjectUnitsDataRead
};
