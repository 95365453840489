import actions from './actions';

const {
  TEACHERS_ADD_BEGIN,
  TEACHERS_ADD_SUCCESS,
  TEACHERS_ADD_ERR,

  TEACHERS_BULK_UPLOAD_BEGIN,
  TEACHERS_BULK_UPLOAD_SUCCESS,
  TEACHERS_BULK_UPLOAD_ERR,

  TEACHERS_READ_BEGIN,
  TEACHERS_READ_SUCCESS,
  TEACHERS_READ_ERR,

  TEACHERS_UPDATE_BEGIN,
  TEACHERS_UPDATE_SUCCESS,
  TEACHERS_UPDATE_ERR,

  TEACHERS_BULK_UPDATE_BEGIN,
  TEACHERS_BULK_UPDATE_SUCCESS,
  TEACHERS_BULK_UPDATE_ERR,

  ADD_NEW_TEACHERS_UPDATE_EXISTING_BEGIN,
  ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS,
  ADD_NEW_TEACHERS_UPDATE_EXISTING_ERR,
} = actions;

const initialState = {
  data: [],    
  loading: false,
  error: null,
};

const initialBulkUploadState = {
  data: null,    
  loading: false,
  error: null,
};

const updateStateSingle = {
  data: null,
  loading: false,
  error: null,
};

const addUpdateinitialState = {
  data: null,
  loading: false,
  error: null,
};

const bulkupdateState = {
  data: null,
  loading: false,
  error: null,
};

const addinitialState = {
  data: null,
  loading: false,
  error: null,
};

const TEACHERSReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {

    case TEACHERS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const ADDNEWTEACHERSUpdateExistingReducer = (state = addUpdateinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_NEW_TEACHERS_UPDATE_EXISTING_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case ADD_NEW_TEACHERS_UPDATE_EXISTING_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case ADD_NEW_TEACHERS_UPDATE_EXISTING_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TEACHERSBulkUpdateReducer = (state = bulkupdateState, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case TEACHERS_BULK_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERS_BULK_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERS_BULK_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const TEACHERSAddReducer = (state = addinitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
  
    case TEACHERS_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERS_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERS_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const TEACHERSBulkAddReducer = (state = initialBulkUploadState, action) => {
  const { type, data, err } = action;
  switch (type) {
  
    case TEACHERS_BULK_UPLOAD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERS_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERS_BULK_UPLOAD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };


    default:
      return state;
  }
};

const TEACHERSUpdateReducer = (state = updateStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    
    case TEACHERS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TEACHERS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TEACHERS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TEACHERSReducer,TEACHERSUpdateReducer , TEACHERSAddReducer, TEACHERSBulkAddReducer, ADDNEWTEACHERSUpdateExistingReducer, TEACHERSBulkUpdateReducer};
