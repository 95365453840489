const actions = {
  FS_ADD_BEGIN: 'FS_ADD_BEGIN',
  FS_ADD_SUCCESS: 'FS_ADD_SUCCESS',
  FS_ADD_ERR: 'FS_ADD_ERR',

  FS_READ_BEGIN: 'FS_READ_BEGIN',
  FS_READ_SUCCESS: 'FS_READ_SUCCESS',
  FS_READ_ERR: 'FS_READ_ERR',

  FS_UPDATE_BEGIN: 'FS_UPDATE_BEGIN',
  FS_UPDATE_SUCCESS: 'FS_UPDATE_SUCCESS',
  FS_UPDATE_ERR: 'FS_UPDATE_ERR',

  fsAddBegin: () => {
    return {
      type: actions.FS_ADD_BEGIN,
    };
  },

  fsAddSuccess: (data) => {
    return {
      type: actions.FS_ADD_SUCCESS,
      data,
    };
  },

  fsAddErr: (err) => {
    return {
      type: actions.FS_ADD_ERR,
      err,
    };
  },

  fsReadBegin: () => {
    return {
      type: actions.FS_READ_BEGIN,
    };
  },

  fsReadSuccess: (data) => {
    return {
      type: actions.FS_READ_SUCCESS,
      data,
    };
  },

  fsReadErr: (err) => {
    return {
      type: actions.FS_READ_ERR,
      err,
    };
  },

  fsUpdateBegin: () => {
    return {
      type: actions.FS_UPDATE_BEGIN,
    };
  },

  fsUpdateSuccess: (data) => {
    return {
      type: actions.FS_UPDATE_SUCCESS,
      data,
    };
  },

  fsUpdateErr: (err) => {
    return {
      type: actions.FS_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
