const actions = {

  SUBJECT_UNITS_READ_BEGIN: 'SUBJECT_UNITS_READ_BEGIN',
  SUBJECT_UNITS_READ_SUCCESS: 'SUBJECT_UNITS_READ_SUCCESS',
  SUBJECT_UNITS_READ_ERR: 'SUBJECT_UNITS_READ_ERR',

  SUBJECT_READ_BEGIN : 'SUBJECT_READ_BEGIN',
  SUBJECT_READ_SUCCESS : 'SUBJECT_READ_SUCCESS',
  SUBJECT_READ_ERR : 'SUBJECT_READ_ERR',

  UNIT_READ_BEGIN : 'UNIT_READ_BEGIN',
  UNIT_READ_SUCCESS : 'UNIT_READ_SUCCESS',
  UNIT_READ_ERR : 'UNIT_READ_ERR',


  subjectsUnitsReadBegin: () => {
    return {
      type: actions.SUBJECT_UNITS_READ_BEGIN,
    };
  },

  subjectsUnitsReadSuccess: (data) => {    
    return {
      type: actions.SUBJECT_UNITS_READ_SUCCESS,      
      data,
    };
  },

  subjectsUnitsReadErr: (err) => {
    return {
      type: actions.SUBJECT_UNITS_READ_ERR,
      err,
    };
  },

  subjectReadBegin: () => {
    return {
      type: actions.SUBJECT_READ_BEGIN,
    };
  },

  subjectReadSuccess: (data) => {    
    return {
      type: actions.SUBJECT_READ_SUCCESS,      
      data,
    };
  },

  subjectReadErr: (err) => {
    return {
      type: actions.SUBJECT_READ_ERR,
      err,
    };
  },

  unitReadBegin: () => {
    return {
      type: actions.UNIT_READ_BEGIN,
    };
  },

  unitReadSuccess: (data) => {    
    return {
      type: actions.UNIT_READ_SUCCESS,      
      data,
    };
  },

  unitReadErr: (err) => {
    return {
      type: actions.UNIT_READ_ERR,
      err,
    };
  },

};

export default actions;