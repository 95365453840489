import {    
    UilArrowGrowth,    
    UilWindowSection,
  } from '@iconscout/react-unicons';
  import { Menu } from 'antd';
  import React, { useEffect } from 'react';
  import { useTranslation } from 'react-i18next';
  import { useDispatch, useSelector } from 'react-redux';
  import { NavLink } from 'react-router-dom';
  
  import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
  import propTypes from 'prop-types';
  import { NavTitle } from './Style';
  import { changeDirectionMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
  import { subjectsUnitsDataRead } from '../redux/teacherSubjectsUnits/actionCreator';
  import Cookies from 'js-cookie';
  
  const userId = Cookies.get('userId')
  
  function MenuItems({ toggleCollapsed }) {
    const { t } = useTranslation();
  
    function getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
  
    const { topMenu } = useSelector((state) => {
      return {
        topMenu: state.ChangeLayoutMode.topMenu,
      };
    });
  
    const dispatch = useDispatch();
  
    const path = '/student';
  
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split('/');
  
    const [openKeys, setOpenKeys] = React.useState(
      !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
    );
  
    const onOpenChange = (keys) => {
      setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
    };
  
    const onClick = (item) => {
      if (item.keyPath.length === 1) setOpenKeys([]);
    };
  
    const changeLayout = (mode) => {
      dispatch(changeLayoutMode(mode));
    };
    const changeNavbar = (topMode) => {
      const html = document.querySelector('html');
      if (topMode) {
        html.classList.add('ninjadash-topmenu');
      } else {
        html.classList.remove('ninjadash-topmenu');
      }
      dispatch(changeMenuMode(topMode));
    };
    const changeLayoutDirection = (rtlMode) => {
      if (rtlMode) {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'rtl');
      } else {
        const html = document.querySelector('html');
        html.setAttribute('dir', 'ltr');
      }
      dispatch(changeDirectionMode(rtlMode));
    };
  
    const darkmodeActivated = () => {
      document.body.classList.add('dark-mode');
    };
  
    const darkmodeDiactivated = () => {
      document.body.classList.remove('dark-mode');
    };
  
    useEffect(() => {
      if (dispatch) {
        dispatch(subjectsUnitsDataRead(userId));
      }
    }, [dispatch]);
  
    const { SubjectsUnits, isLoading } = useSelector((state) => {
      return {
        SubjectsUnits: state.SubjectsUnits.data,
        isLoading: state.SubjectsUnits.loading,
      };
    });
  
    // SubjectsUnits.units.length !== 0 && SubjectsUnits.units.map((unitObj,unitIndex)=>
    //         getItem(
    //           <NavLink onClick={toggleCollapsed} to={`${path}`}>
    //             {unitObj.name}
    //           </NavLink>,
    //           unitObj.name,
    //           null,
    //         ),
    //         ) 
  
    const items = [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
          Dashboard
        </NavLink>,
        'dashboard',
        !topMenu && <UilArrowGrowth />,
      ),
      // getItem(t('Subjects'), 'subjects', !topMenu && <UilCreateDashboard />,
      // SubjectsUnits.length !== 0 && SubjectsUnits.map((subjectObj,subjectIndex)=>
      //     getItem(
      //       <NavLink to='#'>
      //         {subjectObj.course}
      //       </NavLink>,
      //       subjectObj.id,
      //       null,  
      //       subjectObj.units.length !== 0 && subjectObj.units.map((unitObj,unitIndex)=>
      //       getItem(
      //         <NavLink to={`${path}`}>
      //           {unitObj.name}
      //         </NavLink>,
      //         unitObj.name,
      //         null,
      //       ),
      //       )              
      //     ), 
      //   )                    
      // ),        
      getItem(t('layouts'), 'layout', !topMenu && <UilWindowSection />, [
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              darkmodeDiactivated();
              changeLayout('lightMode');
            }}
            to="#"
          >
            {t('light')} {t('mode')}
          </NavLink>,
          'light',
          null,
        ),
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              darkmodeActivated();
              changeLayout('darkMode');
            }}
            to="#"
          >
            {t('dark')} {t('mode')}
          </NavLink>,
          'dark',
          null,
        ),
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              changeNavbar(true);
            }}
            to="#"
          >
            {t('top')} {t('menu')}
          </NavLink>,
          'topMenu',
          null,
        ),
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              changeNavbar(false);
            }}
            to="#"
          >
            {t('side')} {t('menu')}
          </NavLink>,
          'sideMenu',
          null,
        ),
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              changeLayoutDirection(true);
            }}
            to="#"
          >
            RTL
          </NavLink>,
          'rtl',
          null,
        ),
        getItem(
          <NavLink
            onClick={() => {
              toggleCollapsed();
              changeLayoutDirection(false);
            }}
            to="#"
          >
            LTR
          </NavLink>,
          'ltr',
          null,
        ),
      ]),
      //   SubjectsUnits.length !== 0 && SubjectsUnits.map((subjectObj,subjectIndex)=>      
      //     getItem(
      //       <NavLink onClick={toggleCollapsed} to={`${path}/demo-2`}>
      //         {t('demo')} {t('2')}
      //       </NavLink>,
      //       'demo-2',
      //       null,
      //     ),
      //     // getItem('subjects', 'subject', !topMenu && <UilCreateDashboard />,[
      //       // subjectObj.units.length !== 0 && subjectObj.units.map((unitObj,unitIndex)=>
      //       //     getItem(
      //       //       <NavLink onClick={toggleCollapsed} to={`${path}`}>
      //       //         {unitObj.name}
      //       //       </NavLink>,
      //       //       unitObj.id,
      //       //       null,               
      //       //     ), 
      //       // )
      //     // ])                 
      // ),               
    ];
  
    return (
      <Menu
        onOpenChange={onOpenChange}
        onClick={onClick}
        mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
        // // eslint-disable-next-line no-nested-ternary
        defaultSelectedKeys={
          !topMenu
            ? [
              `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
            : []
        }
        defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
        overflowedIndicator={<UilEllipsisV />}
        openKeys={openKeys}
        items={items}
      />
    );
  }
  
  MenuItems.propTypes = {
    toggleCollapsed: propTypes.func,
  };
  
  export default MenuItems;
  