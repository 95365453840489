import React, { useEffect } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useDispatch, useSelector } from 'react-redux';
import Summary from './DashboardOverview/Summary';
import { GetOverallSummary, ScheduleWiseSummary } from '../../redux/interview/actionCreator';
import { Cards } from '../../components/cards/frame/cards-frame';
import { Skeleton } from 'antd';
import InterviewOverallReport from './DashboardOverview/InterviewOverallReport';


const Dashboard = () => {

  const dispatch = useDispatch()

  const { overallSummary, overallSummaryLoading, scheduleWiseSummary, scheduleWiseSummaryLoading } = useSelector((state) => {
    return {
      overallSummary: state.overallSummary.data,
      overallSummaryLoading: state.overallSummary.loading,            
      scheduleWiseSummary: state.scheduleWiseSummary.data,
      scheduleWiseSummaryLoading: state.scheduleWiseSummary.loading,            
    }
  })

  useEffect(() => {
    dispatch(GetOverallSummary())    
    dispatch(ScheduleWiseSummary())    
  }, [])

  return (
    <div>
      <PageHeader className="ninjadash-page-header-main" title={`Dashboard`} />
      <Main>
        <div>
          <h3 className='mt-20 add-line'>Overall Summary &nbsp;</h3>
          {
            overallSummaryLoading ?
              <Cards headless>
                <Skeleton active />
              </Cards>
              :
              <Summary overallSummary={overallSummary} />
          }
        </div>
        <div>
          <h3 className='mt-20 add-line'>Schedule wise report &nbsp;</h3>
          {
            scheduleWiseSummaryLoading ?
              <Cards headless>
                <Skeleton active />
              </Cards>
              :
              <InterviewOverallReport summaryData={scheduleWiseSummary} />
          }
        </div>
      </Main>
    </div>
  );
};

export default Dashboard;