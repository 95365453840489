const actions = {
  BATCHES_ADD_BEGIN: 'BATCHES_ADD_BEGIN',
  BATCHES_ADD_SUCCESS: 'BATCHES_ADD_SUCCESS',
  BATCHES_ADD_ERR: 'BATCHES_ADD_ERR',

  BATCHES_READ_BEGIN: 'BATCHES_READ_BEGIN',
  BATCHES_READ_SUCCESS: 'BATCHES_READ_SUCCESS',
  BATCHES_READ_ERR: 'BATCHES_READ_ERR',

  BATCHES_UPDATE_BEGIN: 'BATCHES_UPDATE_BEGIN',
  BATCHES_UPDATE_SUCCESS: 'BATCHES_UPDATE_SUCCESS',
  BATCHES_UPDATE_ERR: 'BATCHES_UPDATE_ERR',

  batchesAddBegin: () => {
    return {
      type: actions.BATCHES_ADD_BEGIN,
    };
  },

  batchesAddSuccess: (data) => {
    return {
      type: actions.BATCHES_ADD_SUCCESS,
      data,
    };
  },

  batchesAddErr: (err) => {
    return {
      type: actions.BATCHES_ADD_ERR,
      err,
    };
  },

  batchesReadBegin: () => {
    return {
      type: actions.BATCHES_READ_BEGIN,
    };
  },

  batchesReadSuccess: (data) => {
    return {
      type: actions.BATCHES_READ_SUCCESS,
      data,
    };
  },

  batchesReadErr: (err) => {
    return {
      type: actions.BATCHES_READ_ERR,
      err,
    };
  },

  batchesUpdateBegin: () => {
    return {
      type: actions.BATCHES_UPDATE_BEGIN,
    };
  },

  batchesUpdateSuccess: (data) => {
    return {
      type: actions.BATCHES_UPDATE_SUCCESS,
      data,
    };
  },

  batchesUpdateErr: (err) => {
    return {
      type: actions.BATCHES_UPDATE_ERR,
      err,
    };
  },
};

export default actions;
