import { notification } from 'antd';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { logOut } from '../authentication/actionCreator';
import { getItem } from '../../utility/localStorageControl';

const addNotificationSuccess = () => {
  notification.success({
    message: 'A new subject has been Added',
  });
};

const addNotificationError = (err) => {
  const errorMessage = `Adding new subject has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const updateNotificationSuccess = () => {
  notification.success({
    message: 'Subject Record has been updated',
  });
};

const updateNotificationError = (err) => {
  const errorMessage = `Updating subject has been failed(${err})`;
  notification.error({
    message: errorMessage,
    duration: 0,
  });
};

const {
  subjectsAddBegin,
  subjectsAddSuccess,
  subjectsAddErr,

  subjectsReadBegin,
  subjectsReadSuccess,
  subjectsReadErr,

  subjectsUpdateBegin,
  subjectsUpdateSuccess,
  subjectsUpdateErr,
} = actions;

const subjectsDataSubmit = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsAddBegin());
      const response = await DataService.post('/subjectmaster/create', data);
      const token = getItem('access_token');
      if (token && response.data.Error === false) {
        await dispatch(subjectsAddSuccess(response.data));
        addNotificationSuccess();
      } else {
        if ( response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut());
        }
        throw response.data.message;
        // await dispatch(subjectsAddErr('No Unauthorize access'));
        // addNotificationError('No Unauthorize access');
      }
    } catch (err) {
      await dispatch(subjectsAddErr(err));
      addNotificationError(err);
    }
  };
};

const subjectsDataRead = () => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsReadBegin());
      const activeFSid = getItem('activeFSid');
      const query = await DataService.get(`/subjectmaster/all/${activeFSid}`);
      const token = getItem('access_token');
      if (token && query.data !== null && query.data.Error === false) {
        await dispatch(subjectsReadSuccess(query.data.payload));
      } else {
        if ( query.data.Error === true && query.data.statusCode === 401){
          notification.error({ message: query.data.message })
          dispatch(logOut());
        }
        throw query.data.message;
        // await dispatch(subjectsReadSuccess([]));
        // await dispatch(subjectsAddErr('No Unauthorize access'));
      }
    } catch (err) {
      await dispatch(subjectsReadErr(err));
    }
  };
};

const subjectsDataUpdate = (data) => {
  return async (dispatch) => {
    try {
      await dispatch(subjectsUpdateBegin());
      const response = await DataService.put(`/subjectmaster/update`, data);
      const token = getItem('access_token');
      if(token && response.data.Error === false){
        await dispatch(subjectsUpdateSuccess(response.data));
        updateNotificationSuccess();
      }else{
        if (response.data.Error === true && response.data.statusCode === 401){
          notification.error({ message: response.data.message })
          dispatch(logOut())
        }
        throw response.data.message;
        // await dispatch(subjectsDataUpdate('No Unauthorize access'))
      }
    } catch (err) {
      await dispatch(subjectsUpdateErr(err));
      updateNotificationError(err);
    }
  };
};

export {
  subjectsDataRead,
  subjectsDataSubmit,
  subjectsDataUpdate,
};
