import React, { useEffect, useRef, useState } from 'react'
import { PageHeader } from '../../components/page-headers/page-headers'
import { Main, TableWrapper } from '../styled'
import { Button, Col, Collapse, Modal, Result, Row, Select, Table, Tooltip, message } from 'antd'
import { ProjectList, ProjectSorting } from '../project/style'
import { AutoComplete } from '../../components/autoComplete/autoComplete'
import { useDispatch, useSelector } from 'react-redux'
import { Cards } from '../../components/cards/frame/cards-frame'
import { getScheduledData } from '../../redux/interview/actionCreator'
import actions from '../../redux/interview/actions'
import Interviews from './Interviews'

const { getInterviewAttemptsSuccess, getInterviewByScheduleSuccess } = actions

export default function FinishingSchoolInterviews() {

    const dispatch = useDispatch();
    const { interviewsBySchedule, isinterviewsByScheduleLoading, interviewAttempts, isInterviewAttemptsLoading, TopicScheduledData, isSchedulesLoading } = useSelector((state) => {
        return {
            interviewsBySchedule: state.interviewsBySchedule.data,
            isinterviewsByScheduleLoading: state.interviewsBySchedule.loading,
            interviewAttempts: state.interviewAttempts.data,
            isInterviewAttemptsLoading: state.interviewAttempts.loading,
            TopicScheduledData: state.TopicScheduledData.data,
            isSchedulesLoading: state.TopicScheduledData.loading,
        };
    });

    useEffect(() => {
        if (dispatch) {
            dispatch(getInterviewByScheduleSuccess([]))
            dispatch(getInterviewAttemptsSuccess([]))            
            dispatch(getScheduledData())
        }
    }, [dispatch]);



    return (
        <div>
            <Interviews
                TopicScheduledData={TopicScheduledData}
                isSchedulesLoading={isSchedulesLoading}
                interviewsBySchedule={interviewsBySchedule}
                isInterviewsByScheduleLoading={isinterviewsByScheduleLoading}
                interviewAttempts={interviewAttempts}
                isInterviewAttemptsLoading={isInterviewAttemptsLoading}
                pageTitle={'Finishingschool Interviews'}
            />
        </div>
    )
}