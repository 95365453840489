import React from 'react';
import { Main } from '../styled';
import { NoteNav, NoteWrapper } from '../note/style';
import { Col, Row, Spin } from 'antd';
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilAngleLeftB from '@iconscout/react-unicons/icons/uil-angle-left-b';
import UilAngleRightB from '@iconscout/react-unicons/icons/uil-angle-right-b';
import UilPlayCircle from '@iconscout/react-unicons/icons/uil-play-circle';
import UilBookOpen from '@iconscout/react-unicons/icons/uil-book-open';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import { Suspense } from 'react';
import ContentPage from './ContentPage'
import VideoPage from './VideoPage'
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectById } from '../../redux/teacherSubjectsUnits/actionCreator';
import { useEffect } from 'react';
import { getTopicsByUnitId } from '../../redux/topics/actionCreator';
import Swiper from 'react-id-swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { TestimonialStyleWrapper } from '../pages/style'

import 'swiper/scss';
import 'swiper/scss/pagination';
import Cookies from 'js-cookie';
SwiperCore.use([Navigation, Pagination]);
function TopicContent() {

    const { subjectId, unitId, topicId } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedActiveTopic, setSelectedActiveTopic] = useState(-1)

    const { SubjectInfo, subjectLoading, Topics, topicsLoading } = useSelector((state) => {
        return {
            SubjectInfo: state.Subject.data,
            subjectLoading: state.Subject.loading,
            Topics: state.UnitTopics.data,
            topicsLoading: state.UnitTopics.loading,
        };
    });


    let activeTopic = Cookies.get('activestudenttopic')

    useEffect(() => {
        if (activeTopic !== undefined) {
            setSelectedActiveTopic(activeTopic)
        }
    }, [activeTopic])

    // console.log(Topics !== null && Topics.topics.length !== 0 && Topics.topics)

    // let index = Topics !== null && Topics.topics.length !== 0 && Topics.topics.findIndex(x => x.id === parseInt(topicId));    

    useEffect(() => {
        dispatch(getSubjectById(subjectId))
        dispatch(getTopicsByUnitId(unitId))
    }, [dispatch, subjectId])

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    const gotoPreviousTopic = () => {
        if(selectedActiveTopic !== 0)
        {
            Cookies.set('activestudenttopic', selectedActiveTopic-1)            
            setSelectedActiveTopic(selectedActiveTopic-1)
        }
    }
    
    const gotoNextTopic = () => {   
        const length = Topics !== 0 && Topics.topics.length !== 0 && Topics.topics.length                   
        if(selectedActiveTopic !== length-1)
        {
            Cookies.set('activestudenttopic', parseInt(selectedActiveTopic)+1)            
            setSelectedActiveTopic(parseInt(selectedActiveTopic)+1)            
        }
    }    

    return (
        <div>
            <Main className='mt-30'>               
                <NoteWrapper>
                    {
                        subjectLoading || topicsLoading ?
                            <div className='spin'>
                                <Spin />
                            </div>
                            :
                            <Row gutter={25}>
                                <Col xxl={6} lg={8} md={10} xs={24}>
                                    {responsive <= 767 && (
                                        <div className="sidebar-trier-wrap text-center">
                                            <Button type="link" className="sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                                                {collapsed ? <UilAngleLeft /> : <UilAngleRight />}
                                            </Button>
                                        </div>
                                    )}
                                    {responsive > 767 ? (
                                        <div className="sidebar-card">
                                            <Cards title={SubjectInfo !== null ? SubjectInfo.name : '{Subject name not loaded}'} className='text-center'>
                                                <div className='d-flex justify-content-between align-center-v' style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                                                    {
                                                        selectedActiveTopic > 0 ?
                                                        <div className='cursor-pointer' onClick={()=>{gotoPreviousTopic()}}><UilAngleLeftB /></div>
                                                        :
                                                        <div style={{width:'10px'}}></div>
                                                    }
                                                    {
                                                        selectedActiveTopic >= 0 &&
                                                        <div>
                                                            <span>Subject:<Link to={`/student/subject/${subjectId}`} style={{ color: '#666D92' }} className='underline-hover'>{SubjectInfo !== null && SubjectInfo.name}</Link>{' > '}{Topics !== null ? Topics.name : ''}</span>
                                                            <h3 className='text-center m-0' style={{ width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{Topics !== null && Topics.topics.length !== 0 && Topics.topics[selectedActiveTopic].name}</h3>
                                                        </div>
                                                    }
                                                    {
                                                        selectedActiveTopic !== (Topics !== null && Topics.topics.length !== 0 && Topics.topics.length-1) ?
                                                        <div className='cursor-pointer' onClick={()=>{gotoNextTopic()}}><UilAngleRightB /></div>                                                        
                                                        :
                                                        <div style={{width:'10px'}}></div>
                                                    }
                                                </div>
                                                <div className="note-sidebar-bottom">
                                                    <NoteNav>
                                                        <ul className="ninjadash-taskApp-sidebar__nav">
                                                            <li>
                                                                <NavLink to='content' style={{ lineHeight: '40px', fontSize: '18px' }}>
                                                                    <UilBookOpen /> Content
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to='video' style={{ lineHeight: '40px', fontSize: '18px' }}>
                                                                    <UilPlayCircle /> Video
                                                                </NavLink>
                                                            </li>
                                                        </ul>
                                                    </NoteNav>
                                                </div>                                                
                                            </Cards>
                                        </div>
                                    ) : (
                                        <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                            <Link to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                <UilTimes />
                                            </Link>
                                            <SidebarWrap className="mb-30">
                                                <div className="ninjadash-taskApp-sidebar">
                                                    <Heading as='h3' className='text-center'>Topics</Heading>
                                                    <ul className="ninjadash-taskApp-sidebar__nav">

                                                    </ul>
                                                </div>
                                            </SidebarWrap>
                                        </FixedSidebar>
                                    )}
                                </Col>
                                <Col xxl={18} lg={16} md={14} xs={24}>
                                    <Suspense
                                        fallback={
                                            <div className="spin">
                                                <Spin />
                                            </div>
                                        }
                                    >
                                        <Routes>
                                            {/* <Route path="/" element={<Navigate to={`/student/subjects/subject/${subjectId}/unit/${unitId}/topic/${topicId}/upload/file`} replace={true} />} /> */}
                                            <Route path="/" element={<Navigate to={`content`} replace={true} />} />
                                            <Route path="content" element={<ContentPage />} />
                                            <Route path="video" element={<VideoPage />} />
                                        </Routes>
                                    </Suspense>
                                </Col>
                            </Row>
                    }
                </NoteWrapper>
            </Main>
        </div>
    );
}

export default TopicContent;