/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Input, Modal, Form, Select, message, Space, Checkbox } from 'antd';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import { DownloadOutlined } from '@ant-design/icons';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'; // Import icons
import { Cards } from '../../components/cards/frame/cards-frame';
import { TableWrapper, Main } from '../styled';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useDispatch, useSelector } from 'react-redux';
import { assistantsDataRead, assistantsDataSubmit, assistantsDataUpdate, assistantsbulkDataSubmit, assistantsBulkDataUpdate } from '../../redux/assistants/actionCreator';
import { fsDataRead } from '../../redux/fs/actionCreator';
import { ProjectList, ProjectSorting } from '../project/style';
import { AutoComplete } from '../../components/autoComplete/autoComplete';
import { Button } from '../../components/buttons/buttons';
import { subjectsDataRead } from '../../redux/subjects/actionCreator';
import { teacherssubjectsDataRead } from '../../redux/teacherssubjects/actionCreator';
import Papa from 'papaparse';
import { ImportStyleWrap } from '../styled';
import Heading from '../../components/heading/heading';
import Dragger from 'antd/lib/upload/Dragger';
import UilExport from "@iconscout/react-unicons/icons/uil-export";
import CustomSpinner from '../ui-elements/spinner/CustomSpinner';
import { getItem } from '../../utility/localStorageControl';
import { batchesDataRead } from '../../redux/batches/actionCreator';
import { collegeDataRead } from '../../redux/Colleges/actionCreator';

const { Option } = Select;

const sampleCSVData = [
  {
    name: "assistant1",
    username: "sampleassistant1",
    password: "sampleassistant1",
    email: "sampleassistant1@gmail.com",
    batch: "Elite"
  },
  {
    name: "assistant2",
    username: "sampleassistant2",
    email: "sampleassistant2@gmail.com",
    password: "sampleassistant2",
    batch: "FS-A1"

  },
  {
    name: "assistant3",
    username: "sampleassistant3",
    password: "sampleassistant3",
    email: "sampleassistant3@gmail.com",
    batch: "FS-A2"
  },
];


function Assistants() {

  const clgName = getItem('collegeName');
  let ClgName = '(' + clgName + ')';
  const loggedadmin = getItem('username');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [bulkForm] = Form.useForm();

  const [selectedRegulationId, setSelectedRegulationId] = useState(null);
  // const [isSubjectDisabled, setIsSubjectDisabled] = useState(true);
  const [searchText, setsearchText] = useState(null);
  // const [selectedSubjects, setSelectedSubjects] = useState(null);

  const [csvFile, setCSVFile] = useState(null);
  const [csvFileList, setCSVFileList] = useState([]);
  const [parsedCSVData, setParsedCSVData] = useState([]);

  const [updateRecord, setUpdateRecord] = useState(null);

  const dispatch = useDispatch();
  const { batches, assistants, isLoading, AssistantsUpdateRes, isAssistantsUpdating, AssistantsAddRes,
    isAssistantsAdding, AssistantsBulkAddRes, isAssistantsBulkAdding, AssistantsBulkUpdateRes,
    isAssistantsBulkUpdating, AssistantsBulkAddUpdateRes, isAssistantsBulkAddingUpdating, colleges, isCollegesLoading } = useSelector((state) => {
      return {
        batches: state.Batches.data,
        assistants: state.Assistants?.data,
        isLoading: state.Assistants.loading,
        AssistantsUpdateRes: state.AssistantsUpdateRes?.data,
        isAssistantsUpdating: state.AssistantsUpdateRes.loading,
        AssistantsAddRes: state.AssistantsAddRes?.data,
        isAssistantsAdding: state.AssistantsAddRes.loading,
        AssistantsBulkAddRes: state.AssistantsBulkAddRes?.data,
        isAssistantsBulkAdding: state.AssistantsBulkAddRes.loading,
        AssistantsBulkUpdateRes: state.AssistantsBulkUpdateRes?.data,
        isAssistantsBulkUpdating: state.AssistantsBulkUpdateRes.loading,
        AssistantsBulkAddUpdateRes: state.AssistantsBulkAddUpdateRes?.data,
        isAssistantsBulkAddingUpdating: state.AssistantsBulkAddUpdateRes.loading,
        colleges: state.College.data,
        isCollegesLoading: state.College.loading,
      };
    });

  useEffect(() => {
    dispatch(fsDataRead());
    dispatch(subjectsDataRead());
    dispatch(assistantsDataRead());
    dispatch(batchesDataRead());
    if (colleges.length === 0) {
      dispatch(collegeDataRead());
    }
  }, [dispatch]);

  useEffect(() => {
    if (AssistantsUpdateRes !== null) {
      dispatch(assistantsDataRead());
    }
  }, [AssistantsUpdateRes])

  useEffect(() => {
    if (AssistantsBulkAddRes !== null) {
      dispatch(assistantsDataRead());
    }
  }, [AssistantsUpdateRes])

  useEffect(() => {
    if (AssistantsAddRes !== null) {
      dispatch(assistantsDataRead());
    }
  }, [AssistantsAddRes])

  useEffect(() => {
    if (AssistantsBulkUpdateRes !== null) {
      dispatch(assistantsDataRead());
    }
  }, [AssistantsBulkUpdateRes])

  useEffect(() => {
    if (AssistantsBulkAddUpdateRes !== null) {
      dispatch(assistantsDataRead());
    }
  }, [AssistantsBulkAddUpdateRes])

  useEffect(() => {
    if (selectedRegulationId !== null)
      dispatch(teacherssubjectsDataRead(selectedRegulationId));
  }, [dispatch, selectedRegulationId]);

  const [dataSource, setDataSource] = useState([]);
  const [GoodData, setGoodData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isUsernameChecked, setUsernameChecked] = useState(true);
  const [isNameChecked, setNameChecked] = useState(false);
  // const [isSubjectsChecked, setSubjectsChecked] = useState(false);
  const [isBatchesChecked, setBatchesChecked] = useState(false);
  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isPasswordChecked, setPasswordChecked] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleAllCheckboxToggle = () => {
    const allCheckedState = !isAllChecked;
    setIsAllChecked(allCheckedState);
    if (selectedMode === '' && allCheckedState === true) {
      setEmailChecked(true);
      setBatchesChecked(true)
      // setSubjectsChecked(true);
      setNameChecked(true);
      setPasswordChecked(true);
    }
    if (selectedMode === '' && allCheckedState === false) {
      setEmailChecked(false);
      setNameChecked(false);
      setPasswordChecked(false);
      setBatchesChecked(false)

    }
    if ((selectedMode === 'AddnewAssistants' || selectedMode === 'AddNewUpdateExistingAssistants') && allCheckedState === true) {
      setEmailChecked(true);
      // setSubjectsChecked(true);
      setNameChecked(true);
      setBatchesChecked(true)
      setPasswordChecked(true);
      // setSubjectsChecked(true);
    }
    if ((selectedMode === 'AddnewAssistants' || selectedMode === 'AddNewUpdateExistingAssistants') && allCheckedState === false) {
      setEmailChecked(true);
      setNameChecked(true);
      setBatchesChecked(true)
      setPasswordChecked(true);
      // setSubjectsChecked(true);
    }
    if (selectedMode === 'UpdateexistingAssistants' && allCheckedState === false) {
      setEmailChecked(false);
      setBatchesChecked(false)
      setNameChecked(false);
      setPasswordChecked(false);
    }
    if (selectedMode === 'UpdateexistingAssistants' && allCheckedState === true) {
      setEmailChecked(true);
      // setSubjectsChecked(true);
      setNameChecked(true);
      setBatchesChecked(true)
      setPasswordChecked(true);
    }
  };


  const handleModeChange = (value) => {
    const shouldCheckNameAndPassword = value === 'AddnewAssistants' || value === 'AddNewUpdateExistingAssistants';
    setSelectedMode(value);
    setEmailChecked(shouldCheckNameAndPassword);
    setNameChecked(shouldCheckNameAndPassword);
    setPasswordChecked(shouldCheckNameAndPassword);
    // setSubjectsChecked(shouldCheckNameAndPassword);
    setBatchesChecked(shouldCheckNameAndPassword)

  };

  const handleNameCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewAssistants' && selectedMode !== 'AddNewUpdateExistingAssistants') {
      setNameChecked(e.target.checked);
    }
  };

  const handlePasswordCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewAssistants' && selectedMode !== 'AddNewUpdateExistingAssistants') {
      setPasswordChecked(e.target.checked);
    }
  };

  const handleEmailCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewAssistants' && selectedMode !== 'AddNewUpdateExistingAssistants') {
      setEmailChecked(e.target.checked);
    }
  };

  // const handleSubjectsCheckboxChange = (e) => {
  //   if (selectedMode !== 'AddnewAssistants' && selectedMode !== 'AddNewUpdateExistingAssistants') {
  //     setSubjectsChecked(e.target.checked);
  //   }
  // };
  const handleBatchesCheckboxChange = (e) => {
    if (selectedMode !== 'AddnewAssistants' && selectedMode !== 'AddNewUpdateExistingAssistants') {
      setBatchesChecked(e.target.checked);
    }
  };

  useEffect(() => {
    const allCheckboxes = [
      isNameChecked,
      isEmailChecked,
      isPasswordChecked,
      // isSubjectsChecked,
      isBatchesChecked
    ];
    const allChecked = allCheckboxes.every((checkbox) => checkbox === true);
    setIsAllChecked(allChecked);
  }, [isNameChecked, isPasswordChecked, isEmailChecked,
    // isSubjectsChecked
    isBatchesChecked
  ]);


  function formatDateTime(dateTime) {
    const dateObject = dateTime;
    const formattedDate = dateObject?.split('T')[0].replace(/-/g, '/');
    return formattedDate;
  }

  useEffect(() => {
    const filteredData = assistants.length !== 0 ? assistants.map((item, index) => {
      if (item && item.id && item.name && item.usermaster) {
        return {
          key: item.id,
          sno: index + 1,
          id: item.id,
          name: item.name,
          username: item.usermaster.username,
          collegeId: item.usermaster?.college?.id,
          collegecode: item.usermaster?.college?.code,
          batches: item.batch?.name,
          batchId: item.batch?.id,
          // subjects: item.subjects?.map(reg => reg.name).join(', '),
          email: item.usermaster.email ? item.usermaster.email : 'no data',
          updatedby: item.updatedby !== '' ? item.updatedby : '--------',
          updatedon: formatDateTime(item.updatedon),
        };
      }
      return null;
    }) : []
    setDataSource(filteredData);
    setGoodData(filteredData);
    setLoading(false);
  }, [assistants])

  // const handleRegulationChange = (value) => {
  //   setSelectedRegulationId(value);
  //   setIsSubjectDisabled(true);
  // };

  // useEffect(() => {
  //   if (selectedRegulationId ) {
  //     setIsSubjectDisabled(false);
  //   } else {
  //     setIsSubjectDisabled(true);
  //   }
  // }, [selectedRegulationId]);

  const checkSearch = (i) => {
    for (let j in i) {
      if (i[j] != null && i[j].toString().toLowerCase()?.includes(searchText.toString().toLowerCase())) {
        return true;
      }
    }
    return false;

  }

  // const checkSubject=(i)=>{
  //   let tarr = (i.subjects)?.split(",");
  //   let valueInArray=false;
  //   for(let j of tarr){
  //     if(j.trim() === selectedSubjects){
  //       valueInArray=true;
  //       break;
  //     }
  //   }
  //   return valueInArray;
  // }

  useEffect(() => {
    let tempValues = [...GoodData];
    if (searchText != null) {
      tempValues = tempValues?.filter(checkSearch);
    }
    // if(selectedSubjects!=null){
    //   tempValues=tempValues?.filter(checkSubject);
    // }
    setDataSource(tempValues);
  }, [searchText])

  const handleSearch = (e) => {
    setsearchText(e)
  }

  //   const handleSubjectChange = (value) => {
  //     setSelectedSubjects(value);
  //   };

  const handleRemoveCSVFile = () => {
    setCSVFile(null);
    setCSVFileList([])
  }
  const handleCSVFileChange = (uploadedFile) => {
    if (uploadedFile !== null && uploadedFile.fileList.length !== 0) {
      setCSVFileList(uploadedFile.fileList);
      message.success("File Uploaded");
      setTimeout(() => {
        const csvFile = uploadedFile.fileList[0];
        Papa.parse(csvFile.originFileObj, {
          complete: (result) => {
            result?.data.pop();
            const modifiedCSVData = result?.data?.map((row) => {
              if (row.batch) {
                const batchNames = Array.isArray(row.batch) ? row.batch : [row.batch];
                const batchIds = batchNames.map(name => {
                  const found = batches.find(sub => sub.name === row.batch);
                  return found ? found.id : null;
                });
                const batchid = batchIds.find(id => id !== null);
                return {
                  ...row,
                  role: "TEACHER",
                  batchId: batchid !== undefined ? batchid : null,
                };
              } else {
                return row;
              }
            });
            setParsedCSVData(modifiedCSVData);
          },
          header: true,
        });
      }, 1000);
    } else {
      handleRemoveCSVFile();
      message.warning("File Removed");
    }
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'College',
      dataIndex: 'collegecode',
      key: 'collegecode',
    },
    {
      title: 'Batch',
      dataIndex: 'batches',
      key: 'batches',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Updated On',
      dataIndex: 'updatedon',
      key: 'updatedon',
    },
    {
      title: 'Updated By',
      dataIndex: 'updatedby',
      key: 'updatedby',
    },
    {}
  ];

  if (clgName === 'ALL') {
    columns.splice(3, 0,
      {
        title: 'College',
        dataIndex: 'collegename',
        key: 'collegename',
      }
    );
  }

  if (loggedadmin !== 'superadmin') {
    columns.push({
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <div className='table-actions'>
          <Button
            key="updateAssistant"
            onClick={() => handleUpdate(record)}
            className="btn-icon "
            type="primary"
            to="#"
            shape="circle"
          >
            <UilEdit />
          </Button>
        </div>
      ),
    });
  }

  const handleButtonClick = () => {
    setIsModalVisible(true);
    setSelectedRegulationId(null);
  };

  const handleButtonBulkClick = () => {
    setIsBulkModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    // setIsSubjectDisabled(true);
  };

  const handleNewModalCancel = () => {
    setIsNewModalVisible(false);
    setDispatchData([]);
    setSelectedFieldsData([]);
    setSelectedMode('');
  }

  const handleModalCancell = () => {
    setIsBulkModalVisible(false);
    setCSVFile(null);
    setCSVFileList([]);
    bulkForm.resetFields();
    setNameChecked(false);
    setEmailChecked(false);
    setBatchesChecked(false)
    setPasswordChecked(false);
  };

  const [selectedFieldsData, setSelectedFieldsData] = useState([]);
  const [dispatchData, setDispatchData] = useState([]);
  const [selectedMode, setSelectedMode] = useState('');

  const handleBulkModalPreview = () => {
    bulkForm.validateFields().then((values) => {
      const selectedFields = [];

      if (isUsernameChecked) selectedFields.push("username");
      if (isNameChecked) selectedFields.push("name");
      if (isBatchesChecked) selectedFields.push("batchId");
      if (isPasswordChecked) selectedFields.push("password");
      if (isEmailChecked) selectedFields.push("email");
      selectedFields.push('role');
      const modifiedCSVData = parsedCSVData?.map((row) => {
        const filteredRow = {};
        selectedFields.forEach((field) => {
          if ((field === 'username' && isUsernameChecked) || (field === 'name' && isNameChecked) ||
            (field === 'batchId' && isBatchesChecked) ||
            (field === 'password' && isPasswordChecked) || (field === 'email' && isEmailChecked)) {
            filteredRow[field] = row[field] || '';
          }
        });
        selectedFieldsData.push(filteredRow);
        return filteredRow;
      });

      setSelectedMode(values.mode);
      setDispatchData(modifiedCSVData);
      setCSVFile(null);
      setCSVFileList([]);
      setIsBulkModalVisible(false);
      bulkForm.resetFields();
      setNameChecked(false);
      setEmailChecked(false);
      setBatchesChecked(false)
      setPasswordChecked(false);
      setIsNewModalVisible(true);
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  let str = '';
  if (selectedMode === 'AddnewAssistants') {
    str = 'Add';
  } else if (selectedMode === 'UpdateexistingAssistants') {
    str = 'Update';
  }
  // else if(selectedMode === 'AddNewUpdateExistingAssistants'){
  //     str = 'Add & Update';
  // }

  const columnns = Object.keys(selectedFieldsData[0] || {})?.map((key) => ({
    title: key,
    dataIndex: key,
    key: key,
  }));

  columnns.push({
    title: 'Valid',
    dataIndex: 'valid',
    key: 'valid',
    render: (valid) => (
      <Space size="middle">
        {valid ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
      </Space>
    ),
  });
  const tableData = selectedFieldsData?.map((item, index) => {
    const rowData = {
      ...item,
      key: index,
    };
    rowData.valid = Object.keys(rowData).every(key => {
      if (key === 'valid') return true;
      const value = rowData[key];
      if (Array.isArray(value) && value.length === 0) return false;
      return value !== undefined && value !== '';
    });
    if (!rowData.valid) {
      str = '';
    }
    return rowData;
  });

  const handleBulkAssistantsAdd = () => {
    if (str === 'Add') {
      dispatch(assistantsbulkDataSubmit(dispatchData));
    } else if (str === 'Update') {
      dispatch(assistantsBulkDataUpdate(dispatchData));
    }
    // else if(str === 'Add & Update'){
    //     dispatch(addnewassistantsupdateexisting(dispatchData));
    // }
    setSelectedFieldsData([]);
    setIsNewModalVisible(false);
    setSelectedMode('');
    str = '';
  }


  const handleModalAdd = () => {
    form.validateFields().then((values) => {
      const newRow = {
        name: values.name,
        username: values.username,
        collegeId: values.college,
        password: values.password,
        email: (values.email === undefined) || (values.email?.trim() === "") ? null : values.email,
        batchId: values.batch,
      };
      form.resetFields();
      setIsModalVisible(false);
      dispatch(assistantsDataSubmit([newRow]));
    })
      .catch((error) => {
        console.error('Error while validating form:', error);
      });
  };

  const handleModalUpdate = () => {
    updateForm.validateFields().then((values) => {
      const updatedRecord = {
        id: updateRecord.id,
        name: values.name,
        collegeId: values.college,
        username: updateRecord.username,
        batchId: values.batch,
        email: (values.email === undefined) || (values.email?.trim() === "") ? null : values.email,
      };
      setIsUpdateModalVisible(false);
      dispatch(assistantsDataUpdate([updatedRecord]));
    }).catch((error) => {
      console.error('Error while validating form:', error);
    });
  };

  const handleUpdate = (record) => {
    setUpdateRecord(record);
    setIsUpdateModalVisible(true);
    const email = record.email !== 'no data' ? record.email : null;

    updateForm.setFieldsValue({
      name: record.name,
      email: email,
      college: record.collegeId,
      username: record.username,
      batch: record.batchId
    });
  };

  return (
    <>
      <PageHeader className="ninjadash-page-header-main" title={`Assistants ${loggedadmin === 'superadmin' ? ClgName : ''}`}
        buttons={
          loggedadmin !== 'superadmin' ?
            [
              <Button id="assistants-page-add_assistant-button" onClick={handleButtonClick} key="AddAssistant" type="primary" size="default">
                Add Assistant
              </Button>,
              // <Button id="assistants-page-add/update_bulk_assistants-button" onClick={handleButtonBulkClick} key="AddBulkAssistants" type="primary" size="default">
              //   Bulk Upload
              // </Button>,
            ] : []}
      />
      <Main>
        {isLoading || loading || isAssistantsUpdating || isAssistantsAdding || isAssistantsBulkAdding || isAssistantsBulkUpdating || isAssistantsBulkAddingUpdating ?
          (
            <div className="custom-loading-spin-with-text">
              <CustomSpinner />
              <p style={{ fontWeight: 'bold', color: '#006666' }}>
                Loading please wait...
              </p>
            </div>
          ) : (
            <Row gutter={25}>
              <Col sm={24} xs={24}>
                <ProjectSorting>
                  <div className="project-sort-bar d-flex justify-content-end">
                    <div className="project-sort-search">
                      <AutoComplete id="assistants-page-search-filter" onSearch={handleSearch} dataSource={dataSource} placeholder="Search here" patterns />
                    </div>
                  </div>
                </ProjectSorting>
                <Cards headless>
                  <TableWrapper>
                    <ProjectList>
                      <div className='table-responsive'>
                        <Table dataSource={dataSource} columns={columns} />
                      </div>
                    </ProjectList>
                  </TableWrapper>
                </Cards>
              </Col>
            </Row>
          )
        }
      </Main>
      <Modal
        title="Add Assistant"
        open={isModalVisible}
        onCancel={handleModalCancel}
        maskClosable={false}
        footer={[
          <Button id="add-assistant-button" key="AddAssistantRecord" type="primary" onClick={handleModalAdd}>
            Add
          </Button>,
        ]}
      >
        <Form id="add-assistant-form" form={form} layout="vertical">
          <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
            <Select id="add-assistant-select-college" placeholder="select"
              loading={isCollegesLoading}
            >
              {colleges?.map((college) => (
                <Option key={college.id} value={college.id}>
                  {college.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter assistant name" }]}>
            <Input id="add-assistant-enter-assistant-name" placeholder="Enter name" />
          </Form.Item>
          <Form.Item name="username" label="User Name" rules={[{ required: true, message: "Please enter assistant username" }]}>
            <Input id="add-assistant-enter-assistant-username" placeholder="Enter username" />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true, message: "Please enter password" }]}>
            <Input.Password id="add-assistant-enter-password" placeholder="Enter password" />
          </Form.Item>
          <Form.Item name="email" label="Email (optional)" rules={[{ required: false, message: "Please enter email" }]}>
            <Input id="add-assistant-enter-email" placeholder="Enter email(Ex: xyz@gmail.com)" />
          </Form.Item>
          <Form.Item name="batch" label="Batch" rules={[{ required: true, message: "Please select batch" }]}>
            <Select id="add-subject-select-batch" placeholder='Select batch'
              showSearch
              //   mode="multiple" 
              filterOption={(inputValue, option) =>
                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {batches?.map((batch) => (
                <Option key={batch.name} value={batch.id}>
                  {batch.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

        </Form>
      </Modal>
      <Modal
        title="Bulk Upload"
        open={isBulkModalVisible}
        onCancel={handleModalCancell}
        maskClosable={false}
        footer={[
          <Button id="assistants-page-add/update-preview-assistants" key="AddBulkAssistantsRecord" type="primary" onClick={handleBulkModalPreview}>
            Preview
          </Button>,
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontSize: '17px', color: 'black', marginRight: '5px', marginBottom: '0' }}>Sample File:</p>
          <span className="download-text" style={{ marginLeft: '10px', marginRight: '5px', marginBottom: '0', fontSize: '17px' }}>assistant_bulk_upload.csv</span>
          <DownloadOutlined id="download-sample-csv-file"
            style={{ verticalAlign: 'middle' }}
            className="download-icon"
            onClick={() => {
              const csvContent = Papa.unparse(sampleCSVData);
              const blob = new Blob([csvContent], { type: "text/csv" });
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob);
              link.download = "assistant_bulk_upload.csv";
              link.click();
            }}
          />
        </div>
        <Form id="add/update-assistants-form" form={bulkForm} layout="vertical" style={{ marginTop: '15px' }}>
          <Form.Item name="mode" label="Mode" rules={[{ required: true, message: "Please select mode" }]}>
            <Select id="assistants-page-add/update-select-mode" placeholder="Select mode" onChange={handleModeChange}>
              <Option value="AddnewAssistants">Add new assistants</Option>
              {/* <Option value="UpdateexistingAssistants">Update existing assistants</Option> */}
              {/* <Option value="AddNewUpdateExistingAssistants">Add new & update existing assistants</Option> */}
            </Select>
          </Form.Item>
          {
            selectedMode === "UpdateexistingAssistants" &&
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox id="assistant-page-username-checkbox" checked={isUsernameChecked} disabled>
                  username
                </Checkbox>
                <Checkbox id="assistant-page-all-checkbox" checked={isAllChecked} onChange={handleAllCheckboxToggle}>
                  All
                </Checkbox>
                <Checkbox id="assistant-page-name-checkbox" checked={isNameChecked} onChange={handleNameCheckboxChange}>
                  name
                </Checkbox>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <Checkbox id="assistant-page-password-checkbox" checked={isPasswordChecked} onChange={handlePasswordCheckboxChange}>
                  password
                </Checkbox>
                <Checkbox id="assistant-page-batch-checkbox" checked={isBatchesChecked} onChange={handleBatchesCheckboxChange}>
                  Batch
                </Checkbox>
                <Checkbox id="assistant-page-email-checkbox" checked={isEmailChecked} onChange={handleEmailCheckboxChange}>
                  email
                </Checkbox>
              </div>
            </div>
          }
          <Form.Item name="file" rules={[{ required: true, message: 'Please Upload CSV File' }]} style={{ marginTop: '10px' }} label="File">
            <ImportStyleWrap>
              <Dragger id="assistants-page-file-upload"
                maxCount={1}
                beforeUpload={uploadedFile => {
                  if (uploadedFile !== null) {
                    setCSVFile(uploadedFile);
                    return false;
                  }
                }}
                onChange={handleCSVFileChange}
                onRemove={handleRemoveCSVFile}
                fileList={csvFileList}
                accept=".csv"
              >
                <p className="ant-upload-drag-icon">
                  <UilExport />
                </p>
                <Heading as="h4" className="ant-upload-text">
                  Click here to select .csv File
                </Heading>
              </Dragger>
            </ImportStyleWrap>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Preview Assistants"
        open={isNewModalVisible}
        onCancel={handleNewModalCancel}
        maskClosable={false}
        width={1300}
        footer={[
          <Button id="preview-assistants-cancel-button" key="cancelpreview" type="light" onClick={handleNewModalCancel}>
            Cancel
          </Button>,
          <Button id="preview-assistants-add/update/add_new_update_existing-button" key="addpreviewstudents" type="primary" onClick={handleBulkAssistantsAdd}>
            {str}
          </Button>
        ]}
      >
        <Table columns={columnns} dataSource={tableData} />
      </Modal>
      <Modal
        title={`Update info of ${updateRecord?.username}`}
        open={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        maskClosable={false}
        footer={[
          <Button id="update-assistant-button" key="UpdateAssistantRecord" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>
        ]}
      >
        <Form id="update-assistant-form" form={updateForm} layout="vertical">
          <Form.Item name="college" label="College" rules={[{ required: true, message: "Please select college" }]}>
            <Select id="update-assistant-select-college" placeholder="select"
              loading={isCollegesLoading}
            >
              {colleges?.map((college) => (
                <Option key={college.id} value={college.id}>
                  {college.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please enter assistant name" }]}>
            <Input id="update-assistant-enter-assistant-name" placeholder='Enter name' />
          </Form.Item>
          {/* <Form.Item name="username" label="Username" rules={[{ required: true, message: "Please enter username" }]}>
            <Input id="update-assistant-enter-user-name" placeholder='Enter Username' disabled />
          </Form.Item> */}

          <Form.Item name="email" label="Email (optional)" rules={[{ required: false, message: "Please enter email" }]}>
            <Input id="update-assistant-enter-email" placeholder="Enter email(Ex: xyz@gmail.com)" />
          </Form.Item>
          <Form.Item name="batch" label="Batch" rules={[{ required: true, message: "Please select batch" }]}>
            <Select id="update-assistant-select-batch" placeholder='Select batch'
              showSearch
              //   mode="multiple" 
              filterOption={(inputValue, option) =>
                option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {batches?.map((batch) => (
                <Option key={batch.name} value={batch.id}>
                  {batch.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Assistants;