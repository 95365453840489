import React, { useLayoutEffect, useState } from 'react';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { NoteCardWrap, NoteNav, NoteWrapper } from '../note/style';
import { Col, Row, Spin } from 'antd';
import { Button } from '../../components/buttons/buttons';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import UilAngleRight from '@iconscout/react-unicons/icons/uil-angle-right';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { Cards } from '../../components/cards/frame/cards-frame';
import Heading from '../../components/heading/heading';
import { FixedSidebar, SidebarWrap } from '../teacher/styles/sidebarstyle';
import { Suspense } from 'react';
import TopicsList from './TopicsList';
import { useEffect } from 'react';
import { getSubjectById } from '../../redux/teacherSubjectsUnits/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { subjectUnitsDataRead } from '../../redux/studentSubjectsUnits/actionCreator';
import Cookies from 'js-cookie';

const activeUnitIndex = Cookies.get('activeunit')

function Units() {

    const { subjectId } = useParams()

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [sideBarState, setSidebarState] = useState({
        responsive: 0,
        collapsed: false,
        visible: false,
        modalType: 'primary',
    });

    const { SubjectInfo, subjectLoading, Units, unitsLoading } = useSelector((state) => {
        return {
            SubjectInfo: state.Subject.data,
            subjectLoading: state.Subject.loading,
            Units: state.Units.data,
            unitsLoading: state.Units.loading
        };
    });

    useEffect(() => {
        dispatch(getSubjectById(subjectId))
        dispatch(subjectUnitsDataRead(subjectId))
    }, [dispatch, subjectId])

    const { responsive, collapsed } = sideBarState;

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setSidebarState({ responsive: width });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const toggleCollapsed = () => {
        setSidebarState({
            ...sideBarState,
            collapsed: !collapsed,
        });
    };

    useEffect(() => {
        NavigateToPath()
    }, [Units])

    const NavigateToPath = async () => {
        if (Units.length !== 0) {
            let sortedArray = await Units.sort((a, b) => a.unitName > b.unitName ? 1 : -1)
            if (activeUnitIndex === undefined && sortedArray.length !== 0) {
                NavigateDynamic(sortedArray[0])
            }
            else if (activeUnitIndex !== undefined && sortedArray.length !== 0) {
                NavigateDynamic(sortedArray[activeUnitIndex])
            }
        }
    }

    const NavigateDynamic = (unitObj) => {
        if (unitObj !== null) {
            navigate(`unit/${unitObj.unitId}`)
        }
    }


    return (
        <>
            {
                subjectLoading || unitsLoading ?
                    <div className='spin'>
                        <Spin />
                    </div>
                    :
                    <div>
                        <PageHeader className="ninjadash-page-header-main" title={`${SubjectInfo !== null ? SubjectInfo.name : ''}`} />
                        <Main>
                            <NoteWrapper>
                                <Row gutter={25}>
                                    <Col xxl={6} lg={7} md={8} xs={24}>
                                        {responsive <= 767 && (
                                            <div className="sidebar-trier-wrap text-center">
                                                <Button type="link" className="sidebar-trigger" style={{ marginTop: 0 }} onClick={toggleCollapsed}>
                                                    {collapsed ? <UilAngleLeft /> : <UilAngleRight />}
                                                </Button>
                                            </div>
                                        )}
                                        {responsive > 767 ? (
                                            <div className="sidebar-card">
                                                <Cards headless>
                                                    <div className="note-sidebar-bottom">
                                                        <NoteNav>
                                                            <Heading as='h3' className='text-center'>Subject Summary</Heading>
                                                            <ul className="ninjadash-taskApp-sidebar__nav">
                                                                {
                                                                    Units.length === 0 ? <div className='text-center mt-25' style={{ fontSize: '18px' }}>Units Not Found</div> : Units.sort((a, b) => a.unitName > b.unitName ? 1 : -1).map((unit, index) =>
                                                                        <li key={index}>
                                                                            <NavLink to={`unit/${unit.unitId}`} onClick={() => Cookies.set('activeunit', index)}>
                                                                                <span className="nav-text" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: `${responsive > 1435 ? '180px' : ((responsive <= 1435 && responsive > 1275) || (responsive <= 991 && responsive > 936)) ? '150px' : ((responsive <= 1275 && responsive > 1165) || (responsive <= 936 && responsive > 876)) ? '130px' : ((responsive <= 1165 && responsive > 1130) || (responsive <= 876 && responsive > 846)) ? '120px' : ((responsive <= 1130 && responsive > 1090) || (responsive <= 846 && responsive > 816)) ? '110px' : ((responsive <= 1090 && responsive > 1045) || (responsive <= 816 && responsive > 786)) ? '100px' : ((responsive <= 1045 && responsive > 991) || (responsive <= 786 && responsive > 767)) && '85px'}` }}>
                                                                                    {unit.unitName}
                                                                                </span>
                                                                            </NavLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </NoteNav>
                                                    </div>
                                                </Cards>
                                            </div>
                                        ) : (
                                            <FixedSidebar className={collapsed ? 'show' : 'hide'}>
                                                <Link to="#" type="link" className="trigger-close" onClick={toggleCollapsed}>
                                                    <UilTimes />
                                                </Link>
                                                <SidebarWrap className="mb-30">
                                                    <div className="ninjadash-taskApp-sidebar">
                                                        <Heading as='h3' className='text-center'>Topics</Heading>
                                                        <ul className="ninjadash-taskApp-sidebar__nav">
                                                            {
                                                                Units.length !== 0 && Units.sort((a, b) => a.unitName > b.unitName ? 1 : -1).map((unit, index) =>
                                                                    <li key={index} className="ninjadash-taskApp-sidebar__nav--item">
                                                                        <NavLink className="ninjadash-taskApp-sidebar__nav--link" to={`${unit.unitId}`}>
                                                                            <span className="nav-text" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '180px', width: '140px' }}>
                                                                                {unit.unitName}
                                                                            </span>
                                                                        </NavLink>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </SidebarWrap>
                                            </FixedSidebar>
                                        )}
                                    </Col>
                                    <Col xxl={18} lg={17} md={16} xs={24}>
                                        <Suspense
                                            fallback={
                                                <div className="spin">
                                                    <Spin />
                                                </div>
                                            }
                                        >
                                            {
                                                Units.length !== 0 &&
                                                <Routes>
                                                    <Route path="/" element={<Navigate to={`unit/:unitId`} replace={true} />} />
                                                    <Route path={`unit/:unitId`} element={<TopicsList />} />
                                                </Routes>
                                            }
                                        </Suspense>
                                        {/* {
                                topics.length !== 0 && topics.map(Obj =>
                                    <Cards title={`Topics of ${Obj.unitName}`} key={Obj.unitId} >
                                        <Row className='mb-20'>
                                            {
                                                Obj.topics.length !== 0 && Obj.topics.map(topic =>
                                                    <Col xs={12} key={topic.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                        <Link to={`${Obj.unitName}/${topic.name}`} style={{ color: '#8c8c8c' }} className='underline-hover'>
                                                            {topic.name}
                                                        </Link>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    </Cards>
                                )
                            } */}
                                    </Col>
                                </Row>
                            </NoteWrapper>
                        </Main>
                    </div>
            }
        </>
    );
}

export default Units;