import actions from './actions';
import { getItem } from '../../utility/localStorageControl';

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_BEGIN, LOGOUT_SUCCESS, LOGOUT_ERR , 
  GET_MSG_BEGIN,
  GET_MSG_SUCCESS,
  GET_MSG_ERR } = actions;

const initState = {
  login: getItem('logedIn'),
  loading: false,
  role : getItem('role'),
  username : getItem('username'),
  collegeName : getItem('collegeName'),
  error: null,
};


const messageInitialState = {
  data: null,
  loading: false,
  error: null,
};


/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data,        
        loading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,        
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,        
        loading: false,
      };
    default:
      return state;
  }
};


const MessageReducer = (state = messageInitialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MSG_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_MSG_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
        err: null,
      };
    case GET_MSG_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};
export {AuthReducer, MessageReducer};