import actions from './actions';

const {
  TOPIC_SINGLE_DATA_BEGIN,
  TOPIC_SINGLE_DATA_SUCCESS,
  TOPIC_SINGLE_DATA_ERR,

  TOPIC_STATUS_UPDATE_BEGIN,
  TOPIC_STATUS_UPDATE_SUCCESS,
  TOPIC_STATUS_UPDATE_ERR,

  TOPICS_READ_BEGIN,
  TOPICS_READ_SUCCESS,
  TOPICS_READ_ERR,

  TOPIC_CLEAR_BEGIN,
  TOPIC_CLEAR_SUCCESS,
  TOPIC_CLEAR_ERR

} = actions;


const initialStateSingle = {
  data: null,
  loading: false,
  error: null,
};


const topicStatusInitial = {
  data: null,
  loading: false,
  error: null,
};

const topicsInitial = {
  data: null,
  loading: false,
  error: null,
};


const TOPICSingleReducer = (state = initialStateSingle, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_SINGLE_DATA_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_SINGLE_DATA_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_SINGLE_DATA_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TOPICSTATUSReducer = (state = topicStatusInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_STATUS_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_STATUS_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};


const TOPICSByUnitReducer = (state = topicsInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPICS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPICS_READ_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPICS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

const TopicClearReducer = (state = topicsInitial, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TOPIC_CLEAR_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TOPIC_CLEAR_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case TOPIC_CLEAR_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export { TOPICSingleReducer, TOPICSTATUSReducer, TOPICSByUnitReducer, TopicClearReducer };
