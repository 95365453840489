import React, { useEffect } from 'react'
import actions from '../../redux/interview/actions'
import Interviews from './Interviews';
import { useDispatch, useSelector } from 'react-redux';
import { getAcademicScheduledData } from '../../redux/academicInterviews/actionCreator';

const { getInterviewAttemptsSuccess, getInterviewByScheduleSuccess } = actions

export default function AcademicInterviews() {

    const dispatch = useDispatch();
    const { interviewsBySchedule, isacademicInterviewsByScheduleLoading, interviewAttempts, isInterviewAttemptsLoading, TopicScheduledData, isSchedulesLoading } = useSelector((state) => {
        return {
            interviewsBySchedule: state.academicInterviewsBySchedule.data,
            isacademicInterviewsByScheduleLoading: state.interviewsBySchedule.loading,
            interviewAttempts: state.academicInterviewAttempts.data,
            isInterviewAttemptsLoading: state.academicInterviewAttempts.loading,
            TopicScheduledData: state.academicTopicScheduledData.data,
            isSchedulesLoading: state.academicTopicScheduledData.loading,
        };
    });

    useEffect(() => {
        if (dispatch) {
            dispatch(getInterviewByScheduleSuccess([]))
            dispatch(getInterviewAttemptsSuccess([]))            
            dispatch(getAcademicScheduledData())
        }
    }, [dispatch]);
    

    return (
        <div>
            <Interviews
                TopicScheduledData={TopicScheduledData}
                isSchedulesLoading={isSchedulesLoading}
                interviewsBySchedule={interviewsBySchedule}
                isInterviewsByScheduleLoading={isacademicInterviewsByScheduleLoading}
                interviewAttempts={interviewAttempts}
                isInterviewAttemptsLoading={isInterviewAttemptsLoading}
                pageTitle={'Academic Interviews'}
            />
        </div>
    )
}
