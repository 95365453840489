import react from 'react'
import { NoteCardWrap } from '../note/style';
import { Col, Row, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Cards } from '../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import { getTopicsByUnitId } from '../../redux/topics/actionCreator'
import { useEffect } from 'react';
import Heading from '../../components/heading/heading';

function TopicsList() {
    const { unitId } = useParams()
    const dispatch = useDispatch();

    const { Topics, topicsLoading } = useSelector((state) => {
        return {
            Topics: state.UnitTopics.data,
            topicsLoading: state.UnitTopics.loading,
        };
    });

    useEffect(() => {
        if (unitId !== undefined || unitId !== ':unitId') {
            dispatch(getTopicsByUnitId(unitId))
        }
    }, [dispatch, unitId])

    return (
        <div>
            <Cards headless >
                <div className='d-flex mb-20'>
                    <Heading as='h5'>Topics of {Topics !== null ? Topics.name : '{Unit name not loaded}'}</Heading>
                    &nbsp;&nbsp;
                    <span>| {Topics !== null && Topics.topics.length} Topics found</span>
                </div>
                <NoteCardWrap>
                    {
                        topicsLoading ?
                            <div className='spin'>
                                <Spin />
                            </div>
                            :
                            <div>
                                {
                                    Topics !== null && Topics.topics.length !== 0 ?
                                        <Row className='mb-20'>
                                            {
                                                Topics.topics.length < 20 ? Topics.topics.map(topic =>
                                                    <Col xs={24} key={topic.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                        <Link to={`topic/${topic.id}`} style={{ color: '#8c8c8c' }} className='underline-hover'>
                                                            {topic.name}
                                                        </Link>
                                                    </Col>
                                                )
                                                    :
                                                    Topics.topics.map(topic =>
                                                        <Col xs={12} key={topic.id} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                            <Link to={`topic/${topic.id}`} style={{ color: '#8c8c8c' }} className='underline-hover'>
                                                                {topic.name}
                                                            </Link>
                                                        </Col>
                                                    )
                                            }
                                        </Row>
                                        :
                                        <div className='text-center' style={{ fontSize: '20px' }}>
                                            <p>Topics not found</p>
                                        </div>
                                }
                            </div>
                    }
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default TopicsList