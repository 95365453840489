import React, { useLayoutEffect, useState } from 'react';
import { Cards } from '../../components/cards/frame/cards-frame';
import { NoteCardWrap } from '../note/style';
import Heading from '../../components/heading/heading';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfFile from '../../assets/mockups.pdf'
import sampleFile from '../../assets/sample.pdf'
import PdfControlPanel from './PdfControlPanel';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

function ContentPage() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setIsLoading(false);
    }

    const [pdfwidth, setPdfWidth] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
            const width = window.innerWidth;
            setPdfWidth(width);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <div>
            <Cards headless>
                <Heading as={'h3'} className='text-center'>Topic Name</Heading>
                <NoteCardWrap>
                    <PdfControlPanel
                        scale={scale}
                        setScale={setScale}
                        numPages={numPages}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        file={pdfFile}
                    />
                    <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} scale={scale} width={(pdfwidth > 992 ? (pdfwidth) / 1.7 : (pdfwidth < 768 ? (pdfwidth) / 1.2 : (pdfwidth) / 2))} />
                        {/* {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={(pdfwidth > 992 ? (pdfwidth) / 1.7 : (pdfwidth < 768 ? (pdfwidth) / 1.2 : (pdfwidth) / 2))} />
                        ))} */}
                    </Document>
                    {/* <p>
                        Page {pageNumber} of {numPages}
                    </p> */}
                    {/* <iframe
                        title="PDF Viewer"
                        src={pdfFile}
                        style={{ width: '100%', height: '700px', border: 'none' }}
                    /> */}
                </NoteCardWrap>
            </Cards>
        </div>
    );
}

export default ContentPage;